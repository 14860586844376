import React from 'react'
import NavBar from '../NavBar'
import ReactQuill from 'react-quill';
import {Option, Select} from "../customSelect"
import 'quill/dist/quill.snow.css'
import {useState,useEffect} from "react"
import { useNavigate } from 'react-router-dom';
import {getFirestore, doc,getDoc,collection,onSnapshot, getDocs, addDoc, query,where} from "firebase/firestore"
import {getStorage, ref, uploadBytesResumable, getDownloadURL, listAll, deleteObject} from "firebase/storage"
import { app } from "../../firebase";
import uploadIcon from "../../images/upload.png"
const firestore = getFirestore(app)
const storage = getStorage(app)

const modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] }
      ],
      [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
    ]
  };

const formats = [
    "header", "height", "bold", "italic",
    "underline", "strike", "blockquote",
    "list", "color", "bullet", "indent",
    "link", "image", "align", "size",
  ];

function CreateGuide() {

  const [intData,setIntData] = useState([])
  const [inputs, setInputs] = useState({
        counter : 0,
        active : true,
        noteCategory : "",
        noteName : "",
        caption : "",
        noteRs : 0,
        validity : 0
    })
    const [planActive, setPlanActive] = useState(true)
    const [name , setName]  = useState("Select Document")
    const [data,setData] = useState({})
    const [errors, setErrors] = useState({});
    const navigate = useNavigate()

  const getIntData = async() =>{
        const ref = collection(firestore, "interests");
        const refNote = collection(firestore, "Notes");
    // Subscribe to real-time changes
    const unsubscribe = onSnapshot(ref, (snapshot) => {
        const demoData = [];
        snapshot.forEach(async(dc) => {
            if(dc.data().active){
                const noteDoc = query(refNote,where('noteName','==',dc.data().interestName))
                const docExists = await getDocs(noteDoc)
                if(docExists.empty){
                    demoData.push({
                _id: dc.id,
                Name: dc.data().interestName,
                SelByUsers: dc.data().counter,
                Status: dc.data().active ? "Active" : "Inactive"
            });
                }
            }
        });
        setIntData(demoData); // Update state with new data
    });

    // Return unsubscribe function to clean up the listener when component unmounts
    return () => unsubscribe();
    }

    const handleUpload = () =>{
        const validationErrors = validateInputs({...inputs,imgName : name});
        console.log(inputs)
        if (Object.keys(validationErrors).length === 0){
            setErrors({})
            const storageRef = ref(storage, `NotesDocs/${data.name}`)
        const uploadTask = uploadBytesResumable(storageRef, data)
        uploadTask.on("state_changed",(snapshot)=>{
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
        },
        (error)=>{
            console.log(error)
        },
        ()=>{
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL)=>{
                const updatedInputs = {
                        ...inputs,
                        location: downloadURL,
                        uploadDate: new Date().toISOString().split("T")[0]
                    };
                    console.log(updatedInputs)
                    const ref = collection(firestore,"Notes")
                    addDoc(ref,updatedInputs)
                    .then((res)=>{
                        console.log("Data uploaded succesfully :", res.id)
                    })
                    .then(
                        navigate("/Services/ExpertGuide")
                    )
                    .catch((err)=>{
                        console.log(err)
                    })
            })
        })
        }else {
            alert("Please fill in all fields");
            setErrors(validationErrors);
        }
        
    }

    const validateInputs = (values) => {
        const errors = {};

        if (!values.noteCategory.trim()) {
            errors.noteCategory = "Please select qualification";
        }
        if (!values.noteName.trim()) {
            errors.noteName = "Please select interest";
        }
        if (!values.noteRs) {
            errors.noteRs = "Price is required";
        }
        if (!values.validity) {
            errors.validity = "Validity is required";
        }
        if (!values.caption) {
            errors.caption = "Description is required";
        }
        if (values.imgName === "Select Document") {
            errors.imgName = "Document is required";
        }

        return errors;
    }

  const handleChange = (event,name=null,value=null) =>{
        let nameTmp;
        let valueTmp;
        if(name !== null && value !== null){
            nameTmp = name;
            valueTmp = value
        }else{
            nameTmp = event.target.name;
            valueTmp = event.target.value
        }
        if(nameTmp === 'active'){
            valueTmp = (inputs.active)?false:true
        }
        else if (nameTmp === 'imgName'){
            valueTmp = event.target.files[0].name
        }
        else if (nameTmp === 'noteRs'){
            valueTmp = Number(valueTmp)
        }
        else if(nameTmp === 'validity'){
            valueTmp = Number(valueTmp)
        }

        setInputs(values => (console.log(values),{...values, [nameTmp]: valueTmp}))
}


  const handleEditorText = (content) =>{
    setInputs(values => ({...values,caption:content}))
  }

  useEffect(()=>{
    getIntData();
},[])

  return (
    <div style={{"margin":"auto"}} className="pr-5 pt-1 pl-5">
        <NavBar />
        <div className="flex justify-between pl-10 pr-10 mb-5 mt-5">
            <h2 className="text-2xl font-semibold">
                Expert Learning Guides &gt; New Guide
            </h2>
            <div className='flex gap-x-6'>
                <button onClick={()=>navigate("/Services/ExpertGuide")} className="w-40 h-12 bg-transparent text-[#32497F] border-2 mt-2 border-solid text-base tracking-wider rounded-2xl border-[#32497F]">Cancel</button>
                <button onClick={handleUpload} className="w-40 text-white h-12 bg-[#4F72C5] text-base tracking-wider border-none mt-2 rounded-2xl">Upload</button>
            </div>
        </div>
        <div className="ml-56 w-5/6 h-full flex flex-col pl-20 h-80">
            <div className="flex w-3/5 pt-4 pb-8 h-full gap-x-8 border-b-2 border-dashed border-[#707070]">
                <div className='relative flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}} for="interestCategory">Select Qualification</label>

                    <Select onChange={(e,value)=>{handleChange(e,"noteCategory",value)}} placeholder="Select qualification :" name='noteCategory' id="interestCategory" className={(errors.noteCategory)?'pl-4 w-64 h-11 rounded-xl border-2 border-rose-500 z-21 border-solid':'pl-4 w-64 h-11 rounded-xl border-2 border-[#9E9B9B] z-21 border-solid'}>
                    <Option value="UG">Under Graduate</Option>
                    <Option value="PG">Post Graduate</Option>
                    </Select>
                    {errors.noteCategory && <div className='absolute top-16'><p className="text-red-500">{errors.noteCategory}</p></div>}
                </div>
                <div className='relative flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}} for="interestCategory">Select Interest</label>

                    <Select onChange={(e,value)=>{
                        handleChange(e,"noteName",value)}} id="interestCategory" placeholder="Select interest :" name='noteName' className={(errors.noteName)?'pl-4 w-64 h-11 rounded-xl border-2 border-rose-500 z-21 border-solid':'pl-4 w-64 h-11 rounded-xl z-21 border-2 border-[#9E9B9B] border-solid'}>

                    {intData.map((dt)=>{
                        return(
                            <Option key={dt._id} value={dt.Name}>{dt.Name}</Option>
                        )
                    })}
                    </Select>
                    {errors.noteName && <div className='absolute top-16'><p className="text-red-500">{errors.noteName}</p></div>}
                </div>
                <div className='relative flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}}>Price</label>
                    <div className='flex'>
                        <input onChange={handleChange} className={(errors.noteRs)?"pl-4 w-20 h-10 rounded-xl border-2 mr-3 border-rose-500 border-solid pl-2":"pl-4 w-20 h-10 rounded-xl border-2 mr-3 border-[#9E9B9B] border-solid pl-2"} name='noteRs' type='number'/><span className='mt-3'>Rs</span>
                    </div>
                    {errors.noteRs && <div className='absolute top-16'><p className="text-red-500">{errors.noteRs}</p></div>}
                </div>
                <div className='flex flex-col gap-y-3 ml-8'>
                    <label style={{"fontSize":"16px"}} className='mb-3'>Status</label>
                    <label class="inline-flex items-center cursor-pointer">
                    <input type="checkbox" class="sr-only peer" name='active' value={inputs.active} onChange={(e)=>handleChange(e)} checked={(inputs.active)?true:false}/>
                    <span class="text-sm font-normal text-gray-900 dark:text-gray-300 mr-3">Inactive</span>
                    <div class="relative w-11 h-6 bg-[#b6b8b7] rounded-full peer peer-focus:ring-4 peer-focus:ring-white dark:peer-focus:ring-white dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#818181]"></div>
                    <span class="ms-3 text-sm font-normal text-gray-900 dark:text-gray-300 ml-3">Active</span>
                    </label>
                </div>
            </div>
            <div className='pt-4 flex gap-x-5 border-b-2 border-dashed border-[#707070] pb-8 mb-6'>
                <div className='flex relative flex-col gap-y-3'>
                    <label>Upload Document</label>
                    <div  className='relative flex gap-x-8'> 
                        <label for="imgOne" className={(errors.imgName)?'relative pt-1 w-96 pl-4 rounded-xl text-base border-2 h-10 z-10 border-solid border-rose-500':'relative pt-1 w-96 pl-4 rounded-xl text-base border-2 h-10 z-10 border-solid border-[#9E9B9B]'}>{name}</label>
                        <span className='absolute right-6 top-2'><img className='w-6 h-6' src={uploadIcon}/></span>
                        <input onChange={(e)=>{
                            console.log(e.target.files[0])
                            setData(e.target.files[0])
                            setName(e.target.files[0].name)
                            }} id='imgOne' style={{"display":"none"}} type='file'/>

                    </div>
                    {errors.imgName && <div className='absolute top-16'><p className="text-red-500">{errors.imgName}</p></div>}
                </div>
                <div className='relative flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}}>Validity</label>
                    <div>
                        <input onChange={handleChange} className={(errors.validity)?"pl-4 w-20 h-10 rounded-xl border-2 mr-3 border-rose-500 border-solid pl-2":"pl-4 w-20 h-10 rounded-xl border-2 mr-3 border-[#9E9B9B] border-solid pl-2"} name='validity' type='number'/><span>Days</span>
                    </div>
                    {errors.validity && <div className='absolute top-16'><p className="text-red-500">{errors.validity}</p></div>}
                </div>
            </div>
                <div className='relative flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}}>Description</label>
                    <div className={(errors.caption)?'rounded-xl border-2 border-rose-500 border-solid':'rounded-xl border-2 border-[#9E9B9B] border-solid'} style={{"width":"650px", "height":"190px", "overflow":"hidden"}}>
                        <ReactQuill
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        onChange={handleEditorText}
                        placeholder="write your content ...."
                        style={{ height: "152px", width:"650px"}}
                        >
                        </ReactQuill>
                    </div>
                    {errors.caption && <div className='absolute top-52 mt-1'><p className="text-red-500">{errors.caption}</p></div>}
                </div>
        </div>
    </div>
  )
}

export default CreateGuide