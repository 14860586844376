import React from 'react'
import NavBar from './NavBar'
import { useState,useEffect } from 'react'
import {Select ,Option} from './customSelect'
import { useNavigate} from 'react-router-dom'
import {getFirestore, doc,getDoc,collection, getDocs, addDoc,query,where} from "firebase/firestore"
import { app } from "../firebase";
import {getAuth, onAuthStateChanged} from "firebase/auth"
const auth = getAuth(app)
const firestore = getFirestore(app)
const regex = /^[a-zA-Z0-9\s]+$/;

export default function CreateInterest() {
    const navigate = useNavigate()
    const [intName, setinterestName] = useState("")
    const [value,setValue] = useState(true)
    const [status,setStatus] = useState(true)
    const [error ,setError] = useState(false)
    const [invalidInputError, setinvalidInputError] = useState(false)
    const [interestExistsError,setintrestExistsError] = useState(false);
    const [intCategory, setIntCategory] = useState("All")

    const handleClick = async() =>{

        const interestData = {
            counter : 0,
            interestName : intName.toUpperCase(),
            isFree : value,
            active : status,
            interestCategory : intCategory
        }
    if (intName === "") {
        setError(true)
        return;
    }
    if(!regex.test(intName)){
        setinvalidInputError(true);
        return;
        } 

        const interestDocs = collection(firestore,'interests')
        const q = query(interestDocs,where('interestName',"==",intName.toUpperCase()))
        const querySnapshot = await getDocs(q)
        if(!querySnapshot.empty){
          setintrestExistsError(true)
          return;
        }else {
        const addInterest = collection(firestore,'interests')
        console.log(interestData)
        addDoc(addInterest, interestData)
        .then((docRef)=>{
            console.log('Interest added with ID: ', docRef.id);
        })
        .then(
            navigate("/InterestManagement")
        )
        .catch((error)=>{
            console.log("Error adding interest",error)
            alert("Error adding the document. Please try to refresh.")
        })
    }
    }

    useEffect(()=>{
        onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/auth.user
          const uid = user.uid;
          console.log(user)
          // ...
        } else {
          // User is signed out
          // ...
          console.log("no user")
          navigate("/")
        }
      });
    },[])

  return (
    <div style={{"margin":"auto"}} className="pr-5 pt-1 pl-5">
        <NavBar />
        <div className="flex justify-between pl-10 pr-10 mb-5 mt-5">
            <h2 className="text-2xl font-semibold">
                Interest Management &gt; Create Interest
            </h2>
            <div className='flex gap-x-6'>
                <button onClick={()=>navigate("/InterestManagement")} className="w-40 h-12 bg-transparent border-2 mt-2 text-base tracking-wider text-[#32497F] rounded-2xl border-solid border-[#32497F]">Cancel</button>
                <button onClick={handleClick} className="w-40 text-white h-12 bg-[#4F72C5] text-base border-none tracking-wider mt-2 rounded-2xl">Create</button>
            </div>
        </div>
        <div className="ml-56 pl-20 h-80">
            <div className="flex flex-col w-80 pt-4 h-96 gap-y-10">
                <div className='relative flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}}>Name</label>
                    <input onChange={(e)=>{
                        setError(false)
                        setinvalidInputError(false)
                        setintrestExistsError(false)
                        setinterestName(e.target.value)
                        }} className={(error || invalidInputError)?"w-96 h-10 rounded-xl border-solid border-2 pl-4 border-rose-500":"w-96 h-10 rounded-xl border-solid border-2 pl-4 border-[#9E9B9B]"} type='text'/>
                    {(error)&&<label className='absolute text-rose-500 top-20'>Interest name required</label>}
                    {(invalidInputError)&&<div className='absolute text-rose-500 top-20'><label className='text-rose-500'>Numbers, special characters are not allowed.</label></div>}
                    {(interestExistsError)&&<div className='absolute top-20'><label className='text-rose-500'>Interest already exists.</label></div>}
                </div>
                <div className='flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}} for="interestCategory">Select Qualification:</label>

                    <Select onChange={(e,value)=>{setIntCategory(value)}} id="interestCategory" placeholder="Select qualification :" className='w-96 h-11 pl-3 pr-5 h-10 rounded-xl border-2 border-[#9E9B9B] border-solid'>
                    <Option value="All">Open For All</Option>
                    <Option value="Under Graduate">Under Graduate</Option>
                    <Option value="Post Graduate">Post Graduate</Option>
                    </Select>
                </div>
                <div className='flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}}>Value</label>
                    <label class="inline-flex items-center cursor-pointer">
                    <input onChange={()=>setValue(!value)} type="checkbox" value="" class="sr-only peer" checked={(value)?false:true}/>
                    <span class="text-sm font-normal text-gray-900 dark:text-gray-300 mr-3">Free</span>
                    <div class="relative w-11 h-6 bg-[#b6b8b7] rounded-full peer peer-focus:ring-4 peer-focus:ring-white dark:peer-focus:ring-white dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#818181]"></div>
                    <span class="ms-3 text-sm font-normal text-gray-900 dark:text-gray-300 ml-3">Paid</span>
                    </label>
                </div>
                <div className='flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}}>Status</label>
                    <label class="inline-flex items-center cursor-pointer">
                    <input onChange={()=>setStatus(!status)} type="checkbox" value="" class="sr-only peer" checked={(status)?true:false}/>
                    <span class="text-sm font-normal text-gray-900 dark:text-gray-300 mr-3">Inactive</span>
                    <div class="relative w-11 h-6 bg-[#b6b8b7] rounded-full peer peer-focus:ring-4 peer-focus:ring-white dark:peer-focus:ring-white dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#818181]"></div>
                    <span class="ms-3 text-sm font-normal text-gray-900 dark:text-gray-300 ml-3">Active</span>
                    </label>
                </div>
            </div>
        </div>
    </div>
  )
}
