import React from 'react'
import NavBar from './NavBar'
import { Select, Option } from './customSelect';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useNavigate } from 'react-router-dom';
import { Timestamp } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import {getFirestore, doc,getDoc,collection, getDocs, onSnapshot,query, where, orderBy, Firestore, updateDoc} from "firebase/firestore"
import {getAuth, onAuthStateChanged} from "firebase/auth"
import { app } from "../firebase"
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat'
var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat)
const firestore = getFirestore(app)
const auth = getAuth(app)
const Modules = ["Users", "Interests", "Plan", "Notifications", "Expert guide","Colleges"]
const clearfilterCss = "w-40 h-11 bg-transparent cursor-pointer text-base tracking-wider text-[#32497F] border-solid border-2 border-[#32497F] rounded-lg";
const filterCss = "w-40 h-11 bg-[#4F72C5] cursor-pointer text-base tracking-wider text-white border-solid border-2 border-[#4F72C5] rounded-lg"
const theme = createTheme({
  palette: {
    mode: 'light', // Change to 'dark' to test dark mode
  },
});

function Audit() {
    const [mainModule, setmainModule] = useState(null)
    const [subModule, setsubModule] = useState([])
    const [subModuleCategory,setsubModuleCategory] = useState(null)
    const [originalData ,setoriginalData] = useState([])
    const [mainData, setmainData] = useState(originalData)
    const [adminName , setadminName] = useState(null)
    const [qualification,setQualification]  = useState(null)
    const [subStart, setsubStart] = useState("")
    const [subEnd, setsubEnd] = useState("")
    const [dateError, setdateError] = useState(false)
    const [isFilActive, setFilterActive] = useState(false)

    function formatDateString(dateString) {
  // Parse the date string with Day.js
    console.log(dateString)
    const date = dayjs(dateString, "MM/DD/YYYY, hh:mm:ss A");

  // Format the date as "DD/MM/YYYY hh:mm A"
    const formattedDate = date.tz('Asia/Kolkata').format('DD/MM/YYYY hh:mm A');

    return formattedDate;
}

    const getInterests = async(quali=null) =>{
        const demoData = [];
        let intData= null;
        const docRef = collection(firestore,"interests")
        if(mainModule === "Interests"){
            const q = query(docRef,where("interestCategory","==",quali))
            intData = await getDocs(q)
        }else{
            intData = await getDocs(docRef)
        }
        demoData.push("All")
        intData.docs.map((doc)=>{
            demoData.push(
                doc.data().interestName
            )
        })
        return demoData;
    }

    const getGuides = async(quali) =>{
        let Category = "";
        if(quali === "Post Graduate"){
            Category = "PG"
        }else{
            Category = "UG"
        }
        const demoData = [];
        let intData;
        const docRef = collection(firestore,"Notes")
            const q = query(docRef,where("noteCategory","==",Category))
            intData = await getDocs(q)
        intData.docs.map((doc)=>{
            console.log("first")
            demoData.push(
                doc.data().noteName
            )
        })
        return demoData;
    } 

    const getColleges = async(quali) =>{
        let Category = "";
        if(quali === "Post Graduate"){
            Category = "PG"
        }else{
            Category = "UG"
        }
        const demoData = [];
        let intData;
        const docRef = collection(firestore,"College")
            const q = query(docRef,where("collegeCategory","==",Category))
            intData = await getDocs(q)
        intData.docs.map((doc)=>{
            demoData.push(
                doc.data().collegeName
            )
        })
        return demoData;
    }

    const getPlans = async() =>{
        const demoData = []
        const docRef = collection(firestore,"Plans")
        const intData = await getDocs(docRef)
        intData.docs.map((doc)=>{
            demoData.push(
                doc.data().planName
            )
        })
        return demoData;
    }

    function htmlToPlainText(html) {
    var temporaryElement = document.createElement("div");
    temporaryElement.innerHTML = html;
    return temporaryElement.textContent || temporaryElement.innerText || "";
}

    const getNotificationData = async(subModName) =>{
        const demoData = [];
        const docsRef = collection(firestore,"ExamNotification")
        const q = query(docsRef,where("examName","==",subModName),orderBy('postDate','desc'))
        const data = await getDocs(q)
        data.docs.map ((doc)=>{
                demoData.push(
                    {
                        _id : doc.id,
                        uploadDate : dayjs(doc.data().postDate.toDate()).format("DD/MM/YYYY hh:mm A").split(" "),
                        notInfo : doc.data().examInfo,
                        adminName : `Admin : ${doc.data().adminName}`,
                        notURL : doc.data().examURL
                    }
                )
        })
        return demoData
    }

    const getInterestsData = async(subModName) =>{
        console.log(subModName)
        const demoData = [];
        const docsRef = collection(firestore, "interests")
        const q = query(docsRef,where("interestName","==",subModName))
        const docu = await getDocs(q)
        let interestDoc
        if(!docu.empty){
            docu.docs.map((dc)=>{
                console.log(dc.id)
                interestDoc = dc.id
            })
            console.log(interestDoc)
            const commentRef = collection(firestore, `interests/${interestDoc}/Comments`)
            const q = query(commentRef,orderBy('date','desc'))
            const comments = await getDocs(q);
            if(!comments.empty){
                comments.docs.map((dc)=>{
                    console.log(dc.data().commentData)
                    demoData.push({
                        _id : dc.id,
                        uploadDate : dayjs(dc.data().date.toDate()).format("DD/MM/YYYY hh:mm A").split(" "),
                        notInfo : dc.data().commentData,
                        adminName : `Admin : ${dc.data().adminName}`
                    })
                })
            }

        }
        console.log(demoData)
        return demoData;
    }



    const getPlansData = async(subModName) =>{
        console.log(subModName)
        const demoData = [];
        const docsRef = collection(firestore, "Plans")
        const q = query(docsRef,where("planName","==",subModName))
        const docu = await getDocs(q)
        let planDoc
        if(!docu.empty){
            docu.docs.map((dc)=>{
                console.log(dc.id)
                planDoc = dc.id
            })
            const commentRef = collection(firestore, `Plans/${planDoc}/Comments`)
            const q = query(commentRef,orderBy('date','desc'))
            const comments = await getDocs(q);
            if(!comments.empty){
                comments.docs.map((dc)=>{
                    console.log(dc.data().commentData)
                    demoData.push({
                        _id : dc.id,
                        uploadDate : dayjs(dc.data().date.toDate()).format("DD/MM/YYYY hh:mm A").split(" "),
                        notInfo : dc.data().commentData,
                        adminName : `Admin : ${dc.data().adminName}`
                    })
                })
            }

        }
        console.log(demoData)
        return demoData;
    }

    const getGuidesData = async(subModName) =>{
        console.log(subModName)
        const demoData = [];
        const docsRef = collection(firestore, "Notes")
        const q = query(docsRef,where("noteName","==",subModName))
        const docu = await getDocs(q)
        let guideDoc
        if(!docu.empty){
            docu.docs.map((dc)=>{
                console.log(dc.id)
                guideDoc = dc.id
            })
            const commentRef = collection(firestore, `Notes/${guideDoc}/Comments`)
            const q = query(commentRef,orderBy('date','desc'))
            const comments = await getDocs(q);
            if(!comments.empty){
                comments.docs.map((dc)=>{
                        
                    console.log(dc.data().commentData)
                    demoData.push({
                        _id : dc.id,
                        uploadDate : dayjs(dc.data().date.toDate()).format("DD/MM/YYYY hh:mm A").split(" "),
                        notInfo : dc.data().commentData,
                        adminName : `Admin : ${dc.data().adminName}`
                    })
                })
            }

        }
        console.log(demoData)
        return demoData;
    }

    const getCollegesData = async(subModName) =>{
        console.log(subModName)
        const demoData = [];
        const docsRef = collection(firestore, "College")
        const q = query(docsRef,where("collegeName","==",subModName))
        const docu = await getDocs(q)
        let guideDoc
        if(!docu.empty){
            docu.docs.map((dc)=>{
                console.log(dc.id)
                guideDoc = dc.id
            })
            const commentRef = collection(firestore, `College/${guideDoc}/Comments`)
            const q = query(commentRef,orderBy('date','desc'))
            const comments = await getDocs(q);
            if(!comments.empty){
                comments.docs.map((dc)=>{
                    console.log(dc.data().commentData)
                    demoData.push({
                        _id : dc.id,
                        uploadDate : dayjs(dc.data().date.toDate()).format("DD/MM/YYYY hh:mm A").split(" "),
                        notInfo : dc.data().commentData,
                        adminName : `Admin : ${dc.data().adminName}`
                    })
                })
            }

        }
        console.log(demoData)
        return demoData;
    }
        // const docRef = doc(firestore,`College/${guideDoc}/Comments/${dc.id}`)
        //         const tmpDate = dayjs(dc.data().date,'DD/MM/YYYY hh:mm A')
        //         const nDate = Timestamp.fromDate(tmpDate.toDate())
        //         updateDoc(docRef,{date : nDate}).then(()=>{
        //             console.log("doc updated successfully")
        //         }).catch((error)=>{
        //             console.log(error)
        //         })
    const getUsersData = async() =>{
        console.log("first")
        const demoData = []
        const userRef = collection(firestore,"Users")
        const userDocs = await getDocs(userRef);
        const UserCommentsDoc = userDocs.docs.map(async(dc)=>{
            const user = {
                _id : dc.id,
                Name : `${dc.data().fullName} | ${dc.data().email} | ${dc.data().phoneNumber}`
            }
            const commentRef = collection(firestore,`Users/${dc.id}/Comments`)
            const q = query(commentRef,orderBy('date','desc'))
            const comments = await getDocs(q);
            if (!comments.empty) {
                
                comments.docs.map((comment)=>{
                console.log(comment.data().commentData)
                demoData.push({
                    ...user,
                    uploadDate : dayjs(comment.data().date.toDate()).format("DD/MM/YYYY hh:mm A").split(" "),
                    notInfo : comment.data().commentData,
                    adminName : `Admin : ${comment.data().adminName}`
                })
            })
            }
        })
        await Promise.all(UserCommentsDoc)
        console.log(demoData)
        return demoData
    }

    const getMainData = async(subModName) =>{
        if(mainModule === "Notifications"){
            const data = await getNotificationData(subModName);
            setoriginalData(data)
            setmainData(data)
        }
        else if(mainModule === "Interests"){
            const data = await getInterestsData(subModName);
            setoriginalData(data)
            setmainData(data)
        }
        else if(mainModule === "Plan"){
            const data = await getPlansData(subModName)
            setoriginalData(data)
            setmainData(data)
        }else if(mainModule === "Expert guide"){
            const data = await getGuidesData(subModName)
            setoriginalData(data)
            setmainData(data)
        }else if(mainModule === "Colleges"){
            const data = await getCollegesData(subModName)
            setoriginalData(data)
            setmainData(data)
        }
    }

    const getSubModule = async(moduleName) =>{
        console.log(moduleName)
        let intArr = []
        if(moduleName==="Notifications" || moduleName === "Interests"){
            if(moduleName === "Interests" && qualification === null){
                    alert("Please select qualification.")
            }else{
                intArr = await getInterests();
                setsubModule(intArr)
            }
            
        }else if(moduleName === "Plan"){
            const planArr = await getPlans();
            setsubModule(planArr)
        }else if(moduleName === "Users"){
            const userArray = await getUsersData()
            setoriginalData(userArray)
            setmainData(userArray)
        }else if(moduleName === "Expert guide"){
            alert("Please select qualification.")
        }
    }

    const handleQuali = async(value) =>{
        setsubStart("")
        setsubEnd("")
        setFilterActive(false)
        setQualification(value)
        if(mainModule === "Interests"){
            const intArr = await getInterests(value)
            setsubModule(intArr)
        }else if(mainModule === "Expert guide"){
            const guideArr = await getGuides(value)
            setsubModule(guideArr)
        }else if(mainModule === "Colleges"){
            const collegeData = await getColleges(value)
            setsubModule(collegeData)
        }
    }

    const handleSearch = (e) =>{
        setmainData(originalData.filter((dt)=>dt.adminName.toLowerCase().includes(e.target.value.toLowerCase())))
    }

    const handleClickDateEnd= (e) =>{
  //const value = dayjs(e.target.value).format('DD/MM/YYYY')
  setsubEnd(e.target.value)
  //console.log(value)
}

const handleClickDateStart =(e) =>{
  //const value = dayjs(e.target.value).format('DD/MM/YYYY')
  setsubStart(e.target.value)
  //console.log(value)
}

const handleFilter = () =>{
  if (isFilActive) {
      setsubStart("")
      setsubEnd("")
      setFilterActive(false)
      setmainData(originalData)
    }
    else if(subStart !== "" && subEnd !== ""){

    // Ensure subStart and subEnd are dayjs objects
    let subSt = dayjs(subStart, 'YYYY-MM-DD');
    let subEn = dayjs(subEnd, 'YYYY-MM-DD');
    if (subSt.isAfter(subEn) || subSt.isSame(subEn)) {
        setdateError(true);
        return;
      } else {
        setdateError(false);
      }
    // Filter dataKey using dayjs isBetween with consistent formats
    setmainData(originalData.filter((dt) => 
        dayjs(`${dt.uploadDate[0]} ${dt.uploadDate[1]} ${dt.uploadDate[2]}`, 'DD/MM/YYYY hh:mm A').isBetween(subSt, subEn, null, '[]')
    ));

    setFilterActive(true);
    }
    else if(subStart === ""){
      alert("Please select the start date first")
    }
}

    useEffect(()=>{
        onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/auth.user
          const uid = user.uid;
            setadminName(user.email)
          // ...
        } else {
          // User is signed out
          // ...
          console.log("no user")
          navigate("/")
        }
      });
    },[])
  return (
    <div style={{"margin":"auto"}} className="pr-5 pt-1 pb-10 pl-5">
        <NavBar/>
        <div className='flex gap-x-8 pb-10 w-3/5 pr-20 flex-wrap ml-72 gap-y-6 mt-16'>
            
                <div className='flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}} for="interestCategory">Select Main Module</label>
                    
                    
                            <Select defaultValue = "All" onChange={(e,value)=>{
                        
                        console.log(value)
                        setsubStart("")
                        setsubEnd("")
                        setFilterActive(false)
                        setmainData([])
                        setsubModule([])
                        setQualification(null)
                        setmainModule(value)
                        getSubModule(value)
                        }} id="interestCategory" placeholder="Select main module :" className='w-96 h-11 pl-3 pr-5'>
                    {
                        Modules.map((module)=>{
                            return(
                                <Option value={module}>{module}</Option>
                            )
                        })
                    }
                    </Select>
                </div>
                {(mainModule === "Interests" || mainModule === "Expert guide" || mainModule==="Colleges")&&<div className='flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}} for="interestCategory">Select Qualification:</label>

                    <Select defaultValue="select" onChange={(e,value)=>{ handleQuali(value)
                        }} id="interestCategory" placeholder="Select Qualification :" className='w-96 h-11 pl-3 pr-5'>
                    {(mainModule==="Interests")&&<Option value="All">All</Option>}
                    <Option value="Under Graduate">Under Graduate</Option>
                    <Option value="Post Graduate">Post Graduate</Option>
                    </Select>
                </div>}
                <div className='flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}} for="interestCategory">Select Sub Module:</label>

                    <Select placeholder="Select Submodule :" defaultValue="subSelect" disabled={(mainModule==="Users")?true:false} onChange={(e,value)=>{
                        setsubStart("")
                        setsubEnd("")
                        setFilterActive(false)
                        setsubModuleCategory(value),
                        getMainData(value)
                        }} id="interestCategory" className={(mainModule === "Users")?'w-96 h-11 pl-3 pr-5 border-[#bcbcbc] text-[#bcbcbc]':'w-96 h-11 pl-3 pr-5'}>
                    {
                        (subModule.length!==0) && subModule.map((module)=>{
                            return(
                                <Option value={module}>{module}</Option>
                            )
                        })
                    }
                    </Select>
                </div>
                <div className='flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}} for="interestCategory">Search :</label>
                    <input type="Text" placeholder="Search ..." onChange={handleSearch} className="w-56 h-10 pl-5 border-solid border-[#9E9B9B] rounded-xl border-2"/>
                </div>
                <div className='flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}}>Select Date :</label>
                    <div className="flex relative gap-x-3 pr-2">
                        <input onChange={handleClickDateStart} type="Date" value={subStart} className="w-40 h-10 border-solid border-2 border-[#9E9B9B] bg-white rounded-lg pl-3 pr-1" />
                        <span className="mt-3">to</span>
                        <input onChange={handleClickDateEnd} type="Date" value={subEnd} placeholder="dd-mm-yy"  className="w-40 h-10 border-solid border-2 border-[#9E9B9B] rounded-lg pl-3 pr-1" />
                        <button style={{"fontFamily":"Segoe UI"}} onClick={handleFilter} className={(isFilActive)?clearfilterCss:filterCss}>{(isFilActive)?"Clear":"Filter"}</button>
                        {(dateError)&&<p className="text-rose-600 absolute top-8 left-1">Please validate the dates.</p>}
                    </div>
                </div>
        </div>
        <div className='ml-72'>
            <div className='text-lg mb-4'>{(mainModule !== null)?mainModule:""} audit data :</div>
            <div className='flex flex-col gap-y-4'>
                {
                    (mainData.length !== 0) && mainData.map((data)=>{
                        return(
                            <div className='flex flex-col pb-4 border-b-2 border-dashed border-[#707070]'>
                                    <div>{dayjs(data.uploadDate[0],'DD/MM/YYYY').format('DD MMM YYYY')} | {data.uploadDate[1]} {data.uploadDate[2]}</div>
                                    {(mainModule==="Users")&&<div>{data.Name}</div>}
                                    <div>{htmlToPlainText(data.notInfo)}</div>
                                    <div>{data.adminName}</div>
                                    {(data.notURL)&&<div><a href={data.notURL}>{data.notURL}</a></div>}
                            </div>
                        )
                    })
                }
            </div>
        </div>
    </div>
  )
}

export default Audit