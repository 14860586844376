import React from 'react'
import NavBar from './NavBar';
import DataTable from 'react-data-table-component';
import { useNavigate } from "react-router-dom";
import {getFirestore, doc,getDoc,collection, getDocs , onSnapshot} from "firebase/firestore"
import { app } from "../firebase";
import { useEffect, useState } from "react";
import {getAuth, onAuthStateChanged} from "firebase/auth"
const auth = getAuth(app)
const firestore = getFirestore(app)

const columns = [
  {
    name : 'Sr NO',
    selector : row => row.SrNo,
    left : true
  },
  {
    name : 'Name',
    selector : row=>row.Name,
    sortable :true,
    left : true,
    conditionalCellStyles: [
			{
				when: row => row.Name,
				style: {
					color: '#5F8EFC',
					'&:hover': {
						cursor: 'pointer',
					},
				},
			},
    ]
  },
  {
    name : 'Plan Price',
    selector : row=>row.PlanPrice,
    sortable : true,
    left : true
  },
  {
    name : 'Days Active For',
    selector : row=>row.DaysActive,
    sortable :true,
    left : true
  },
  {
    name : 'Interests Selectable',
    selector : row=>row.InterestSelectable,
    sortable :true,
    left : true
  },
  {
    name : 'Selected By Users',
    selector : row=>row.SelByUsers,
    sortable : true,
    left : true
  },
  {
    name : 'Status',
    selector : row=>row.Status,
    sortable :true,
    left : true
  }
]

const customStyles = {
  headRow :{
    style: {
			// backgroundColor: theme.background.default,
      fontSize : '16px',
      fontWeight : 'bold',
      fontFamily : 'Segoe UI',
			minHeight: '52px',
			borderBottomWidth: '1px',
			borderBottomColor: '#8A8A8A',
			borderBottomStyle: 'solid',
		},

  },
  rows : {
    style : {
      fontSize: '16px',
			fontWeight: 400,
      fontFamily : 'Segoe UI',
			// color: theme.text.primary,
			// backgroundColor: theme.background.default,
			minHeight: '48px',
			'&:not(:last-of-type)': {
				borderBottomStyle: 'solid',
				borderBottomWidth: '1px',
				borderBottomColor: '#8A8A8A'
			},
    }
  },
  pagination :{
    style: {
			fontSize: '16px',
			minHeight: '56px',
      fontFamily : 'Segoe UI',
			borderTopStyle: 'solid',
			borderTopWidth: '1px',
			borderTopColor: '#8A8A8A',
      color : 'black'
		},
  }
}
const conditionalRowStyles = [
			{
				when: row => row.Status === "Inactive",
				style: {
					backgroundColor: '#F1F1F1'
				}
			}]


export default function Plan() {
  const [originalData, setoriginalData] = useState([])
  const [planData , setPlanData] = useState(originalData)
  const navigate = useNavigate()

  let srNo = 0;
  const handleSearch = (e) =>{
    console.log("first")
    setPlanData(originalData.filter((dt)=>dt.Name.toLowerCase().includes(e.target.value.toLowerCase())))
  }

  const handleChange = (e) =>{
      navigate("/PlanManagement/EditPlan/"+e._id)
  }

  const handleClick = () =>{
      navigate("/PlanManagement/createPlan")
  }

  const handleGetData = () =>{
    
    const ref = collection(firestore, "Plans");

    // Subscribe to real-time changes
    const unsubscribe = onSnapshot(ref, (snapshot) => {
        const demoData = [];
        snapshot.forEach((dc) => {
            console.log("snap ",dc.data())
            demoData.push({
                SrNo: ++srNo,
                _id: dc.id,
                Name: dc.data().planName,
                DaysActive : dc.data().days,
                InterestSelectable : dc.data().interest,
                SelByUsers: dc.data().counter,
                Status: (dc.data().active)?"Active":"Inactive",
                PlanPrice : dc.data().rs
            });
        });
        setoriginalData(demoData)
        setPlanData(demoData); // Update state with new data
    });

    // Return unsubscribe function to clean up the listener when component unmounts
    return unsubscribe;
  }

  useEffect(()=>{
    
    onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/auth.user
          const uid = user.uid;
          console.log(user)
          handleGetData()
          // ...
        } else {
          // User is signed out
          // ...
          console.log("no user")
          navigate("/")
        }
      });
  },[])

  return (
    <div style={{"margin":"auto"}} className="pr-5 pt-1 pl-5">
        <NavBar />
        <div className="pr-4 pl-4">
          <div className="flex justify-between mt-10 mb-10">
            <h2 className="text-2xl font-semibold">
                Plan Management ({planData.length})
            </h2>
              <button onClick={handleClick} className="w-52 h-12 bg-[#4F72C5] mt-2 text-base tracking-wider text-white border-none rounded-2xl">Create Plan</button>
          </div>
          <div className="flex justify-between mt-5 mb-5">
            <input onInput={handleSearch} type="Text" placeholder="Search By Plan Name" className="pl-5 w-56 h-10 rounded-xl border-2 border-[#9E9B9B] border-solid"/>
          </div>
          <div>
            <DataTable 
              columns={columns}
              data={planData}
              onRowClicked={handleChange}
              customStyles={customStyles}
              conditionalRowStyles={conditionalRowStyles}
              pagination
            />
          </div>
        </div>
      </div>
  )
}
