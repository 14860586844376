import React from 'react';
import NavBar from './NavBar';

const TermsAndConditions = () => {
    return (
        <div>
            <NavBar />
        <div className="p-8">
            <h1 className="text-3xl font-bold text-center mb-6">EazyCareer Privacy Policy</h1>
            <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
                <h2 className="text-2xl font-semibold mb-4">Terms and Conditions of Use for Eazy Career App</h2>
                <p className="text-base mb-4">Welcome to Eazy Career App! Before you proceed, please take a moment to review our Terms and Conditions of Use. By accessing or using our educational app, you agree to comply with and be bound by these terms. If you do not agree with any part of these terms, please do not use the app.</p>
                <h3 className="text-base font-semibold mb-2">1. Acceptance of Terms:</h3>
                <p className="text-base mb-4">By using Eazy Career App, you agree to abide by these terms and any future updates or modifications.</p>
                <h3 className="text-base font-semibold mb-2">2. User Eligibility:</h3>
                <p className="text-base mb-4">You must be of legal age to enter into a binding agreement or have parental consent to use the app. If you are a minor, please seek the consent of a parent or guardian.</p>
                <h3 className="text-base font-semibold mb-2">3. License to Use:</h3>
                <p className="text-base mb-4">We grant you a non-exclusive, non-transferable license to use Eazy Career App for personal use only, non-commercial purposes. You may not sublicense, transfer, or assign your rights.</p>
                <h3 className="text-base font-semibold mb-2">4. User Conduct:</h3>
                <p className="text-base mb-4">You agree to use the app for lawful purposes only and not to engage in any prohibited activities, including but not limited to hacking, spreading malware, or violating intellectual property rights.</p>
                <h3 className="text-base font-semibold mb-2">5. Content:</h3>
                <p className="text-base mb-4">Any content provided within the app, including text, images, videos, and educational materials, is for informational purposes only. We do not guarantee the accuracy, completeness, or usefulness of this content.</p>
                <h3 className="text-base font-semibold mb-2">6. Privacy Policy:</h3>
                <p className="text-base mb-4">Your use of Eazy Career App is also governed by our Privacy Policy, which outlines how we collect, use, and protect your personal information. Please review the Privacy Policy to understand our practices.</p>
                <h3 className="text-base font-semibold mb-2">7. Account Security:</h3>
                <p className="text-base mb-4">If you create an account with Eazy Career App, you are responsible for maintaining the security of your account information, including your username and password.</p>
                <h3 className="text-base font-semibold mb-2">8. Termination:</h3>
                <p className="text-base mb-4">We reserve the right to terminate or suspend your access to Eazy Career App at any time, with or without cause.</p>
                <h3 className="text-base font-semibold mb-2">9. Changes to Terms:</h3>
                <p className="text-base mb-4">We may update or modify these terms at any time without notice. It is your responsibility to check for updates periodically.</p>
                <h3 className="text-base font-semibold mb-2">10. Limitation of Liability:</h3>
                <p className="text-base mb-4">To the extent permitted by law, Eazy Career App and its affiliates are not liable for any direct, indirect, incidental, consequential, or special damages arising out of or in any way connected with your use of the app.</p>
                <h3 className="text-base font-semibold mb-2">11. Governing Law:</h3>
                <p className="text-base mb-4">These terms are governed by and construed in accordance with the laws.</p>
                <h3 className="text-base font-semibold mb-2">Contact Information:</h3>
                <p className="text-base mb-4">If you have any questions or concerns about these terms, please contact us at eazycareerpune@gmail.com.</p>
                <p className="text-base">Thank you for using Eazy Career App!</p>
            </div>
        </div>
    </div>
    );
};

export default TermsAndConditions;
