import React from 'react'
import NavBar from './NavBar'
import { useNavigate,useLocation} from 'react-router-dom'
import { useState,useEffect } from 'react'
import {Option,Select} from "./customSelect"
import { Fragment, useRef } from 'react'
import DialogBox from "./dialogPop"
import dayjs from 'dayjs'
import {getFirestore, doc, getDoc,collection, getDocs, addDoc, updateDoc,query,where, Timestamp} from "firebase/firestore"
import { app } from "../firebase";
import {getAuth, onAuthStateChanged} from "firebase/auth"
const auth = getAuth(app)
const firestore = getFirestore(app)

export default function EditInterest() {
    const [intName, setinterestName] = useState("")
    const [value,setValue] = useState(true)
    const [status,setStatus] = useState(true)
    const [open, setOpen] = useState(false)
    const [comment, setComment] = useState(null)
    const [commentError, setcommentError] = useState(false)
    const [intCategory,setIntCategory] = useState("")
    const [error, setError] = useState(false)
    const [invalidInputError, setinvalidInputError] = useState(false)
    const [adminName,setadminName] = useState("");
    const cancelButtonRef = useRef(null)
    const navigate = useNavigate()
    const loc = useLocation();
    const interestId = loc.pathname.split("/")[3]
    var regex = /^[a-zA-Z0-9\s]+$/;

    const handleClick = async() =>{
        if(intName === ""){
          setError(true);
          return;
        }
        if(!regex.test(intName)){
          setinvalidInputError(true);
          return;
        }
        if(comment !== null){
          handleComment()
        }
          const interestDocRef = doc(firestore, `interests/${interestId}`);
        updateDoc(interestDocRef, { 
            active : status,
            interestName : intName.toUpperCase(),
            isFree : value,
            interestCategory : intCategory
        })
        .then(() => {
        console.log('User document updated successfully.');
        })
        .then(
          navigate("/InterestManagement")
        )
        .catch((error) => {
        console.error('Error updating user document:', error);
        alert("Error updating the document. Please try to refresh.")
    })
}

    const handleComment = () =>{
      if(comment === ""){
        setcommentError(true);
        return;
      }
      
      const addCom = {
        date : Timestamp.now(),
        adminName : adminName,
        commentData : comment
      }

      const commentsColRef = collection(firestore,"interests/"+interestId+"/Comments")
      addDoc(commentsColRef,addCom)
      .then((docRef) => {
        console.log('Comment added with ID: ', docRef.id);

      })
      .catch((error) => {
        console.error('Error adding comment: ', error);
        alert("Error updating the document. Please try to refresh.")
      });
    }

    const handlegetInterestData = () =>{
            const docRef = doc(firestore,`interests/${interestId}`)

            // Fetch data from the document
            getDoc(docRef)
            .then((dc)=>{
                console.log(dc.data())
                setinterestName(dc.data().interestName)
                setValue(dc.data().isFree)
                setStatus(dc.data().active)
                setIntCategory(dc.data().interestCategory)
            })  
            .catch((error)=>{
                console.log(error)
                alert("Error fetching the data from database. Please try to refresh");
            })
    }

    useEffect (()=>{
      onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/auth.user
          const uid = user.uid;
          console.log(user)
          setError(false)
          setinvalidInputError(false)
          setadminName(user.email)
          handlegetInterestData()
          // ...
        } else {
          // User is signed out
          // ...
          console.log("no user")
          navigate("/")
        }
      });
    },[])
  return (
    <div style={{"margin":"auto"}} className="pr-5 pt-1 pl-5">
        <NavBar />
        <div className="flex justify-between pl-10 pr-10 mb-5 mt-5">
            <h2 className="text-2xl font-semibold">
                Interest Management &gt; Edit Interest &gt; {intName}
            </h2>
            <div className='flex gap-x-6'>
                <button onClick={()=>navigate("/InterestManagement")} className="w-40 h-12 bg-transparent border-solid tracking-wider border-2 mt-2 text-base text-[#32497F]  rounded-2xl border-[#32497F]">Cancel</button>
                <button onClick={handleClick} className="w-40 text-white h-12 bg-[#4F72C5] text-base border-none mt-2 tracking-wider rounded-2xl">Save</button>
            </div>
        </div>
        <div className="ml-56 pl-20 h-80">
            <div className="flex flex-col w-80 pt-4 h-96 gap-y-10">
                <div className='relative flex flex-col gap-y-3'>
                    <label className='pl-1' style={{"fontSize":"16px"}}>Name</label>
                    <input onChange={(e)=>{
                      setError(false)
                      setinvalidInputError(false)
                        setinterestName(e.target.value)}} value={intName} className={(error || invalidInputError)?"w-96 h-10 rounded-xl border-solid border-2 pl-4 border-rose-500":"w-96 h-10 rounded-xl border-solid border-2 pl-4 border-[#9E9B9B]"} type='text'/>
                    {(error)&&<div className='absolute top-20 pl-1'><label className='text-rose-500'>Interest name required.</label></div>}
                    {(invalidInputError)&&<div className='absolute top-20 pl-1'><label className='text-rose-500'>Numbers, special characters are not allowed.</label></div>}
                </div>
                <div className='flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}} for="interestCategory" className='pl-1'>Qualification Selected:</label>

                    <Select onChange={(e,value)=>{setIntCategory(value)}} id="interestCategory" value={intCategory} className='w-96 h-10 pl-3 rounded-xl border-2 border-solid border-[#9E9B9B]'>
                    <Option value="All">Open For All</Option>
                    <Option value="Under Graduate">Under Graduate</Option>
                    <Option value="Post Graduate">Post Graduate</Option>
                    </Select>
                </div>
                <div className='flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}} className='pl-1'>Value</label>
                    <label class="inline-flex items-center cursor-pointer">
                    <input type="checkbox" onChange={()=>setValue(!value)} value="" class="sr-only peer" checked={(value)?false:true}/>
                    <span class="ms-1 text-sm font-normal text-gray-900 dark:text-gray-300 mr-3">Free</span>
                    <div class="relative w-11 h-6 bg-[#b6b8b7] rounded-full peer peer-focus:ring-4 peer-focus:ring-white dark:peer-focus:ring-white dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#818181]"></div>
                    <span class="ms-3 text-sm font-normal text-gray-900 dark:text-gray-300 ml-3">Paid</span>
                    </label>
                </div>
                <div className='flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}} className='pl-1'>Status</label>
                    <label class="inline-flex items-center cursor-pointer">
                    <input type="checkbox" onChange={()=>setOpen(true)} value="" class="sr-only peer" checked={(status)?true:false}/>
                    <span class="ms-1 text-sm font-normal text-gray-900 dark:text-gray-300 mr-3">Inactive</span>
                    <div class="relative w-11 h-6 bg-[#b6b8b7] rounded-full peer peer-focus:ring-4 peer-focus:ring-white dark:peer-focus:ring-white dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#818181]"></div>
                    <span class="ms-3 text-sm font-normal text-gray-900 dark:text-gray-300 ml-3">Active</span>
                    </label>
                </div>
            </div>
        </div>
        <DialogBox 
          dialogTitle = "Change Interest Status"
          data = {intName}
          open = {open}
          setOpen = {setOpen}
          comment = {comment}
          setComment = {setComment}
          commentError = {commentError}
          setcommentError={setcommentError}
          statusVal = {status}
          setStatusVal = {setStatus}
        />

    </div>
  )
}
