import React from 'react'
import NavBar from '../NavBar'
import DataTable from "react-data-table-component";
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import {getFirestore, doc,getDoc,collection, getDocs, onSnapshot} from "firebase/firestore"
import {getAuth, onAuthStateChanged} from "firebase/auth"
import { app } from "../../firebase";
import ServiceNavbar from "./ServiceNavbar"
import dayjs from 'dayjs';
var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)
const firestore = getFirestore(app)
const clearfilterCss = "w-40 h-11 bg-transparent cursor-pointer text-base tracking-wider text-[#32497F] border-solid border-2 border-[#32497F] rounded-lg";
const filterCss = "w-40 h-11 bg-[#4F72C5] cursor-pointer text-base tracking-wider text-white border-solid border-2 border-[#4F72C5] rounded-lg"

const columns = [
  {
    name : 'Sr NO',
    selector : row => row.SrNo,
    width :"80px",
    left : true
  },
  {
    name : 'GuideFor',
    selector : row=>row.Name,
    sortable :true,
    left : true,
    conditionalCellStyles: [
			{
				when: row => row.Name,
				style: {
					color: '#5F8EFC',
					'&:hover': {
						cursor: 'pointer',
					},
				},
			},
    ]
  },
  {
    name : 'Status',
    selector : row=>row.Status,
    left : true
  },
  {
    name : 'Downloaded By Users',
    selector : row=>row.Counter,
    sortable :true,
    left : true
  },
  {
    name : 'Price',
    selector : row=>row.GuidePrice,
    sortable :true,
    left : true
  },
  {
    name : 'Uploaded On',
    selector : row=>row.uploadDate,
    left : true
  }
]

const customStyles = {
  headRow :{
    style: {
			// backgroundColor: theme.background.default,
      fontSize : '16px',
      fontWeight : 'bold',
      fontFamily : 'Segoe UI',
			minHeight: '52px',
			borderBottomWidth: '1px',
			borderBottomColor: '#8A8A8A',
			borderBottomStyle: 'solid',
		},

  },
  rows : {
    style : {
      fontSize: '16px',
			fontWeight: 400,
      fontFamily : 'Segoe UI',
			// color: theme.text.primary,
			// backgroundColor: theme.background.default,
			minHeight: '48px',
			'&:not(:last-of-type)': {
				borderBottomStyle: 'solid',
				borderBottomWidth: '1px',
				borderBottomColor: '#8A8A8A'
			},
    }
  },
  pagination :{
    style: {
			fontSize: '16px',
			minHeight: '56px',
      fontFamily : 'Segoe UI',
			borderTopStyle: 'solid',
			borderTopWidth: '1px',
			borderTopColor: '#8A8A8A',
      color : 'black'
		},
  }
}

const conditionalRowStyles = [
			{
				when: row => row.Status === "Inactive",
				style: {
					backgroundColor: '#F1F1F1'
				}
			}]

function ExpertGuide() {
    let srNo = 0;
    const navigate = useNavigate();
    const [originalData, setoriginalData] = useState([])
    const [dataKey, setDataKey] = useState(originalData)
    const [subStart, setsubStart] = useState("")
    const [subEnd, setsubEnd] = useState("")
    const [dateError, setdateError] = useState(false)
    const [isFilActive, setFilterActive] = useState(false)

    

    const handleSearch = (e) =>{
      setDataKey(originalData.filter((dt)=>dt.Name.toLowerCase().includes(e.target.value.toLowerCase())))
    }

    const handleClickDateEnd= (e) =>{
  //const value = dayjs(e.target.value).format('DD/MM/YYYY')
  setsubEnd(e.target.value)
  //console.log(value)
}

const handleClickDateStart =(e) =>{
  //const value = dayjs(e.target.value).format('DD/MM/YYYY')
  setsubStart(e.target.value)
  //console.log(value)
}

    const handelFilter  = () =>{
      console.log("i am in")
  if (isFilActive) {
      setsubStart("")
      setsubEnd("")
      setFilterActive(false)
      setDataKey(originalData)
    }
    else if(subStart !== "" && subEnd !== ""){
      console.log("First");

    // Ensure subStart and subEnd are dayjs objects
    let subSt = dayjs(subStart, 'YYYY-MM-DD');
    let subEn = dayjs(subEnd, 'YYYY-MM-DD');
    if (subSt.isAfter(subEn) || subSt.isSame(subEn)) {
        setdateError(true);
        return;
      } else {
        setdateError(false);
      }
    // Filter dataKey using dayjs isBetween with consistent formats
    setDataKey(dataKey.filter((dt) => 
      dayjs(dt.uploadDate).isBetween(subSt, subEn, null, '[]')
    ));

    setFilterActive(true);
    }
    else if(subStart === ""){
      alert("Please select the start date first")
    }
  }

    const handleChange = (e) =>{
      navigate("/Services/ExpertGuide/"+e._id)
    }

    const handleGetGuides = () =>{
        const ref = collection(firestore, "Notes");

    // Subscribe to real-time changes
    const unsubscribe = onSnapshot(ref, (snapshot) => {
        const demoData = [];
        snapshot.forEach((dc) => {
            console.log("snap ",dc.data())
            demoData.push({
                SrNo: ++srNo,
                _id: dc.id,
                Name: dc.data().noteName,
                Status : (dc.data().active)?"Active":"Inactive",
                Counter: dc.data().counter,
                GuidePrice: dc.data().noteRs,
                uploadDate : dc.data().uploadDate
            });
        });
        setoriginalData(demoData)
        setDataKey(demoData)
    });

    // Return unsubscribe function to clean up the listener when component unmounts
    return () => unsubscribe();
    }

    useEffect (()=>{
        handleGetGuides();
    },[])


  return (
    <div style={{"margin":"auto"}} className="pr-5 pt-1 pl-5">
        <NavBar />
        <div className='flex'>
          <ServiceNavbar />
        <button onClick={()=>(navigate("/Services/ExpertGuide/NewGuide"))} className='w-52 h-12 bg-[#4F72C5] cursor-pointer tracking-wider text-base text-white mt-4 border-none rounded-2xl '>New Guide</button>
        </div>
        <div className="pr-4 pl-4">
          {/* <div className="flex justify-between mt-10 mb-10">
            <h2 className="text-2xl font-semibold">
                User Management ({dataKey.length})
            </h2>
              <button onClick={handleClick} className="w-56 h-12 bg-[#4F72C5] cursor-pointer text-lg text-white mt-2 border-none rounded-2xl">Create User</button>
          </div> */}
          <div className="flex justify-between mt-5 mb-5">
            <div className='flex gap-x-10 mt-5 mb-5'>
            <input onInput={handleSearch} type="Text"  placeholder="Search By Guide Name" className="pl-5 w-56 h-10 border-solid border-2 border-[#9E9B9B] text-black rounded-xl"/>
            <p onClick={()=>navigate("/updateImages/expertGuide/updateCarouselImage",{state:{
              storagePath : "guideCarouselImages",
              navigationPath : "Services/ExpertGuide",
              moduleName : "Expert guide"
            }})} className='text-black underline decoration-black'>Update Homepage Content and Images</p>
            </div>
            <div className="flex relative gap-x-3 pr-2 mt-4">
             {/* reduce date input width // add filter button */}
              <input onChange={handleClickDateStart} type="Date" value={subStart} className="border-solid border-2 border-[#9E9B9B] rounded-lg w-36 pl-3 pr-1 h-10" />
              <span className="mt-3">to</span>
              <input onChange={handleClickDateEnd} type="Date" value={subEnd} placeholder="dd-mm-yy"  className="border-solid border-2 border-[#9E9B9B] rounded-lg pl-3 pr-1 w-36 h-10" />
              <button style={{"fontFamily":"Segoe UI"}} onClick={handelFilter} className={(isFilActive)?clearfilterCss:filterCss}>{(isFilActive)?<p className="mt-1.5">Clear</p>:<p className="mt-1.5">Filter</p>}</button>
              {(dateError)&&<p className="text-rose-600 absolute top-8 left-1">Please validate the dates.</p>}
            </div>
          </div>
          <div>
            <DataTable 
              columns={columns}
              data={dataKey}
              onRowClicked={handleChange}
              customStyles={customStyles}
              conditionalRowStyles={conditionalRowStyles}
              pagination
            />
          </div>
        </div>
      </div>
  )
}

export default ExpertGuide