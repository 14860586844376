import React from 'react'
import NavBar from "../components/NavBar";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from 'react-router-dom'
import { useState,useEffect } from 'react'
import {getFirestore, doc,getDoc,collection, getDocs, onSnapshot} from "firebase/firestore"
import {getAuth, onAuthStateChanged} from "firebase/auth"
import { app } from "../firebase";
const firestore = getFirestore(app)
const auth = getAuth(app)
const columns = [
  {
    name : 'Sr NO',
    selector : row => row.SrNo,
    left : true
  },
  {
    name : 'Name',
    selector : row=>row.Name,
    sortable :true,
    left : true,
    conditionalCellStyles: [
			{
				when: row => row.Name,
				style: {
					color: '#5F8EFC',
					'&:hover': {
						cursor: 'pointer',
					},
				},
			},
    ]
  },
  {
    name : 'Value',
    selector : row=>row.Value,
    sortable :true,
    left : true
  },
  {
    name : 'Selected By Users',
    selector : row=>row.SelByUsers,
    sortable :true,
    left : true
  },
  {
    name : 'Status',
    selector : row=>row.Status,
    sortable :true,
    left : true
  }
]

const customStyles = {
  headRow :{
    style: {
			// backgroundColor: theme.background.default,
      fontSize : '16px',
      fontWeight : 'bold',
      fontFamily : 'Segoe UI',
			minHeight: '52px',
			borderBottomWidth: '1px',
			borderBottomColor: '#8A8A8A',
			borderBottomStyle: 'solid',
		},

  },
  rows : {
    style : {
      fontSize: '16px',
			fontWeight: 400,
      fontFamily : 'Segoe UI',
			// color: theme.text.primary,
			// backgroundColor: theme.background.default,
			minHeight: '48px',
			'&:not(:last-of-type)': {
				borderBottomStyle: 'solid',
				borderBottomWidth: '1px',
				borderBottomColor: '#8A8A8A'
			},
    }
  },
  pagination :{
    style: {
			fontSize: '16px',
			minHeight: '56px',
			borderTopStyle: 'solid',
			borderTopWidth: '1px',
			borderTopColor: '#8A8A8A',
      color : 'black'
		},
  }
}
const conditionalRowStyles = [
			{
				when: row => row.Status === "Inactive",
				style: {
					backgroundColor: '#F1F1F1'
				}
			}]

export default function Interest() {
    
    const [originalData, setoriginalData] = useState([])
    const [intData, setIntData] = useState(originalData)
    const navigate = useNavigate();
    let srNo = 0;
    const handleSearch = (e) =>{
    setIntData(originalData.filter((dt)=>dt.Name.toLowerCase().includes(e.target.value.toLowerCase()))) 
  }

    const handleClick = async() =>{
        navigate("/createInterest")
    }

    const handleChange = (e) =>{
        navigate("/InterestManagement/EditInterest/"+e._id)
    }

    const handleGetData = async() =>{
        const ref = collection(firestore, "interests");

    try {
      // Subscribe to real-time changes
    const unsubscribe = onSnapshot(ref, (snapshot) => {
        const demoData = [];
        snapshot.forEach((dc) => {
            console.log("snap ",dc.data())
            demoData.push({
                SrNo: ++srNo,
                _id: dc.id,
                Name: dc.data().interestName,
                SelByUsers: dc.data().counter,
                Value: dc.data().isFree ? "Free" : "Paid",
                Status: dc.data().active ? "Active" : "Inactive"
            });
        });
        setoriginalData(demoData)
        setIntData(demoData); // Update state with new data
    });
    } catch (error) {
      console.log("error");
      alert("Error fetching data from database");
    }

    // Return unsubscribe function to clean up the listener when component unmounts
    return () => unsubscribe();
    }

    useEffect(()=>{
      onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/auth.user
          const uid = user.uid;
          console.log(user)
          handleGetData()
          // ...
        } else {
          // User is signed out
          // ...
          console.log("no user")
          navigate("/")
          
        }
      });
    },[])

  return (
     <div style={{"margin":"auto"}} className="pr-5 pt-1 pl-5">
        <NavBar />
        <div className="pr-4 pl-4">
          <div className="flex justify-between mt-10 mb-10">
            <h2 className="text-2xl font-semibold">
                Interest Management ({intData.length})
            </h2>
              <button onClick={handleClick} className="w-52 h-12 bg-[#4F72C5] mt-2 text-base tracking-wider text-white border-none rounded-2xl">Create Interest</button>
          </div>
          <div className="flex gap-x-10 mt-5 mb-5">
            <input onChange={handleSearch} type="Text" placeholder="Search Interest" className="w-56 h-10 pl-5 border-solid border-[#9E9B9B] rounded-xl border-2"/>
            <p onClick={()=>navigate("/updateImages/InterestManagement/updateInterestImage",{state:{
                storagePath : "imagesInterest",
                navigationPath : "InterestManagement",
                moduleName : "Interest"
            }})} className='text-black cursor-pointer underline decoration-black'>Update Homepage Content and Images</p>
          </div>
          <div>
            <DataTable
                columns={columns}
                data={intData}
                onRowClicked={handleChange}
                customStyles={customStyles}
                conditionalRowStyles={conditionalRowStyles}
                pagination
            />
          </div>
        </div>
      </div>
  )
}
