import React, { useState, useEffect } from 'react'
import { useNavigate,Link } from 'react-router-dom'
import {app} from '../firebase'
import {getAuth, signOut} from "firebase/auth"
const navOptionsArray = [
    {name : "Users",link :"/Dashboard", current:true},
    {name : "Interests",link :"/interestManagement", current:false},
    {name : "Plan",link :"/planManagement/", current:false},
    {name : "Notifications",link :"/Notification", current:false},
    {name : "Services",link :"/Services/ExpertGuide", current:false},
    {name : "Reports",link :"/Dashboard", current:false},
    {name : "Audit History",link :"/Audit", current:false}
]
const auth = getAuth(app)

export default function NavBar() {

    const navigate = useNavigate()
    const [navOptions, setNavOptions] = useState(navOptionsArray);
    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
        const storedIndex = localStorage.getItem('selectedNavIndex');
        if (storedIndex !== null) {
            setSelectedIndex(parseInt(storedIndex));
        }
    }, []);

    const handleLogout = () =>{
        signOut(auth).then(() => {
            console.log("Sign-out successful")
            navigate("/")
        }).catch((error) => {
        // An error happened.
        });
    }

    const handleOptionClick = (index) => {
        setSelectedIndex(index);
        if(navOptionsArray[index].name === "Services"){
            localStorage.setItem("selectedServiceNavIndex",'0')
        }
        localStorage.setItem('selectedNavIndex', index.toString());
    };

  return (
    <div className='width-full h-20 bg-[#E4ECFF]'>
        <div className='width-full h-20 flex items-center'>
            <div className="w-52 h-14 flex items-center pl-4">
                <h2 className='font-normal text-2xl' style={{"fontFamily":"Montserrat"}}>
                    EZC
                </h2>
            </div>
            <div className="flex-1 flex gap-x-4 h-14 items-center justify-center">
                {
                    navOptions.map((op,index)=>{
                    return( <Link key={index} onClick={()=>handleOptionClick(index)} className='text-black no-underline' to={op.link}>
                        <div className='flex gap-x-4'>
                            <div className={(index === selectedIndex )?"font-bold font-base":"font-medium font-base"}>
                            <p className='pl-4'>{op.name}</p>
                        </div>
                        <p>|</p>
                        </div>
                        <div className={(index === selectedIndex)?'mt-0 h-1 rounded-xl bg-[#4F72C5]':""}></div>
                    </Link>)
                    })
                }
            </div>
            <div className="w-52 h-14 flex justify-end items-center pr-4 decoration-1">
                <div onClick={handleLogout} style={{"fontSize":"20px","fontFamily":"Segoe UI"}}>
                    Logout
                </div>
            </div>
        </div>
    </div>
  )
}
