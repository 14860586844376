import NavBar from "../components/NavBar";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import {getFirestore, doc,getDoc,collection, getDocs} from "firebase/firestore"
import { app } from "../firebase";
import {getAuth, onAuthStateChanged} from "firebase/auth"
import { useEffect, useState } from "react";
import dayjs from 'dayjs';
var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)
const firestore = getFirestore(app)
const auth = getAuth(app)
const clearfilterCss = "w-40 h-11 bg-transparent cursor-pointer text-base tracking-wider text-[#32497F] border-solid border-2 border-[#32497F] rounded-lg";
const filterCss = "w-40 h-11 bg-[#4F72C5] cursor-pointer text-base tracking-wider text-white border-solid border-2 border-[#4F72C5] rounded-lg"
const columns = [
  {
    name : 'Sr NO',
    selector : row => row.SrNo,
    width :"80px",
    left : true
  },
  {
    name : 'Name',
    selector : row=>row.Name,
    sortable :true,
    left : true,
    conditionalCellStyles: [
			{
				when: row => row.Name,
				style: {
					color: '#5F8EFC',
					'&:hover': {
						cursor: 'pointer',
					},
				},
			},
    ]
  },
  {
    name : 'Email Id',
    selector : row=>row.EmailId,
    sortable :true,
    left : true
  },
  {
    name : 'Plan Status',
    selector : row=>row.Status,
    sortable :true,
    width :"150px",
    left : true
  },
  {
    name : 'Plan',
    selector : row=>row.Plan,
    sortable :true,
    width :"150px",
    left : true
  },
  {
    name : 'Subscription Start',
    selector : row=>row.SubStart,
    sortable :true,
    width : "200px",
    left : true
  },
  {
    name : 'Subscription End',
    selector : row=>row.SubEnd,
    sortable :true,
    width : "200px",
    left : true
  },
  {
    name : 'Interest',
    selector : row=>row.Interest,
    sortable :true,
    width :"120px",
    left : true
  }
]

const customStyles = {
  headRow :{
    style: {
			// backgroundColor: theme.background.default,
      fontSize : '16px',
      fontWeight : 'bold',
      fontFamily : 'Segoe UI',
			minHeight: '52px',
			borderBottomWidth: '1px',
			borderBottomColor: '#8A8A8A',
			borderBottomStyle: 'solid',
		},

  },
  rows : {
    style : {
      fontSize: '16px',
			fontWeight: 400,
      fontFamily : 'Segoe UI',
			// color: theme.text.primary,
			// backgroundColor: theme.background.default,
			minHeight: '48px',
			'&:not(:last-of-type)': {
				borderBottomStyle: 'solid',
				borderBottomWidth: '1px',
				borderBottomColor: '#8A8A8A'
			},
    }
  },
  pagination :{
    style: {
			fontSize: '16px',
			minHeight: '56px',
      fontFamily : 'Segoe UI',
			borderTopStyle: 'solid',
			borderTopWidth: '1px',
			borderTopColor: '#8A8A8A',
      color : 'black'
		},
  }
}
const conditionalRowStyles = [
			{
				when: row => row.Status === "Inactive",
				style: {
					backgroundColor: '#F1F1F1'
				}
			}]

const Desktop = () => {
  let srNo = 0;
  const navigate = useNavigate();
  const [originalData, setoriginalData] = useState([])
  const [dataKey, setDataKey] = useState(originalData)
  const [subStart, setsubStart] = useState("")
  const [subEnd, setsubEnd] = useState("")
  const [dateError, setdateError] = useState(false)
  const [isFilActive, setFilterActive] = useState(false)
  
  const handleChange = (e) =>{ 
      console.log(e._id)
      navigate("/UserManagement/"+e._id)
  }

  const handleClick = async() =>{

    const demoData = []
    try {
        const userSnapshot = await getDocs(collection(firestore, "Users"));

        const userPromises = userSnapshot.docs.map(async (userDoc) => {
            const buyPlansCollection = collection(firestore, "Users", userDoc.id, "Buy Plans");
            const buyPlansSnapshot = await getDocs(buyPlansCollection);
            if(buyPlansSnapshot.empty){
              demoData.push({
                SrNo : ++srNo,
                _id: userDoc.id,
                Name: userDoc.data().fullName,
                EmailId : userDoc.data().email,
                Status: "--",
                Plan: "--",
                SubStart: "--",
                SubEnd: "--",
                Interest: "--"
              })
            }
            else{
              buyPlansSnapshot.forEach((buyPlanDoc) => {
              
                const userData = userDoc.data();
                const buyPlanData = buyPlanDoc.data();

                demoData.push({
                    SrNo: ++srNo,
                    _id: userDoc.id,
                    Name: userData.fullName,
                    EmailId: userData.email,
                    Status: buyPlanData.Plan.active ? "Active" : "Inactive",
                    Plan: buyPlanData.Plan.planRs,
                    SubStart: buyPlanData.Plan.startDate.split('T')[0],
                    SubEnd: buyPlanData.Plan.endDate.split('T')[0],
                    Interest: buyPlanData.Plan.interestCount
                });
            });
            }
        });

        await Promise.all(userPromises);
        
        // or do whatever you want with demoData here
    } catch (error) {
        console.error("Error fetching data:", error);
        alert("Error Fetching Data from Firebase.Please try to refresh");
    }

    setoriginalData(demoData)
    setDataKey(demoData)
  }

  const handleSearch = (e) =>{
      const filterData = originalData.filter((dt)=>dt.Name.toLowerCase().includes(e.target.value.toLowerCase()) || dt.EmailId.toLowerCase().includes(e.target.value.toLowerCase()))
      setDataKey(filterData)
  }

  useEffect (()=>{
    localStorage.setItem('selectedNavIndex', '0');
    onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/auth.user
          const uid = user.uid;
          console.log(user)
          localStorage.setItem('selectedNavIndex', '0');
          handleClick()
          // ...
        } else {
          // User is signed out
          // ...
          console.log("no user")
          navigate("/")
        }
      });
  },[])

  const handleClickDateStart = (e) =>{
    setsubStart(e.target.value)
  }

  const handleClickDateEnd = (e) =>{
    setsubEnd(()=>e.target.value)
  }

  const handelFilter  = () =>{
      console.log("i am in")
  if (isFilActive) {
      setsubStart("")
      setsubEnd("")
      setFilterActive(false)
      setDataKey(originalData)
    }
    else if(subStart !== "" && subEnd !== ""){
      console.log("First");

    // Ensure subStart and subEnd are dayjs objects
    let subSt = dayjs(subStart, 'YYYY-MM-DD');
    let subEn = dayjs(subEnd, 'YYYY-MM-DD');
    if (subSt.isAfter(subEn) || subSt.isSame(subEn)) {
        setdateError(true);
        return;
      } else {
        setdateError(false);
      }
    // Filter dataKey using dayjs isBetween with consistent formats
    setDataKey(dataKey.filter((dt) => 
      dayjs(dt.SubStart).isBetween(subSt, subEn, null, '[]')
    ));

    setFilterActive(true);
    }
    else if(subStart === ""){
      alert("Please select the start date first")
    }
  }

  return (
      <div style={{"margin":"auto"}} className="pr-5 pt-1 pl-5">
        <NavBar />
        <div className="pr-4 pl-4">
          <div className="flex justify-between mt-10 mb-10">
            <h2 className="text-2xl font-semibold">
                User Management ({dataKey.length})
            </h2>
              <button onClick={handleClick} className="w-52 h-12 bg-[#4F72C5] cursor-pointer text-base tracking-wider text-white mt-2 border-none rounded-2xl">Create User</button>
          </div>
          <div className="flex justify-between mt-5 mb-5">
            <input onInput={handleSearch} type="Text"  placeholder="Search User Name or Email" className="pl-5 w-56 h-10 border-solid border-2 border-[#9E9B9B] text-black rounded-lg"/>
            <div className="flex relative gap-x-3 pr-2">
             {/* reduce date input width // add filter button */}
              <input onChange={handleClickDateStart} type="Date" value={subStart} className="w-40 h-10 border-solid border-2 border-[#9E9B9B] bg-white rounded-lg pl-3 pr-1" />
              <span className="mt-3">to</span>
              <input onChange={handleClickDateEnd} type="Date" value={subEnd} placeholder="dd-mm-yy"  className="w-40 h-10 border-solid border-2 border-[#9E9B9B] rounded-lg pl-3 pr-1" />
              <button style={{"fontFamily":"Segoe UI"}} onClick={handelFilter} className={(isFilActive)?clearfilterCss:filterCss}>{(isFilActive)?"Clear":"Filter"}</button>
              {(dateError)&&<p className="absolute text-rose-600 top-8 left-1">start date must be less than end date</p>}
            </div>
          </div>
          <div className="mt-10">
            <DataTable 
              columns={columns}
              data={dataKey}
              onRowClicked={handleChange}
              customStyles={customStyles}
              conditionalRowStyles={conditionalRowStyles}
              pagination
            />
          </div>
        </div>
      </div>
    );
};

export default Desktop;
