import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {getAuth, signInWithEmailAndPassword,onAuthStateChanged} from "firebase/auth"
import {app} from "../firebase"
import logo from "../images/red-logo.svg"
/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
const auth = getAuth(app)
export default function Login() {

    const [inputsLogin, setInputsLogin] = useState({
        email : "",
        password : ""
    });

    const navigate = useNavigate()
    
    // const [pass, setPass] = useState(true)
    const [errorMsg , seterrorMsg] = useState(false);
    const [validEmail, setValidEmail] = useState(false)
    const [isUserValid, setisUserValid] = useState(false);

    const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputsLogin(values => ({...values, [name]: value}))
    }

    const handleClick = () =>{

        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(inputsLogin.email === "" || inputsLogin.password === ""){
            setValidEmail(false)
            seterrorMsg(true)
        }
        else if(!regex.test(inputsLogin.email)){
            seterrorMsg(false)
            setisUserValid(false)
            setValidEmail(true);
        }
        else{
            signInWithEmailAndPassword(auth, inputsLogin.email, inputsLogin.password)
            .then((userCredential) => {
              // Signed up 
              const user = userCredential.user;
              if(user){
                localStorage.setItem('selectedNavIndex', '0');
                navigate("/Dashboard")
                console.log(user)
              }
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              // ..
              console.log("ErroCode :",errorCode)
              console.log("errorMessage :",errorMessage)
              setisUserValid(true)
            });
                  }
    }

    useEffect(()=>{
        onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/auth.user
          const uid = user.uid;
          console.log(user)
          navigate("/Dashboard")
          // ...
        }
      });
    },[])

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-white">
        <body class="h-full">
        ```
      */}
      <div style={{"margin":"auto","marginTop":"100px","border":"solid 1px","borderColor":"#FF8080","borderRadius":"10px"}} className="w-96 flex min-h-full flex-1 flex-col justify-center px-6 pt-6 pr-10 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-10 w-auto"
            src={logo}
            alt="Your Company"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="flex flex-col gap-y-10 w-96">
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2 relative">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  onChange={(e)=>{seterrorMsg(false),handleChange(e)}}
                  required
                  className="block w-96 h-7 pl-4 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-[#FF8080] placeholder:text-gray-400 sm:text-sm sm:leading-6"
                />
                {(errorMsg) && <p className="text-rose-500 text-base absolute top-6">Email is required</p>}
                {(validEmail) && <p className="text-rose-500 absolute top-6">Enter Valid Email</p>}
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>
                <div className="text-sm">
                  <a className="font-semibold text-[black] hover:text-[black]">
                    Forgot password?
                  </a>
                </div>
              </div>
              <div className="mt-2 relative w-96">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  onChange={(e)=>{seterrorMsg(false),handleChange(e)}}
                  required
                  className="block w-96 h-7 pl-4 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-[#FF8080] placeholder:text-gray-400 sm:text-sm sm:leading-6"
                />
                {(errorMsg)&& <p className="text-rose-500 absolute top-6">Password is required</p>}
              </div>
            </div>

            <div className="relative w-full">
              <button
                onClick={handleClick}
                style={{"width":"402px",'height':"40px"}}
                className="flex block justify-center rounded-md bg-[#E30707] px-3 pt-3 py-1.5 mb-10 text-sm font-semibold text-white shadow-sm hover:bg-[#EB4C4C]"
              >
                Sign in
              </button>
              {(isUserValid)&&<p className="text-rose-500 absolute top-6">Invalid user</p>}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
