import React,{useState,useEffect} from 'react'
import NavBar from "../components/NavBar";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import {getFirestore, doc,getDoc,collection, getDocs,onSnapshot,where,orderBy,query} from "firebase/firestore"
import { app } from '../firebase';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {getAuth, onAuthStateChanged} from "firebase/auth"
dayjs.extend(utc);
dayjs.extend(timezone);
var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)
dayjs.extend(customParseFormat);
const firestore = getFirestore(app)
const auth = getAuth(app)
const clearfilterCss = "w-40 h-11 bg-transparent cursor-pointer text-base tracking-wider text-[#32497F] border-solid border-2 border-[#32497F] rounded-lg";
const filterCss = "w-40 h-11 bg-[#4F72C5] cursor-pointer text-base tracking-wider text-white border-solid border-2 border-[#4F72C5] rounded-lg"
const columns = [
  {
    name : 'Sr NO',
    selector : row => row.SrNo,
    left : true
  },
  {
    name : 'Interest',
    selector : row=>row.interestName,
    sortable :true,
    left : true,
    conditionalCellStyles: [
			{
				when: row => row.interestName,
				style: {
					color: '#5F8EFC',
					'&:hover': {
						cursor: 'pointer',
					},
				},
			},
    ]
  },
  {
    name : 'Last Notification Sent',
    selector : row=>row.latestNotificationTime,
    sortable :true,
    left : true
  },
  {
    name : 'Total Users',
    selector : row=>row.notificationsCount,
    sortable :true,
    left : true
  },
  {
    name : 'Total Notification Sent',
    selector : row=>row.totalCount,
    sortable :true,
    left : true
  }
]

const customStyles = {
  headRow :{
    style: {
			// backgroundColor: theme.background.default,
      fontSize : '16px',
      fontWeight : 'bold',
      fontFamily : 'Segoe UI',
			minHeight: '52px',
			borderBottomWidth: '1px',
			borderBottomColor: '#8A8A8A',
			borderBottomStyle: 'solid',
		},

  },
  rows : {
    style : {
      fontSize: '16px',
			fontWeight: 400,
      fontFamily : 'Segoe UI',
			// color: theme.text.primary,
			// backgroundColor: theme.background.default,
			minHeight: '48px',
			'&:not(:last-of-type)': {
				borderBottomStyle: 'solid',
				borderBottomWidth: '1px',
				borderBottomColor: '#8A8A8A'
			},
    }
  },
  pagination :{
    style: {
			fontSize: '16px',
			minHeight: '56px',
			borderTopStyle: 'solid',
			borderTopWidth: '1px',
			borderTopColor: '#8A8A8A',
      color : 'black'
		},
  }
}
function Notification() {
    let srNo = 0;
    const navigate = useNavigate();
    const [originalData, setoriginalData] = useState([])
    const [intData, setIntData] = useState([]);
    const [dataKey, setdataKey] = useState(originalData);
    const [subStart, setsubStart] = useState("")
    const [subEnd, setsubEnd] = useState("")
    const [dateError, setdateError] = useState(false)
    const [isFilActive, setFilterActive] = useState(false)

    const handleGetData = async() =>{
        const ref = collection(firestore, "interests");

            const unsubscribe = onSnapshot(ref, (snapshot) => {
                const demoData = ["All"];
                snapshot.forEach((dc) => {
                    if (dc.data().active) {
                      demoData.push(
                        dc.data().interestName
                    );
                    }
                });
                
          handleNotificationCount(demoData)
                setIntData([...demoData]); // Update state with new data
            });

    // Return unsubscribe function to clean up the listener when component unmounts
    return () => unsubscribe();
    }

 // Array to store notification counts
const handleSearch = (e) =>{
    setdataKey(originalData.filter((dt)=>dt.interestName.toLowerCase().includes(e.target.value.toLowerCase())))
  }
// Function to fetch notification count for a specific interest and push the data to array
const fetchNotificationCount = async (interest) => {
    try {
        const querySnapshot = query(collection(firestore,"ExamNotification"),
            where("examName", "==", `${interest}`),
            orderBy("postDate", "desc"),
    )

        const totalCount = await getDocs(querySnapshot)
        let latestNotificationTime = null;

        if (!totalCount.empty) {
            const latestNotification = totalCount.docs[0].data();
            latestNotificationTime = dayjs(latestNotification.postDate.toDate()).format("DD/MM/YYYY hh:mm A");
            var notificationReceivedCount = latestNotification.Counter;
        }else{
          return;
        }

        const interestData = {
            SrNo : ++srNo,
            interestName: (interest === 'All')?"General for all":interest,
            latestNotificationTime: latestNotificationTime,
            totalCount: totalCount.size,
            notificationsCount : notificationReceivedCount
        };
        //notificationCounts.push(interestData); // Push data to array
        
        console.log("Notification count updated for interest:", interest, interestData);
        return interestData;
    } catch (error) {
        console.log("Error getting notifications for interest:", interest, error);
    }

};

const handleClickDateEnd= (e) =>{
  //const value = dayjs(e.target.value).format('DD/MM/YYYY')
  setsubEnd(e.target.value)
  //console.log(value)
}

const handleClickDateStart =(e) =>{
  //const value = dayjs(e.target.value).format('DD/MM/YYYY')
  setsubStart(e.target.value)
  //console.log(value)
}

const handleFilter = () =>{
  console.log("i am in")
  if (isFilActive) {
      setsubStart("")
      setsubEnd("")
      setFilterActive(false)
      setdataKey(originalData)
    }
    else if(subStart !== "" && subEnd !== ""){
      console.log("First");

    // Ensure subStart and subEnd are dayjs objects
    let subSt = dayjs(subStart, 'YYYY-MM-DD');
    let subEn = dayjs(subEnd, 'YYYY-MM-DD');
    if (subSt.isAfter(subEn) || subSt.isSame(subEn)) {
        setdateError(true);
        return;
      } else {
        setdateError(false);
      }
    // Filter dataKey using dayjs isBetween with consistent formats
    setdataKey(dataKey.filter((dt) => 
      dayjs(dt.latestNotificationTime, 'DD/MM/YYYY hh:mm A').isBetween(subSt, subEn, null, '[]')
    ));

    setFilterActive(true);
    }
    else if(subStart === ""){
      alert("Please select the start date first")
    }
}

    const handleNotificationCount = async(interestData) =>{
      const notificationCounts = [];
      console.log(interestData)
      const interestPromise = interestData.map(async(interest)=>{
        const data = await fetchNotificationCount(interest);
        (data!==undefined)?notificationCounts.push(data):null
      })
      await Promise.all(interestPromise)
      setoriginalData(notificationCounts)
      setdataKey(notificationCounts)
    }

    useEffect(()=>{
      onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/auth.user
          const uid = user.uid;
          console.log(user)
          handleGetData()
          // ...
        } else {
          // User is signed out
          // ...
          console.log("no user")
          navigate("/")
        }
      });
    },[])
  return (
    <div style={{"margin":"auto"}} className="pr-5 pt-1 pl-5">
        <NavBar />
        <div className="pr-4 pl-4">
          <div className="flex justify-between mt-10 mb-10">
            <h2 className="text-2xl font-semibold">
                Notifications ({dataKey.length})
            </h2>
              <button onClick={()=>navigate("/Notification/CreateNotification")} className="w-52 h-12 bg-[#4F72C5] cursor-pointer text-base tracking-wider text-white mt-2 border-none rounded-2xl">New Notification</button>
          </div>
          <div className="flex justify-between mt-5 mb-5">
            <input type="Text" onChange={handleSearch}  placeholder="Search Notification" className="pl-5 w-56 h-10 border-solid border-2 border-[#9E9B9B] text-black rounded-xl"/>
            <div className="flex relative gap-x-3 pr-2">
              <input onChange={handleClickDateStart} type="Date" value={subStart} className="w-40 h-10 border-solid border-2 border-[#9E9B9B] bg-white rounded-lg pl-3 pr-1" />
              <span className="mt-3">to</span>
              <input onChange={handleClickDateEnd} type="Date" value={subEnd} placeholder="dd-mm-yy"  className="w-40 h-10 border-solid border-2 border-[#9E9B9B] rounded-lg pl-3 pr-1" />
              <button style={{"fontFamily":"Segoe UI"}} onClick={handleFilter} className={(isFilActive)?clearfilterCss:filterCss}>{(isFilActive)?"Clear":"Filter"}</button>
              {(dateError)&&<p className="text-rose-600 absolute top-8 left-1">Please validate the dates.</p>}
            </div>
          </div>
          <div className='mt-10'>
            <DataTable 
              columns={columns}
              data={dataKey}
              customStyles={customStyles}
              pagination
            />
          </div>
        </div>
      </div>
  )
}

export default Notification