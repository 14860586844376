import * as React from 'react';
import PropTypes from 'prop-types';
import { Select as BaseSelect } from '@mui/base/Select';
import { Option as BaseOption } from '@mui/base/Option';
import { useTheme } from '@mui/system';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import clsx from 'clsx';
import { PopupContext } from '@mui/base/Unstable_Popup';
import { CssTransition } from '@mui/base/Transitions';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function useIsDarkMode() {
  const theme = useTheme();
  return theme.palette.mode === '';
}

export default function UnstyledSelectIntroduction() {
  const isDarkMode = useIsDarkMode();

  return (
    <div className={isDarkMode ? 'dark' : 'light'}>
      <Select defaultValue={10}>
        <Option value={10}>Documentation</Option>
        <Option value={20}>Components</Option>
        <Option value={30}>Features</Option>
      </Select>
    </div>
  );
}

const getOptionColorClasses = ({ selected, highlighted, disabled }) => {
  let classes = '';
  if (disabled) {
    classes += 'text-slate-400 ';
  } else {
    if (selected) {
      classes += ' bg-[#E4ECFF] text-purple-950';
    } else if (highlighted) {
      classes += ' bg-[#E4ECFF] text-slate-900 ';
    }
    classes += 'hover:bg-[#E4ECFF] hover:text-slate-900';
    classes += ' focus-visible:outline focus-visible:outline-2 focus-visible:outline-purple-400';
  }
  return classes;
};

const Option = React.forwardRef((props, ref) => {
  return (
    <BaseOption
      ref={ref}
      {...props}
      slotProps={{
        root: ({ selected, highlighted, disabled }) => ({
          className: `list-none p-2 mb-2 rounded-lg cursor-default last-of-type:border-b-0 ${getOptionColorClasses({
            selected,
            highlighted,
            disabled,
          })}`,
        }),
      }}
    />
  );
});

const Button = React.forwardRef(function Button(props, ref) {
  const { ownerState, ...other } = props;
  return (
    <button type="button" {...other} ref={ref}>
      {other.children}
      <UnfoldMoreRoundedIcon />
    </button>
  );
});

Button.propTypes = {
  children: PropTypes.node,
  ownerState: PropTypes.object.isRequired,
};

const AnimatedListbox = React.forwardRef(function AnimatedListbox(props, ref) {
  const { ownerState, ...other } = props;
  const popupContext = React.useContext(PopupContext);

  if (popupContext == null) {
    throw new Error(
      'The `AnimatedListbox` component cannot be rendered outside a `Popup` component',
    );
  }

  const verticalPlacement = popupContext.placement.split('-')[0];

  return (
    <CssTransition
      className={`placement-${verticalPlacement}`}
      enterClassName="open"
      exitClassName="closed"
    >
      <ul {...other} ref={ref} />
    </CssTransition>
  );
});

AnimatedListbox.propTypes = {
  ownerState: PropTypes.object.isRequired,
};

const resolveSlotProps = (fn, args) => (typeof fn === 'function' ? fn(args) : fn);

const Select = React.forwardRef(function CustomSelect(props, ref) {
  const isDarkMode = useIsDarkMode();

  return (
    <BaseSelect
      ref={ref}
      {...props}
      slots={{
        root: Button,
        listbox: AnimatedListbox,
        ...props.slots,
      }}
      className={clsx('CustomSelect', props.className)}
      slotProps={{
        ...props.slotProps,
        root: (ownerState) => {
          const resolvedSlotProps = resolveSlotProps(props.slotProps?.root, ownerState);
          return {
            ...resolvedSlotProps,
            className: clsx(
              `relative text-sm box-border w-80 px-3 py-2 rounded-xl text-left bg-white border border-solid border-2 border-[#9E9B9B] text-slate-900 transition-all outline-0 ${
                ownerState.focusVisible
                  ? 'focus-visible:ring-4 ring-purple-500/30 focus-visible:border-purple-500'
                  : ''
              } [&>svg]:text-base [&>svg]:absolute [&>svg]:h-full [&>svg]:top-0 [&>svg]:right-2.5`,
              resolvedSlotProps?.className,
            ),
          };
        },
        listbox: (ownerState) => {
          const resolvedSlotProps = resolveSlotProps(props.slotProps?.listbox, ownerState);
          return {
            ...resolvedSlotProps,
            className: clsx(
              `text-sm p-1.5 my-3 w-80 rounded-xl overflow-auto outline-0 bg-white border border-solid border-slate-200 text-slate-900 shadow shadow-slate-200 [.open_&]:opacity-100 [.open_&]:scale-100 transition-[opacity,transform] [.closed_&]:opacity-0 [.closed_&]:scale-90 [.placement-top_&]:origin-bottom [.placement-bottom_&]:origin-top`,
              resolvedSlotProps?.className,
            ),
          };
        },
        popup: (ownerState) => {
          const resolvedSlotProps = resolveSlotProps(props.slotProps?.popup, ownerState);
          return {
            ...resolvedSlotProps,
            className: clsx(`${isDarkMode ? 'dark' : 'light'} z-10`, resolvedSlotProps?.className),
          };
        },
      }}
    />
  );
});

Select.propTypes = {
  className: PropTypes.string,
  slotProps: PropTypes.shape({
    listbox: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    popup: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    root: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  }),
  slots: PropTypes.shape({
    listbox: PropTypes.elementType,
    popup: PropTypes.elementType,
    root: PropTypes.elementType,
  }),
};

export { Select, Option };
