import React from 'react'
import NavBar from './NavBar'
import { useNavigate, useLocation } from 'react-router-dom'
import {getFirestore, doc,getDoc,collection,addDoc, getDocs, updateDoc , onSnapshot,deleteDoc, Timestamp} from "firebase/firestore"
import { app } from "../firebase";
import { useEffect, useState } from "react";
import { Fragment, useRef } from 'react'
import dayjs from 'dayjs';
import ReactQuill from 'react-quill';
import 'quill/dist/quill.snow.css'
import { Dialog, Transition } from '@headlessui/react'
import crossIocon from "../images/icons8-multiply-24.png"
import {getAuth, onAuthStateChanged} from "firebase/auth"
const auth = getAuth(app)
const firestore = getFirestore(app)
const regex = /^[a-zA-Z0-9\s]+$/;

export default function EditPlan() {

    const loc = useLocation();
    const planId = loc.pathname.split("/")[3]
    const [planData, setPlanData] = useState({})
    const [open, setOpen] = useState(false)
    const cancelButtonRef = useRef(null)
    const navigate = useNavigate()
    const [adminName,setadminName] = useState("");
    const [comment,setComment] = useState(null)
    const [commentError, setcommentError] = useState(false)
    const [errors, setErrors] = useState({});
    const [planActive, setPlanActive] = useState(true);
    const [planExistsError, setplanExistsError] = useState(false);
    const [invalidInputError, setinvalidInputError] = useState(false);

    const getPlanData = () =>{
        const docRef = doc(firestore,`Plans/${planId}`)

            // Fetch data from the document
            getDoc(docRef)
            .then((dc)=>{
                console.log(dc.data())
                setPlanData(dc.data());
            })  
            .catch((error)=>{
                console.log(error)
            })
    }

    const modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] }
      ],
      [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
    ]
  };

const formats = [
    "header", "height", "bold", "italic",
    "underline", "strike", "blockquote",
    "list", "color", "bullet", "indent",
    "link", "align", "size",
  ];

    const handleClick = () =>{
    const validationErrors = validateInputs(planData);
        //console.log(validationErrors)
        //console.log(inputs)
        if (Object.keys(validationErrors).length === 0) {
            if(!regex.test(planData.planName)) {
                setErrors({})
                setplanExistsError(false)
                setinvalidInputError(true);
                return;
            }
            else {
              console.log(planData)
                if(comment !== null){
                  handleComment()
                }
                const planDocRef = doc(firestore, `Plans/${planId}`);
                updateDoc(planDocRef, planData)
                .then(() => {
                console.log('plan document updated successfully.');
                })
                .then(
                  navigate("/planManagement")
                )
                .catch((error) => {
                console.error('Error updating plan document:', error);
            })
            }
        } else {
            alert('Please fill in all fields.')
            setplanExistsError(false)
            setErrors(validationErrors);
        }
}

    const validateInputs = (values) => {
        const errors = {};

        if (!values.planName.trim()) {
            errors.planName = "Plan Name is required";
        }
        if (!values.caption.trim()) {
            errors.caption = "Description is required";
        }
        if (!values.rs) {
            errors.rs = "Plan Price is required";
        }
        if (!values.days) {
            errors.days = "Validity is required";
        }
        if (!values.interest) {
            errors.interest = "Selectable Interests is required";
        }

        return errors;
    }

    const handleEditorText = (content) =>{
        console.log(content)
        setPlanData(values => ({...values,caption:content}))
    }

    const handleComment = () =>{

      if(comment === null){
        setcommentError(true);
        return;
      }
      
      const addCom = {
        date : Timestamp.now(),
        commentData : comment,
        adminName : adminName
      }

      const commentsColRef = collection(firestore,"Plans/"+planId+"/Comments")
      addDoc(commentsColRef,addCom)
      .then((docRef) => {
        console.log('Comment added with ID: ', docRef.id);
        setComment(null)
      })
      .catch((error) => {
        console.error('Error adding comment: ', error);
      });
    }

//     const delPlan = async() =>{
//         try {
//     // Create a reference to the document representing the user
//     const planRef = doc(firestore, 'Plans', planId);

//     // Delete the document
//     await deleteDoc(planRef);

//     console.log('User deleted successfully.');
//   } catch (error) {
//     console.error('Error deleting user:', error);
//   }
//     }

    const handleChange = (e) =>{
        const name = e.target.name
        let value = e.target.value
        if (name === 'interest'){
            value = Number(e.target.value)
        }
        else if(name === 'planName'){
            value = e.target.value.toUpperCase();
        }
        else if(name === 'days'){
            value = Number(e.target.value)
        }
        else if(name === 'rs'){
            value = Number(e.target.value)
        }
        setPlanData(values => (console.log(values),{...values, [name]: value}))
    }

    useEffect(()=>{
        onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/auth.user
          const uid = user.uid;
          console.log(user)
          getPlanData()
          setadminName(user.email)
          // ...
        } else {
          // User is signed out
          // ...
          console.log("no user")
          navigate("/")
        }
      });
    },[])

  return (
    <div style={{"margin":"auto"}} className="pr-5 pt-1 pl-5">
        <NavBar />
        <div className="flex justify-between pl-10 pr-10 mb-5 mt-5">
            <h2 className="text-2xl font-semibold">
                Plan Management &gt; Edit Plan &gt; {planData.planName}
            </h2>
            <div className='flex gap-x-6'>
                <button onClick={()=>navigate("/PlanManagement")} className="w-40 h-12 bg-transparent border-2 mt-2 text-[#32497F] tracking-wider text-base tracking-wider rounded-2xl border-[#32497F] border-solid">Cancel</button>
                <button onClick={handleClick} className="w-40 text-white h-12 bg-[#4F72C5] text-base border-none mt-2 tracking-wider rounded-2xl">Save</button>
            </div>
        </div>
        <div className="ml-56 h-full flex pl-20 h-80">
            <div className="flex flex-col w-3/5 pt-4 h-full gap-y-10 border-r-2 border-dashed border-[#707070]">
                <div className='relative flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}}>Plan Name</label>
                    <input className={(errors.planName || invalidInputError)?"w-96 h-10 rounded-xl border-2 border-rose-500 border-solid pl-4":"w-96 h-10 rounded-xl border-2 border-[#9E9B9B] border-solid pl-4"} onChange={(e)=>{
                        setinvalidInputError(false)
                        handleChange(e)}} name='planName' value={planData.planName}  type='text'/>
                    {errors.planName && <div className='absolute top-16'><p className="text-red-500">{errors.planName}</p></div>}
                    {(invalidInputError) && <div className='absolute top-16'><p className="text-red-500">Invalid Input</p></div>}
                </div>
                <div className='relative flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}}>Description</label>
                    <div className={(errors.caption)?'rounded-xl border-2 border-rose-500 border-solid':'rounded-xl border-2 border-[#9E9B9B] border-solid'} style={{"width":"650px", "height":"250px", "overflow":"hidden"}}>
                        <ReactQuill
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        value={planData.caption}
                        onChange={handleEditorText}
                        placeholder="write your content ...."
                        style={{ height: "209px", width:"650px"}}
                        >
                        </ReactQuill>
                    </div>
                    {errors.caption && <div className='absolute top-64 mt-4'><p className="text-red-500">{errors.caption}</p></div>}
                </div>
                <div className='flex w-48 flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}}>Status</label>
                    <label class="inline-flex items-center cursor-pointer">
                    <input type="checkbox" value="" onChange={()=>setOpen(true)} class="sr-only peer" checked={(planData.active)?true:false}/>
                    <span class="ms-3 text-sm font-normal text-gray-900 dark:text-gray-300 mr-3">Inactive</span>
                    <div class="relative w-11 h-6 bg-[#b6b8b7] rounded-full peer peer-focus:ring-4 peer-focus:ring-white dark:peer-focus:ring-white dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#818181]"></div>
                    <span class="ms-3 text-sm font-normal text-gray-900 dark:text-gray-300 ml-3">Active</span>
                    </label>
                </div>
            </div>
            <div className='pl-12 w-96 pt-4 flex flex-col gap-y-10'>
                <div className='flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}}>Plan Price</label>
                    <div className='relative'>
                        <input onChange={handleChange} className={(errors.rs)?"w-20 h-10 rounded-xl border-2 mr-3 border-rose-500 border-solid pl-4":"w-20 h-10 rounded-xl border-2 mr-3 border-[#9E9B9B] border-solid pl-4"} name='rs' value={planData.rs} type='number'/><span>Rs</span>
                        {errors.rs && <div className='absolute top-8'><p className="text-red-500">{errors.rs}</p></div>}
                    </div>
                </div>
                <div className='flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}}>Validity</label>
                    <div className='relative'>
                        <input onChange={handleChange} className={(errors.days)?"w-20 h-10 rounded-xl border-2 mr-3 border-rose-500 border-solid pl-4":"w-20 h-10 rounded-xl border-2 mr-3 border-[#9E9B9B] border-solid pl-4"} value={planData.days} name='days' type='number'/><span>Days</span>
                        {errors.days && <div className='absolute top-8'><p className="text-red-500">{errors.days}</p></div>}
                    </div>
                </div>
                <div className='flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}}>Selectable Interests</label>
                    <div className='relative'>
                        <input onChange={handleChange} className={(errors.interest)?"w-20 h-10 rounded-xl border-2 mr-3 border-rose-500 border-solid pl-4":"w-20 h-10 rounded-xl border-2 mr-3 border-[#9E9B9B] border-solid pl-4"} value={planData.interest} name='interest' type='number'/>
                        {errors.interest && <div className='absolute top-8'><p className="text-red-500">{errors.interest}</p></div>}
                    </div>
                </div>
            </div>
        </div>
        <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className = "bg-gray"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-100 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-100 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
            <Dialog.Panel style={{"width":"655px","height":"354px","fontFamily":"Segoe UI"}} className="absolute top-64 transform border border-[#707070] overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all  sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white pl-10 px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div> */}
                    <div className="mt-3 text-left sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-2xl font-semibold leading-6 text-gray-900">
                        Change Plan Status
                      </Dialog.Title>
                      <img onClick={() => setOpen(false)} src={crossIocon} alt='cross' className='absolute right-12 top-10'/>
                      <div className="mt-2">
                        <p className="text-base text-gray-500">
                          Are you sure, you want make {planData.planName} {(planData.active)?<span>Inactive</span>:<span>Active</span>}?
                        </p>
                      </div>
                      <div className='relative flex flex-col pr-9'>
                        {/* add padding top-left */}
                        <label className='text-base mb-2'>Please provide comment below</label>
                        <textarea onChange={(e)=>{
                        setcommentError(false)
                        setComment(e.target.value)}} id="w3review" name="w3review" rows="4" cols="50" className='w-78 h-24 rounded-lg border pt-3 pl-3'></textarea>
                        {(commentError)&&<div className='absolute top-28 mt-3'><p className="text-red-500">Please provide comment.</p></div>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 flex justify-center gap-x-5">
                  <button
                    type="button"
                    style={{
                      "borderColor" : "#32497F",
                      "borderWidth" :"2px"
                    }}
                    className="justify-center w-36 h-12 border-solid rounded-2xl bg-white px-3 py-2 text-lg text-[#32497F] sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="justify-center w-36 h-12 rounded-2xl border-solid bg-white px-3 py-2 text-lg text-[#FF0000] border-2 sm:mt-0 sm:w-auto border-[#FF0000]"
                    onClick={()=>{
                      if(comment === ""){
                          setcommentError(true);
                          return;
                        }
                      setPlanData(prevData =>({...prevData,active:(planData.active)?false:true}))
                      setOpen(false)
                    }}
                    ref={cancelButtonRef}
                  >
                    Yes
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </div>
  )
}
