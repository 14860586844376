import React, { useEffect } from 'react'
import NavBar from './NavBar'
import uploadIcon from "../images/upload.png"
import { useState} from 'react'
import {getFirestore, doc, getDoc,collection, getDocs, addDoc, updateDoc} from "firebase/firestore"
import {getStorage, ref, uploadBytesResumable, getDownloadURL, listAll, deleteObject} from "firebase/storage"
import CircularProgressWithLabel from '@mui/material/CircularProgress';
import { app } from "../firebase";
import { useNavigate, useLocation } from 'react-router-dom'
import trashCan from "../images/trash-can.png"
import {getAuth, onAuthStateChanged} from "firebase/auth"
const auth = getAuth(app)
const firestore = getFirestore(app)
const storage = getStorage(app)
let imgArr = [];


export default function UpdateInterestImage() {

    const [name , setName]  = useState("Choose Image")
    const [data,setData] = useState({})
    const [imageArr, setImageArr] = useState(imgArr)
    const [error, setError] = useState(false);
    const [typeError,settypeError] = useState(false)
    const loc = useLocation();
    const {storagePath,navigationPath,moduleName} = loc.state
    const navigate = useNavigate()
    const onDataAdd = () =>{
        console.log(data.type)
        if(name === "Choose Image"){
            setError(true);
            return;
        }
        if(data.type === "image/jpg" || data.type === 'image/png' || data.type === 'image/jpeg'){
            imgArr.push(
            {
                name : name,
                data : data
            }
            
        )
        setImageArr([...imgArr])
        setName("Choose Image")
        setData({})
        }else{
            settypeError(true);
            return;
        }
    }
    
   const handleUpload = async () => {
    if (imgArr.length === 0) {
        alert("please add data.");
        return;
    }
    if (error || typeError) {
        alert("clear all validations");
        return;
    }

    try {
        const uploadPromises = imageArr.map(async (data) => {
            const storageRef = ref(storage, `${storagePath}/${data.name}`);
            const uploadTask = uploadBytesResumable(storageRef, data.data);

            return new Promise((resolve, reject) => {
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('Upload is ' + progress + '% done');
                    },
                    (error) => {
                        console.log(error);
                        reject(error);
                    },
                    () => {
                        resolve();
                    }
                );
            });
        });

        await Promise.all(uploadPromises);

        navigate(`/${navigationPath}`);
    } catch (error) {
        console.error('Error during upload:', error);
        alert('Upload failed, please try again.');
    }
};



    const handleDelete = (index) =>{
        imgArr.splice(index,1);
        setImageArr([...imgArr]);
    }

    useEffect (()=>{
        onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/auth.user
          const uid = user.uid;
          console.log(user)
          imgArr = [];
          // ...
        } else {
          // User is signed out
          // ...
          console.log("no user")
          navigate("/")
        }
      });
    },[])
  return (
    <div style={{"margin":"auto"}} className="pr-5 pt-1 pl-5">
        <NavBar />
        <div className="flex justify-between pl-10 pr-10 mb-5 mt-5">
            <h2>
                {moduleName} &gt; Update Homepage Content and Images
            </h2>
            <div className='flex gap-x-6'>
                <button onClick={()=>navigate("/InterestManagement")} className="w-40 h-12 bg-transparent border-2 mt-2 text-base tracking-wider text-[#32497F] rounded-2xl border-solid border-[#32497F]">Cancel</button>
                <button onClick={handleUpload} className="w-40 text-white h-12 bg-[#4F72C5] text-base tracking-wider border-none mt-2 rounded-2xl">Save</button>
            </div>
        </div>
        <div>
            <div className='w-1/2 ml-64 flex flex-col gap-y-4'>
                {
                    (imageArr.length !== 0) && imageArr.map((ig,index)=>{
                        console.log(ig)
                        return( <div className='flex flex-col gap-y-4'>
                                    <label>Carousel Image {++index}</label>
                                    <div  className='relative flex gap-x-8'>
                                        <label style={{"border":"solid #9E9B9B","borderWidth":"2px","borderColor":"#9E9B9B"}} className='w-3/5 pl-5 pt-2 rounded-xl h-8 z-20'>{ig.name}</label>
                                        <span className='absolute right-72 top-2'><img className='w-6 h-6 z-10' src={uploadIcon}/></span>
                                        <input style={{"display":"none"}} type='text' readOnly/>
                                        <img src={trashCan} className='m-0 mt-2.5 cursor-pointer w-6 h-6' onClick={()=>handleDelete(--index)}/>
                                    </div>
                                </div>)
                    })
                }
                <div className='flex flex-col gap-y-4'>
                    <label>Upload Carousel Image</label>
                    <div  className='relative flex gap-x-8'>
                        <label for="imgOne" className={(error || typeError)?'w-3/5 pl-5 pt-2 border-[#c4c2c2] rounded-xl border-rose-500 border-solid border-2 h-8 z-20':'w-3/5 pl-5 pt-2 border-[#c4c2c2] rounded-xl border-solid border-2 h-8 z-20'}>{name}</label>
                        <span className='absolute right-72 top-2'><img className='w-6 h-6 z-10' src={uploadIcon}/></span>
                        <input onChange={(e)=>{
                            console.log(e.target.files[0])
                            setError(false)
                            settypeError(false)
                            setData(e.target.files[0])
                            setName(e.target.files[0].name)
                            }} id='imgOne' style={{"display":"none"}} name='imgOne' type='file' accept='accept="image/png, image/jpg, image/jpeg"'/>
                            <p className='m-0 mt-3 cursor-pointer underline' onClick={onDataAdd}>Add Image</p>
                    </div>
                    {(error)&&<label className='text-rose-500'>upload image</label>}
                    {(typeError)&&<label className='text-rose-500'>Allowed fomats : .jpg, .png, .jpeg</label>}
                </div>
            </div>
        </div>
    </div>
  )
}
