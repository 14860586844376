import React from 'react'
import NavBar from "../components/NavBar";
import {Select, Option} from "./customSelect"
import { useNavigate } from "react-router-dom";
import ReactQuill from 'react-quill';
import 'quill/dist/quill.snow.css'
import {getFirestore, doc,getDoc,collection,onSnapshot, getDocs,query,where,and,addDoc} from "firebase/firestore"
import { app } from '../firebase';
import { useEffect, useState } from "react";
import crossIocon from "../images/icons8-multiply-24.png"
import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import serviceCred from "../ezapp-6bbee-c4c7e72edb92.json"
import cred from "../client_secret_100998732635-hckn4s77n6ibm5h3et6qbfpqmqh1uqhg.apps.googleusercontent.com.json"
import { Timestamp } from 'firebase/firestore';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {getAuth, onAuthStateChanged} from "firebase/auth"
dayjs.extend(customParseFormat);
// var {google} = require("googleapis")
const firestore = getFirestore(app)
const auth = getAuth(app)
// const SCOPES = [""]

const modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] }
      ],
      [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
    ]
  };

const formats = [
    "header", "height", "bold", "italic",
    "underline", "strike", "blockquote",
    "list", "color", "bullet", "indent",
    "link", "image", "align", "size",
  ];

function CreateNotification() {

    
    const [intData, setIntData] = useState([])
    const [notificationSend, setNotification] = useState({
      examName : "",
      examTitle : "",
      fullDate:"",
      examURL :"",
      examInfo :""
    })
    const [accessToken, setAccessToken] = useState(null);
    const [deviceTokens, setdeviceTokens] = useState([]);
    const [open, setOpen] = useState(false)
    const cancelButtonRef = useRef(null)
    const navigate = useNavigate()
    const [errors, setErrors] = useState({});
    const [interestIsFree,setinterestIsFree] = useState(false)
    const [adminName,setadminName] = useState("");

    const handleChange = (e,intValue=null) =>{
        let name = e.target.name
        console.log(e)
        let value;
        if(intValue === null){
          value = e.target.value
        }else{
          name = "examName"
          value = intValue
        }
        
        setNotification(values => (console.log(values),{...values, [name]: value}))
        if(intValue !== null){
          handleUserNotification(value);
        }
    }

    const validateInputs = (values) => {
        const errors = {};

        if (!values.examName.trim()) {
            errors.examName = "Please select interest";
        }
        if (!values.examTitle.trim()) {
            errors.examTitle = "Notification Title is required";
        }
        if (!values.fullDate.trim()) {
            errors.fullDate = "Notification Date is required";
        }
        if (!values.examURL.trim()) {
            errors.examURL = "Notification URL is required";
        }
        if (!values.examInfo.trim()) {
            errors.examInfo = "Description is required";
        }

        return errors;
    }

    const handleEditorText = (content) =>{
        setNotification(values => ({...values,examInfo:content}))
    }

    const getIntData = async() =>{
        const ref = collection(firestore, "interests");

    // Subscribe to real-time changes
    const unsubscribe = onSnapshot(ref, (snapshot) => {
        const demoData = [];
        snapshot.forEach((dc) => {
            if(dc.data().active){
                demoData.push({
                _id: dc.id,
                Name: dc.data().interestName,
                SelByUsers: dc.data().counter,
                Status: dc.data().active ? "Active" : "Inactive",
                isFree : dc.data().isFree
            });
            }
        });
        setIntData(demoData); // Update state with new data
    });

    // Return unsubscribe function to clean up the listener when component unmounts
    return () => unsubscribe();
    }



    const getExam = async() =>{
        const exam = await getDocs(collection(firestore,'Exams'))
        exam.docs.map((examDoc)=>{
            console.log(examDoc.data())
        })
    }

    function htmlToPlainText(html) {
    var temporaryElement = document.createElement("div");
    temporaryElement.innerHTML = html;
    return temporaryElement.textContent || temporaryElement.innerText || "";
}

    const sendNotificationUpdate = async (token) => {
  const endpoint = 'https://fcm.googleapis.com/v1/projects/ezapp-673cd/messages:send';
  const notification = {
    message: {
      notification: {
        title: notificationSend.examTitle,
        body: htmlToPlainText(notificationSend.examInfo)
      },
      token: token, // Replace with the device token of the recipient
    }
  };

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}` ,
      },
      body: JSON.stringify(notification),
    });

    if (!response.ok) {
      throw new Error('Failed to send notification');
    }

    console.log('Notification sent successfully');
  } catch (error) {
    console.error('Error sending notification:', error);
  }
};

const addNotification = () =>{
        const index = findIndexByField(intData,"Name",notificationSend.examName)
        const updateNotification = {
          ...notificationSend,
          Counter : deviceTokens.length,
          isFree : (notificationSend.examName === "All"||intData[index].isFree)?true:false,
          postDate : Timestamp.now(),
          adminName:adminName
        }
        console.log(updateNotification)
        const ref = collection(firestore,"ExamNotification")
        addDoc(ref,updateNotification)
        .then((res)=>{
            console.log("Data uploaded succesfully :", res.id)
        })
        .then(
            navigate("/Notification")
        )
        .catch((err)=>{
            console.log(err)
        })
}


  const sendNotificationsToAll = () => {
    addNotification();
    deviceTokens.forEach(token => {
      sendNotificationUpdate(token);
    });
  };
  function findIndexByField(array, field, value) {
  for (let i = 0; i < array.length; i++) {
    if (array[i][field] === value) {
      return i; // Return index if found
    }
  }
  return -1; // Return -1 if not found
}
  const handleUserNotification = async(value) =>{
    const tmpDeviceToken = [];
    try {
      const userSnapshot = await getDocs(collection(firestore, "Users"));
      const index = findIndexByField(intData,"Name",value)
      console.log(intData[index])
    const notificationPromise = userSnapshot.docs.map(async(userDoc)=>{
      if (value === "All" || intData[index].isFree) {
        if (userDoc.data().fcmToken && userDoc.data().logIn) {
          tmpDeviceToken.push(userDoc.data().fcmToken)
        }
      } else {
        const buyPlansCollection = collection(firestore, "Users", userDoc.id, "Buy Plans");
        const q = query(buyPlansCollection, and(
        where("Plan.active","==",true),
        where("selectedInterests", "array-contains", value)));
          const querySnapshot = await getDocs(q);
          if(querySnapshot.empty){
            console.log("doc is empty")
          }else{
            if(userDoc.data().fcmToken && userDoc.data().logIn){
              tmpDeviceToken.push(userDoc.data().fcmToken)
            }
          }
      }
      
    })
      await Promise.all(notificationPromise);
      console.log(deviceTokens)
    } catch (error) {
      console.log(error)
    }
    setdeviceTokens(tmpDeviceToken)
  }


useEffect(()=>{
    onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/auth.user
          const uid = user.uid;
          console.log(user)
          setadminName(user.email)
          getIntData();
          // ...
        } else {
          // User is signed out
          // ...
          console.log("no user")
          navigate("/")
        }
      });
},[])

useEffect(()=>{
  const params = new URLSearchParams(window.location.search);
    const authorizationCode = params.get('code');

    if (authorizationCode) {
      // If there's an authorization code in the URL, exchange it for an access token
      exchangeCodeForToken(authorizationCode);
    } else {
      // If there's no authorization code, initiate the OAuth flow
      initiateOAuthFlow();
    }
},[])

const initiateOAuthFlow = () => {
    const clientId = cred.web.client_id;
    const redirectUri = cred.web.redirect_uris[0];
    const scopes = 'https://www.googleapis.com/auth/firebase.messaging'; // Add the scopes your application requires
    const authEndpoint = 'https://accounts.google.com/o/oauth2/auth';
    const authUrl = `${authEndpoint}?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scopes)}&response_type=code`;

    window.location.href = authUrl;
  };

  const exchangeCodeForToken = (authorizationCode) => {
    const clientId = cred.web.client_id;
    const clientSecret = cred.web.client_secret;
    const redirectUri = cred.web.redirect_uris[0];
    const tokenEndpoint = 'https://oauth2.googleapis.com/token';

    fetch(tokenEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        code: authorizationCode,
        client_id: clientId,
        client_secret: clientSecret,
        redirect_uri: redirectUri,
        grant_type: 'authorization_code',
      }),
    })
      .then(response => response.json())
      .then(data => {
        // Access token received
        const accessToken = data.access_token;
        console.log(accessToken)
        setAccessToken(accessToken);
      })
      .catch(error => {
        console.error('Error exchanging code for token:', error);
      });
  };

  const handleNotificationValidation = () =>{
    const validationErrors = validateInputs(notificationSend);
    if(Object.keys(validationErrors).length === 0){
      setErrors({})
      if(deviceTokens.length === 0){
        alert("User count is zero")
        return;
      }else{
        setOpen(true)
      }
    }else{
      alert("Please fill in all fields")
      setErrors(validationErrors)
    }
  }

return (
    <div style={{"margin":"auto"}} className="pr-5 pt-1 pl-5">
        <NavBar />
        <div className="flex justify-between pl-10 pr-10 mb-5 mt-5">
            <h2 className="text-2xl font-semibold">
                New Notification
            </h2>
            <div className='flex gap-x-6'>
                <button onClick={()=>navigate("/Notification")} className="w-40 h-12 bg-transparent border-2 mt-2 text-base text-[#32497F] tracking-wider rounded-2xl border-solid border-[#32497F]">Cancel</button>
                <button onClick={handleNotificationValidation} className="w-40 text-white h-12 bg-[#4F72C5] text-base tracking-wider border-none mt-2 rounded-2xl">Send</button>
            </div>
        </div>
        <div className="ml-56 pl-20 mb-10 h-80">
            <div className="flex flex-col w-80 pt-4 h-96 gap-y-8 mb-10">
                <div className='flex flex-col gap-y-3'>
                      <label style={{"fontSize":"16px"}} for="interestCategory">Select Interest</label>
                      <span className='absolute right-20'>Notification to be sent to {deviceTokens.length} users</span>
                    <Select onChange={(e,value,name)=>{
                      console.log(name)
                      handleChange(e,value)
                      }} id="interestCategory" name='examName' placeholder="Select interest : " className={(errors.examName)?'pl-4 pr-4 w-96 h-11 rounded-xl border-2 border-rose-500 border-solid':'pl-4 pr-4 w-96 h-11 rounded-xl border-2 border-[#9E9B9B] border-solid'}>
                    <Option value="All">Open For All</Option>
                    {intData.map((dt)=>{
                        return(
                            <Option key={dt._id} name="examName" value={dt.Name}>{dt.Name}</Option>
                        )
                    })}
                    </Select>
                    {errors.examName && <div className='absolute top-64 mt-5'><p className="text-red-500">{errors.examName}</p></div>}
                </div>
                <div className='flex gap-x-7'>
                    <div className='relative flex flex-col gap-y-3'>
                        <label style={{"fontSize":"16px"}}>Notification Date</label>
                        <input onChange={handleChange} className={(errors.fullDate)?'pl-4 pr-4 w-96 h-11 rounded-xl border-2 border-rose-500 border-solid':'pl-4 pr-4 w-96 h-11 rounded-xl border-2 border-[#9E9B9B] border-solid'} name='fullDate' type='date'/>
                        {errors.fullDate && <div className='absolute top-16'><p className="text-red-500">{errors.fullDate}</p></div>}
                    </div>
                    {/* <div className='flex flex-col gap-y-3'>
                        <label style={{"fontSize":"16px"}}>Notification Month Year</label>
                        <input className="w-24 h-10 rounded-xl border-[#9E9B9B] border-solid border-2" type='text'/>
                    </div> */}
                </div>
                <div className='relative flex flex-col gap-y-3'>
                        <label style={{"fontSize":"16px"}}>Notification Title</label>
                        <input onChange={handleChange} className={(errors.examTitle)?'pl-4 pr-4 w-96 h-11 rounded-xl border-2 border-rose-500 border-solid':'pl-4 pr-4 w-96 h-11 rounded-xl border-2 border-[#9E9B9B] border-solid'} name='examTitle' type='text'/>
                        {errors.examTitle && <div className='absolute top-16'><p className="text-red-500">{errors.examTitle}</p></div>}
                </div>
                <div className='relative flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}}>Description</label>
                    <div className={(errors.examInfo)?'rounded-xl border-2 border-rose-500 border-solid':'rounded-xl border-2 border-[#9E9B9B] border-solid'} style={{"width":"650px", "height":"150px", "overflow":"hidden"}}>
                        <ReactQuill
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        onChange={handleEditorText}
                        placeholder="write your content ...."
                        style={{ height: "120px", width:"650px"}}
                        >
                        </ReactQuill>
                    </div>
                    {errors.examInfo && <div className='absolute top-44'><p className="text-red-500">{errors.examInfo}</p></div>}
                </div>
                <div className='relative flex flex-col gap-y-3 mb-10'>
                        <label style={{"fontSize":"16px"}}>Notification URL</label>
                        <input onChange={handleChange} className={(errors.examURL)?'pl-4 pr-4 w-96 h-11 rounded-xl border-2 border-rose-500 border-solid':'pl-4 pr-4 w-96 h-11 rounded-xl border-2 border-[#9E9B9B] border-solid'} name='examURL' type='text'/>
                        {errors.examURL && <div className='absolute top-16'><p className="text-red-500">{errors.examURL}</p></div>}
                </div>
            </div>
        </div>
        <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className = "bg-gray"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-100 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-100 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
            <Dialog.Panel style={{"width":"655px","height":"292px","fontFamily":"Segoe UI"}} className="absolute top-64 transform border border-[#707070] overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all  sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white pl-10 px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div> */}
                    <div className="mt-3 text-left sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-2xl font-semibold leading-6 text-gray-900">
                        Send Notification ?
                      </Dialog.Title>
                      <img onClick={() => setOpen(false)} src={crossIocon} alt='cross' className='absolute right-12 top-10'/>
                      <div className="mt-10">
                        <p className="text-base text-gray-500">
                          Are you sure, you want to send notification to {deviceTokens.length} users?
                        </p>
                      </div>
                      {/* <div className='flex flex-col pr-9'>
                        <label className='text-base mb-2'>Please provide comment below</label>
                        <textarea onChange={(e)=>{setComment(e.target.value)}} id="w3review" name="w3review" rows="4" cols="50" className='w-78 h-24 rounded-lg border pt-3 pl-3'></textarea>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 flex mt-6 justify-center gap-x-5">
                  <button
                    type="button"
                    style={{
                      "borderColor" : "#32497F",
                      "borderWidth" :"2px"
                    }}
                    className="justify-center w-36 h-12 border-solid rounded-2xl bg-white px-3 py-2 text-lg text-[#32497F] sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="justify-center w-36 h-12 rounded-2xl bg-[#4F72C5] px-3 py-2 text-base text-[white] border-2 sm:mt-0 sm:w-auto border-none"
                    onClick={sendNotificationsToAll}
                    ref={cancelButtonRef}
                  >
                    Send
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </div>
  )
}

export default CreateNotification