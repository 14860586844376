import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function ServiceNavbar() {
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    const storedIndex = localStorage.getItem('selectedServiceNavIndex');
    if (storedIndex !== null) {
      setSelectedIndex(parseInt(storedIndex));
    }
  }, []);

  const handleOptionClick = (index) => {
    setSelectedIndex(index);
    localStorage.setItem('selectedServiceNavIndex', index.toString());
  };

  return (
    <div className='flex border w-5/6 h-12 mt-6 ml-4 items-center gap-x-10'>
      <div className='flex flex-col'>
        <div onClick={() => { handleOptionClick(0); navigate("/Services/ExpertGuide") }} className={`text-lg font-medium pb-2 cursor-pointer`}>
        Expert Learning Guides
      </div>
        <div className={(selectedIndex === 0)?'mt-0 h-1 rounded-xl bg-[#4F72C5]':""}></div>
      </div>
      <div>
        <div onClick={() => { handleOptionClick(1); navigate("/Services/PersolnalizedCoaching") }} className={`text-lg font-medium pb-2 cursor-pointer`}>
        Personalized Career Coaching by Experts
      </div>
      <div className={(selectedIndex === 1)?'mt-0 h-1 rounded-xl bg-[#4F72C5]':""}></div>
      </div>
      <div>
        <div onClick={() => { handleOptionClick(2); navigate("/Services/Colleges") }} className={`text-lg font-medium pb-2 cursor-pointer`}>
        Top Colleges
      </div>
      <div className={(selectedIndex === 2)?'mt-0 h-1 rounded-xl bg-[#4F72C5]':""}></div>
      </div>
    </div>
  );
}

export default ServiceNavbar;
