import React from 'react'
import NavBar from './NavBar'
import {getFirestore, doc,getDoc,collection, getDocs , onSnapshot, addDoc,query,where} from "firebase/firestore"
import { app } from "../firebase";
import ReactQuill from 'react-quill';
import 'quill/dist/quill.snow.css'
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import {getAuth, onAuthStateChanged} from "firebase/auth"
const auth = getAuth(app)
const firestore = getFirestore(app)
const regex = /^[a-zA-Z0-9\s]+$/;
const modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] }
      ],
      [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
    ]
  };

const formats = [
    "header", "height", "bold", "italic",
    "underline", "strike", "blockquote",
    "list", "color", "bullet", "indent",
    "link", "image", "align", "size",
  ];


export default function CreatePlan() {

    const [inputs, setInputs] = useState({
        counter : 0,
        planName: "",
        caption: "",
        active: true,
        rs: 0,
        days: 0,
        interest: 0
    })
    const [errors, setErrors] = useState({});
    const [planActive, setPlanActive] = useState(true);
    const [planExistsError, setplanExistsError] = useState(false);
    const [invalidInputError, setinvalidInputError] = useState(false);
    
    const navigate = useNavigate()

    const handleClick = async() =>{
        const validationErrors = validateInputs(inputs);
        console.log(validationErrors)
        console.log(inputs)
        if (Object.keys(validationErrors).length === 0) {
            if(!regex.test(inputs.planName)) {
                setErrors({})
                setplanExistsError(false)
                setinvalidInputError(true);
                return;
            }
            const planDocs = collection(firestore,'Plans')
            const q = query(planDocs,where('planName',"==",inputs.planName.toUpperCase()))
            const querySnapshot = await getDocs(q)
            if (!querySnapshot.empty) {
                setErrors({})
                setplanExistsError(true);
                return;
            } 
            else {
                const ref = collection(firestore,"Plans")
                    addDoc(ref,inputs)
                    .then((res)=>{
                        console.log("Data uploaded succesfully :", res.id)
                    })
                    .then(
                        navigate("/PlanManagement")
                    )
                    .catch((err)=>{
                        console.log(err)
                    })
            }
        } else {
            alert('Please fill in all fields.')
            setplanExistsError(false)
            setErrors(validationErrors);
        }
    }

    const validateInputs = (values) => {
        const errors = {};

        if (!values.planName.trim()) {
            errors.planName = "Plan Name is required";
        }
        if (!values.caption.trim()) {
            errors.caption = "Description is required";
        }
        if (!values.rs) {
            errors.rs = "Plan Price is required";
        }
        if (!values.days) {
            errors.days = "Validity is required";
        }
        if (!values.interest) {
            errors.interest = "Selectable Interests is required";
        }

        return errors;
    }

    const handleEditorText = (content) =>{
        console.log(content)
        setInputs(values => ({...values,caption:content}))
    }

    const handleChange = (event) => {
        const name = event.target.name;
        let value = event.target.value
        if(name === 'active'){
            value = (inputs.active)?false:true
        }
        else if(name === 'planName'){
            value = event.target.value.toUpperCase();
        }
        else if (name === 'interest'){
            value = Number(event.target.value)
        }
        else if(name === 'days'){
            value = Number(event.target.value)
        }
        else if(name === 'rs'){
            value = Number(event.target.value)
        }

        setInputs(values => (console.log(values),{...values, [name]: value}))
    }

    useEffect (()=>{
        onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/auth.user
          const uid = user.uid;
          console.log(user)
          // ...
        } else {
          // User is signed out
          // ...
          console.log("no user")
          navigate("/")
        }
      });
      },[]);

  return (
    <div style={{"margin":"auto"}} className="pr-5 pt-1 pl-5">
        <NavBar />
        <div className="flex justify-between pl-10 pr-10 mb-5 mt-5">
            <h2 className="text-2xl font-semibold">
                Plan Management &gt; Create Plan
            </h2>
            <div className='flex gap-x-6'>
                <button onClick={()=>navigate("/PlanManagement")} className="w-40 h-12 bg-transparent border-2 mt-2 border-solid text-base text-[#32497F] tracking-wider rounded-2xl border-[#32497F]">Cancel</button>
                <button onClick={handleClick} className="w-40 text-white h-12 bg-[#4F72C5] text-base border-none mt-2 tracking-wider rounded-2xl">Create</button>
            </div>
        </div>
        <div className="ml-56 h-full flex pl-20 h-80">
            <div className="flex flex-col w-3/5 pt-4 h-full gap-y-10 border-r-2 border-dashed border-[#707070]">
                <div className='relative flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}}>Plan Name</label>
                    <input onChange={(e)=>{
                        setplanExistsError(false)
                        setinvalidInputError(false)
                        handleChange(e)}} className={(errors.planName || planExistsError || invalidInputError)?"w-96 h-10 rounded-xl border-2 border-rose-500 border-solid pl-4":"w-96 h-10 rounded-xl border-2 border-[#9E9B9B] border-solid pl-4"} name='planName' type='text'/>
                    {errors.planName && <div className='absolute top-16'><p className="text-red-500">{errors.planName}</p></div>}
                    {(planExistsError) && <div className='absolute top-16'><p className="text-red-500">Plan already exists</p></div>}
                    {(invalidInputError) && <div className='absolute top-16'><p className="text-red-500">Invalid Input</p></div>}
                </div>
                <div className='relative flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}}>Description</label>
                    <div className={(errors.caption)?'rounded-xl border-2 border-rose-500 border-solid':'rounded-xl border-2 border-[#9E9B9B] border-solid'} style={{"width":"650px", "height":"250px", "overflow":"hidden"}}>
                        <ReactQuill
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        onChange={handleEditorText}
                        placeholder="write your content ...."
                        style={{ height: "209px", width:"650px"}}
                        >
                        </ReactQuill>
                    </div>
                    {errors.caption && <div className='absolute top-64 mt-4'><p className="text-red-500">{errors.caption}</p></div>}
                </div>
                <div className='flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}}>Status</label>
                    <label class="inline-flex items-center cursor-pointer">
                    <input type="checkbox" value={inputs.active} onChange={(e)=>{
                        handleChange(e)
                        }} class="sr-only peer" name='active' checked={(inputs.active)?true:false}/>
                    <span class="text-sm font-normal text-gray-900 dark:text-gray-300 mr-3">Inactive</span>
                    <div class="relative w-11 h-6 bg-[#b6b8b7] rounded-full peer peer-focus:ring-4 peer-focus:ring-white dark:peer-focus:ring-white dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#818181]"></div>
                    <span class="ms-3 text-sm font-normal text-gray-900 dark:text-gray-300 ml-3">Active</span>
                    </label>
                </div>
            </div>
            <div className='w-96 pl-12 pt-4 flex flex-col gap-y-10'>
                <div className='flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}}>Plan Price</label>
                    <div className='relative'>
                        <input onChange={handleChange} onWheel={(e) => e.target.blur()} className={(errors.rs)?"w-20 h-10 rounded-xl border-2 mr-3 border-rose-500 border-solid pl-4":"w-20 h-10 rounded-xl border-2 mr-3 border-[#9E9B9B] border-solid pl-4"} name='rs' type='number'/><span>Rs</span>
                        {errors.rs && <div className='absolute top-8'><p className="text-red-500">{errors.rs}</p></div>}
                    </div>
                </div>
                <div className='flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}}>Validity</label>
                    <div className='relative'>
                        <input onChange={handleChange} onWheel={(e) => e.target.blur()} className={(errors.days)?"w-20 h-10 rounded-xl border-2 mr-3 border-rose-500 border-solid pl-4":"w-20 h-10 rounded-xl border-2 mr-3 border-[#9E9B9B] border-solid pl-4"} name='days' type='number'/><span>Days</span>
                        {errors.days && <div className='absolute top-8'><p className="text-red-500">{errors.days}</p></div>}
                    </div>
                </div>
                <div className='flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}}>Selectable Interests</label>
                    <div className='relative'>
                        <input onChange={handleChange} onWheel={(e) => e.target.blur()} className={(errors.interest)?"w-20 h-10 rounded-xl border-2 mr-3 border-rose-500 border-solid pl-4":"w-20 h-10 rounded-xl border-2 mr-3 border-[#9E9B9B] border-solid pl-4"} name='interest' type='number'/>
                        {errors.interest && <div className='absolute top-8'><p className="text-red-500">{errors.interest}</p></div>}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
