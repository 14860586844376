import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Desktop from "./pages/Desktop";
import UserData from "./components/UserData";
import Interest from "./components/Interest";
import CreateInterest from "./components/CreateInterest";
import EditInterest from "./components/EditInterest";
import Login from "./components/Login";
import UpdateImages from "./components/UpdateImages"
import Plan from "./components/Plan";
import CreatePlan from "./components/CreatePlan";
import {app} from "./firebase"
import EditPlan from "./components/EditPlan";
import EditCollege from "./components/services/EditCollege";
import { getAuth, onAuthStateChanged } from "firebase/auth";
const auth = getAuth(app);
import Notification from "./components/Notification";
import CreateNotification from "./components/CreateNotification";
import ExpertGuide from "./components/services/ExpertGuide";
import CreateGuide from "./components/services/CreateGuide";
import EditGuide from "./components/services/EditGuide";
import PersonalizedCoaching from "./components/services/PersonalizedCoaching";
import TopColleges from "./components/services/TopColleges";
import NewCollge from "./components/services/NewCollge";
import NewSlot from "./components/services/NewSlot";
import ShowMeetingLink from "./components/services/showMeetingLink";
import Audit from "./components/Audit";
import TermsAndConditions from "./components/TermsAndConditions";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/Dashboard" element={<Desktop />} />
      <Route path="/UserManagement/:id" element={<UserData />}/>
      <Route path="/InterestManagement" element={<Interest />}/>
      <Route path="/createInterest" element={<CreateInterest />}/>
      <Route path="/InterestManagement/EditInterest/:IntName" element={<EditInterest />}/>
      <Route path="/updateImages/:id/:id" element = {<UpdateImages />} />
      <Route path="/PlanManagement" element={<Plan/>} />
      <Route path="/PlanManagement/createPlan" element = {<CreatePlan/>} />
      <Route path = "/PlanManagement/EditPlan/:id" element={<EditPlan/>} />
      <Route path = "/Notification" element={<Notification/>} />
      <Route path = "/Notification/CreateNotification" element={<CreateNotification/>} />
      <Route path = "/Services/ExpertGuide" element={<ExpertGuide/>} />
      <Route path = "/Services/ExpertGuide/NewGuide" element={<CreateGuide/>} />
      <Route path = "/Services/ExpertGuide/:id" element={<EditGuide/>} />
      <Route path = "/Services/PersolnalizedCoaching" element={<PersonalizedCoaching/>} />
      <Route path = "/Services/Colleges" element={<TopColleges/>} />
      <Route path = "/Services/Colleges/AddNewCollge" element={<NewCollge/>} />
      <Route path = "/Services/Colleges/:id" element={<EditCollege/>} />
      <Route path="/Services/PersolnalizedCoaching/NewSlot" element={<NewSlot/>} />
      <Route path="/Services/PersolnalizedCoaching/:id/:id" element={<ShowMeetingLink/>} />
      <Route path="/Audit" element={<Audit/>} />
      <Route path="/privacy-policy" element={<TermsAndConditions/>} />
    </Routes>
  );
}
export default App;
