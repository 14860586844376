import React,{useEffect,useState} from 'react'
import { useLocation ,useNavigate} from 'react-router-dom'
import {collectionGroup,getFirestore, doc,getDoc,collection,onSnapshot, getDocs,query,where} from "firebase/firestore"
import { app } from "../../firebase";
import NavBar from '../NavBar'
const firestore = getFirestore(app)

function ShowMeetingLink() {

const loc = useLocation();
const slotId = loc.pathname.split("/")
const [slotData, setslotData] = useState({
    slotTime : null,
    emailId : null,
    eventId : null,
    slotPrice : null,
    studName : null,
    studPhone : null
})
const navigate = useNavigate()

const getSlotData = () =>{
    console.log(slotId)
    const docRef = doc(firestore,`slots/${slotId[3]}/Slots/${slotId[4]}`)
            // Fetch data from the document
            getDoc(docRef)
            .then((dc)=>{
                console.log(dc.data());
                if(!dc.data().isBooked){
                    setslotData(dc.data())
                }else{
                    const colRef = collection(firestore,"Users")
                const q = query(colRef,where("email",'==',dc.data().emailId))
                getDocs(q).then((dt)=>{
                    dt.docs.map((dat)=>{
                        console.log(dat.data())
                        const updatedData = {
                            ...dc.data(),
                            studName : dat.data().fullName,
                            studPhone : dat.data().phoneNumber
                        }
                        setslotData(updatedData)
                    })
                })
                }
            }).catch((error)=>{
                console.log(error)
            })
}


useEffect(()=>{
    getSlotData();
},[])


return (
    <div style={{"margin":"auto"}} className="pr-5 pt-1 pl-5">
        <NavBar />
        <div className="flex justify-between pl-10 pr-10 mb-5 mt-5">
            <h2 className="text-2xl font-semibold">
                Personalized Career Coaching by Experts &gt; {slotId[3]} &#124; {(slotData.slotTime !== null)?slotData.slotTime:""}
            </h2>
            <div className='flex gap-x-6'>
                <button onClick={()=>navigate("/Services/PersolnalizedCoaching/")} className="w-40 h-12 bg-transparent text-[#32497F] border-2 mt-2 border-solid text-base rounded-2xl border-[#32497F]">Back</button>
            </div>
        </div>
        <div className='w-full '>
            <div className='flex flex-col w-4/5 ml-72 gap-y-5 '>
                <div className='flex gap-x-20 border-b-2 border-dashed border-[#707070]'>
                    <div className='flex mb-6 flex-col gap-y-3'>
                        <label>Select Date & Time</label>
                        <div className='text-xl font-normal'>{`${slotId[3]} ${(slotData.slotTime !== null)?slotData.slotTime:""}`}</div>
                    </div>
                    <div className='flex flex-col gap-y-3'>
                        <label>Price</label>
                        <div className='text-xl font-normal'>{(slotData.slotPrice !== null)?slotData.slotPrice:""}</div>
                    </div>
                </div>
            
                <div className='flex flex-col gap-y-3 pb-6 border-b-2 border-dashed border-[#707070]'>
                    <label>Booked By</label>
                    <div className='text-xl font-normal'>
                        <p>{(slotData.emailId !== null)?slotData.emailId:<span>---</span>}  &#124;  {(slotData.studName !== null)?slotData.studName:<span>---</span>}  &#124;   {(slotData.studPhone !== null)?slotData.studPhone:<span>---</span>}</p>
                    </div>
                </div>
                <div className='flex flex-col gap-y-3'>
                    <label>Join meeting</label> 
                    <div className='text-xl font-normal'>
                        {(slotData.eventId!==null)?<a href={slotData.eventId.hangoutLink} target='blank'>{slotData.eventId.hangoutLink}</a>:"--"}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ShowMeetingLink