import React from 'react'
import NavBar from '../NavBar'
import ReactQuill from 'react-quill';
import {Option,Select} from "../customSelect"
import 'quill/dist/quill.snow.css'
import {useState,useEffect} from "react"
import { useNavigate } from 'react-router-dom';
import {getFirestore, doc,getDoc,collection,onSnapshot, getDocs,addDoc} from "firebase/firestore"
import {getStorage, ref, uploadBytesResumable, getDownloadURL, listAll, deleteObject} from "firebase/storage"
import uploadIcon from "../../images/upload.png"
import trashCan from "../../images/trash-can.png"
import { app } from "../../firebase";
const firestore = getFirestore(app)
const storage = getStorage(app)
let imgArr = [];

const modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] }
      ],
      [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
    ]
  };

const formats = [
    "header", "height", "bold", "italic",
    "underline", "strike", "blockquote",
    "list", "color", "bullet", "indent",
    "link", "image", "align", "size",
  ];

function NewCollge() {
    const [inputs, setInputs] = useState({
        active : true,
        collegeName : "",
        collegeCategory : "",
        aboutUs :"",
        admissions : "",
        feesStructure : "",
        staff : "",
        programme : "",
        placementCell : "",
        collegeImage : "https://firebasestorage.googleapis.com/v0/b/ezapp-6bbee.appspot.com/o/collegeImage%2Fmit.jpg?alt=media&token=c5d7692c-007c-4211-add3-cb41c4b508d9"
    })
    const [planActive, setPlanActive] = useState(true)
    const [name , setName]  = useState("Choose Image")
    const [data,setData] = useState({})
    const [imageArr, setImageArr] = useState(imgArr)
    const [error, setError] = useState(false);
    const [typeError,settypeError] = useState(false)
    const navigate = useNavigate()
    const [errors, setErrors] = useState({});

    const onDataAdd = () =>{
        console.log(data.type)
        if(name === "Choose Image"){
            setError(true);
            return;
        }
        if(data.type === "image/jpg" || data.type === 'image/png' || data.type === 'image/jpeg'){
            imgArr.push(
            {
                name : name,
                data : data
            }
            
        )
        setImageArr([...imgArr])
        setName("Choose Image")
        setData({})
        }else{
            settypeError(true);
            return;
        }
    }

    const validateInputs = (values) => {
        const errors = {};

        if (!values.collegeName.trim()) {
            errors.collegeName = "College name is required.";
        }
        if (!values.collegeCategory.trim()) {
            errors.collegeCategory = "College category is required.";
        }

        return errors;
    }
    
    const handleUpload = () =>{
        const validationErrors = validateInputs(inputs);
        if(Object.keys(validationErrors).length === 0){
            if(imgArr.length === 0){
            alert("please add images.");
            return;
        }
        console.log(inputs)
    const colref = collection(firestore,"College")
                    addDoc(colref,inputs)
                    .then((res)=>{
                        console.log("Data uploaded succesfully :", res.id)
                        return res.id
                    }).then((id)=>{
                        imageArr.map((data)=>{
                            const storageRef = ref(storage, `imagesColleges/${id}/${data.name}`)
                            const uploadTask = uploadBytesResumable(storageRef, data.data)
                            uploadTask.on("state_changed",(snapshot)=>{
                                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                console.log('Upload is ' + progress + '% done');
                            },
                            (error)=>{
                                console.log(error)
                            })
                        })
                    })
                    .then(
                        ()=>{
                            imgArr = [],
                        navigate("/Services/Colleges")
                        }
                    )
                    .catch((err)=>{
                        console.log(err)
                    })
        }else{
            alert('Please fill in all fields.')
            setErrors(validationErrors);
        }
        
    }


    const handleDelete = (index) =>{
        imgArr.splice(index,1);
        setImageArr([...imgArr]);
    }

     const handleChange = (event,name=null,value=null) =>{
        let nameTmp;
        let valueTmp;
        if(name!==null && value !==null){
            nameTmp = name;
            valueTmp = value
        }else{
            nameTmp = event.target.name;
            valueTmp = event.target.value;
        }
        
        if(nameTmp === 'active'){
            valueTmp = (inputs.active)?false:true;
        }
        setInputs(values => (console.log(values),{...values, [nameTmp]: valueTmp}))
  }



//   const handleClick = () =>{
//     console.log(inputs)
//     const ref = collection(firestore,"College")
//                     addDoc(ref,inputs)
//                     .then((res)=>{
//                         console.log("Data uploaded succesfully :", res.id)
//                     })
//                     .then(
//                         navigate("/Services/ExpertGuide")
//                     )
//                     .catch((err)=>{
//                         console.log(err)
//                     })
//   }


//   useEffect(()=>{
//     getIntData();
// },[])

  return (
    <div style={{"margin":"auto"}} className="pr-5 pt-1 pl-5">
        <NavBar />
        <div className="flex justify-between pl-10 pr-10 mb-5 mt-5">
            <h2 className="text-2xl font-semibold">
                Top Colleges &gt; New College
            </h2>
            <div className='flex gap-x-6'>
                <button onClick={()=>navigate("/Services/Colleges")} className="w-40 h-12 text-[#32497F] bg-transparent border-2 mt-2 border-solid tracking-wider text-base rounded-2xl border-[#32497F]">Cancel</button>
                <button onClick={handleUpload} className="w-40 text-white h-12 bg-[#4F72C5] text-base border-none mt-2 tracking-wider rounded-2xl">Upload</button>
            </div>
        </div>
        <div className="ml-56 h-full flex flex-col pl-20 h-80">
            <div className="flex w-3/5 pt-4 pb-8 h-full gap-x-8 border-b-2 border-dashed border-[#707070]">
                <div className='relative flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}} for="interestCategory">College Title</label>
                    <input onChange={handleChange} className={(errors.collegeName)?"w-96 h-10 rounded-xl border-2 border-rose-500 border-solid pl-4":"w-96 h-10 rounded-xl border-2 border-[#9E9B9B] border-solid pl-4"} name='collegeName' type='text'/>
                    {errors.collegeName && <div className='absolute top-16'><p className="text-red-500">{errors.collegeName}</p></div>}
                </div>
                <div className='flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}} className='mb-3'>Status</label>
                    <label class="inline-flex items-center cursor-pointer">
                    <input type="checkbox" class="sr-only peer" name='active' value={inputs.active} onChange={(e)=>handleChange(e)} checked={(inputs.active)?true:false}/>
                    <span class="text-sm font-normal text-gray-900 dark:text-gray-300 mr-3">Inactive</span>
                    <div class="relative w-11 h-6 bg-[#b6b8b7] rounded-full peer peer-focus:ring-4 peer-focus:ring-white dark:peer-focus:ring-white dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#818181]"></div>
                    <span class="ms-3 text-sm font-normal text-gray-900 dark:text-gray-300 ml-3">Active</span>
                    </label>
                </div>
                <div className='relative flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}} for="interestCategory">Select Qualification</label>

                    <Select onChange={(e,value)=>{handleChange(e,"collegeCategory",value)}} placeholder="Select qualification" name='collegeCategory' id="interestCategory" className={(errors.collegeCategory)?'w-64 h-11 pl-4 rounded-xl border-2 border-rose-500 border-solid':'w-64 h-11 pl-4 rounded-xl border-2 border-[#9E9B9B] border-solid'}>
                    <Option value="UG">Under Graduate</Option>
                    <Option value="PG">Post Graduate</Option>
                    </Select>
                    {errors.collegeCategory && <div className='absolute top-16'><p className="text-red-500">{errors.collegeCategory}</p></div>}
                </div>
            </div>
            <div>
            <div className='w-3/5 mb-3 flex flex-col pt-4 pb-8 gap-y-4 border-b-2 border-dashed border-[#707070]'>
                {
                    (imageArr.length !== 0) && imageArr.map((ig,index)=>{
                        console.log(ig)
                        return( <div className='flex flex-col gap-y-4'>
                                    <label>Carousel Image {++index}</label>
                                    <div  className='relative flex gap-x-8'>
                                        <label style={{"border":"solid #9E9B9B","borderWidth":"2px","borderColor":"#9E9B9B"}} className='w-3/5 pl-5 pt-2 rounded-xl h-8 z-20'>{ig.name}</label>
                                        <span className='absolute right-72 top-2'><img className='w-6 h-6 z-10' src={uploadIcon}/></span>
                                        <input style={{"display":"none"}} type='text' readOnly/>
                                        <img src={trashCan} className='m-0 mt-2.5 cursor-pointer w-6 h-6' onClick={()=>handleDelete(--index)}/>
                                    </div>
                                </div>)
                    })
                }
                <div className='relative flex flex-col gap-y-4'>
                    <label>Upload Carousel Image</label>
                    <div  className='relative flex gap-x-8'>
                        <label for="imgOne" className={(error || typeError)?'w-3/5 pl-5 pt-2 border-[#c4c2c2] rounded-xl border-rose-500 border-solid border-2 h-8 z-20':'w-3/5 pl-5 pt-2 border-[#c4c2c2] rounded-xl border-solid border-2 h-8 z-20'}>{name}</label>
                        <span className='absolute right-72 top-2'><img className='w-6 h-6 z-10' src={uploadIcon}/></span>
                        <input onChange={(e)=>{
                            console.log(e.target.files[0])
                            setError(false)
                            settypeError(false)
                            setData(e.target.files[0])
                            setName(e.target.files[0].name)
                            }} id='imgOne' style={{"display":"none"}} name='imgOne' type='file' accept='accept="image/png, image/jpg, image/jpeg"'/>
                            <p className='m-0 mt-3 cursor-pointer underline' onClick={onDataAdd}>Add Image</p>
                    </div>
                    {(error)&&<label className='absolute text-rose-500 top-20'>upload image</label>}
                    {(typeError)&&<label className='absolute text-rose-500 top-20'>Allowed fomats : .jpg, .png, .jpeg</label>}
                </div>
            </div>
        </div>
                <div className='flex flex-col gap-y-3 pb-3 border-b-2 border-dashed border-[#707070] mb-6'>
                    <label style={{"fontSize":"16px"}}>About Us</label>
                    <div className='rounded-xl border-2 border-[#9E9B9B] border-solid mb-6' style={{"width":"650px", "height":"190px", "overflow":"hidden"}}>
                        <ReactQuill
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        onChange={(content)=>{
                            setInputs((values)=>({
                                ...values,
                                aboutUs : content
                            }))
                        }}
                        placeholder="write your content ...."
                        name = 'aboutUS'
                        style={{ height: "152px", width:"650px"}}
                        >
                        </ReactQuill>
                    </div>
                </div>
                <div className='flex flex-col gap-y-3 pb-3 border-b-2 border-dashed border-[#707070] mb-6'>
                    <label style={{"fontSize":"16px"}}>Admissions</label>
                    <div className='rounded-xl border-2 border-[#9E9B9B] border-solid mb-6' style={{"width":"650px", "height":"190px", "overflow":"hidden"}}>
                        <ReactQuill
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        onChange={(content)=>{
                            setInputs((values)=>({
                                ...values,
                                admissions : content
                            }))
                        }}
                        placeholder="write your content ...."
                        style={{ height: "152px", width:"650px"}}
                        >
                        </ReactQuill>
                    </div>
                </div>
                <div className='flex flex-col gap-y-3 pb-3 border-b-2 border-dashed border-[#707070] mb-6'>
                    <label style={{"fontSize":"16px"}}>Placement Cell</label>
                    <div className='rounded-xl border-2 border-[#9E9B9B] border-solid mb-6' style={{"width":"650px", "height":"190px", "overflow":"hidden"}}>
                        <ReactQuill
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        onChange={(content)=>{
                            setInputs((values)=>({
                                ...values,
                                placementCell : content
                            }))
                        }}
                        placeholder="write your content ...."
                        style={{ height: "152px", width:"650px"}}
                        
                        >
                        </ReactQuill>
                    </div>
                </div>
                <div className='flex flex-col gap-y-3 pb-3 border-b-2 border-dashed border-[#707070] mb-6'>
                    <label style={{"fontSize":"16px"}}>Programme</label>
                    <div className='rounded-xl border-2 border-[#9E9B9B] border-solid mb-6' style={{"width":"650px", "height":"190px", "overflow":"hidden"}}>
                        <ReactQuill
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        onChange={(content)=>{
                            setInputs((values)=>({
                                ...values,
                                programme : content
                            }))
                        }}
                        placeholder="write your content ...."
                        style={{ height: "152px", width:"650px"}}
                        >
                        </ReactQuill>
                    </div>
                </div>
                <div className='flex flex-col gap-y-3 border-b-2 pb-3 border-dashed border-[#707070] mb-6'>
                    <label style={{"fontSize":"16px"}}>Fees Structure</label>
                    <div className='rounded-xl border-2 border-[#9E9B9B] border-solid mb-6' style={{"width":"650px", "height":"190px", "overflow":"hidden"}}>
                        <ReactQuill
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        onChange={(content)=>{
                            setInputs((values)=>({
                                ...values,
                                feesStructure : content
                            }))
                        }}
                        placeholder="write your content ...."
                        style={{ height: "152px", width:"650px"}}
                        >
                        </ReactQuill>
                    </div>
                </div>
                <div className='flex flex-col gap-y-3 pb-3 border-b-2 border-dashed border-[#707070] mb-6'>
                    <label style={{"fontSize":"16px"}}>Faculty Members & Staff</label>
                    <div className='rounded-xl border-2 border-[#9E9B9B] border-solid mb-6' style={{"width":"650px", "height":"190px", "overflow":"hidden"}}>
                        <ReactQuill
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        onChange={(content)=>{
                            setInputs((values)=>({
                                ...values,
                                staff : content
                            }))
                        }}
                        placeholder="write your content ...."
                        style={{ height: "152px", width:"650px"}}
                        >
                        </ReactQuill>
                    </div>
                </div>
        </div>
    </div>
  )
}

export default NewCollge