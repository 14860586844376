import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import crossIocon from "../images/icons8-multiply-24.png"

export default function DialogPop({
  dialogTitle,
  data,
  open,
  setOpen,
  comment,
  setComment,
  commentError,
  setcommentError,
  statusVal,
  setStatusVal
}) {
  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className = "bg-gray"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
            <Dialog.Panel style={{"width":"655px","height":"354px","fontFamily":"Segoe UI"}} className="absolute top-64 transform border border-[#707070] overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all  sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white pl-10 px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div> */}
                    <div className="mt-3 text-left sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-2xl font-semibold leading-6 text-gray-900">
                        {dialogTitle}
                      </Dialog.Title>
                      <img onClick={() => setOpen(false)} src={crossIocon} alt='cross' className='absolute right-12 top-10'/>
                      <div className="mt-2">
                        <p className="text-base text-gray-500">
                          Are you sure, you want to make {data} {(statusVal)?<span>Inactive</span>:<span>Active</span>}?
                        </p>
                      </div>
                      <div className='relative flex flex-col pr-9'>
                        {/* add padding top-left */}
                        <label className='text-base mb-2'>Please provide comment below</label>
                        <textarea onChange={(e)=>{
                        setcommentError(false)
                        setComment(e.target.value)}} id="w3review" name="w3review" rows="4" cols="50" className='w-78 h-24 rounded-lg border pt-3 pl-3'></textarea>
                        {(commentError)&&<div className='absolute top-28 mt-3'><p className="text-red-500">Please provide comment.</p></div>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 flex justify-center gap-x-5">
                  <button
                    type="button"
                    style={{
                      "borderColor" : "#32497F",
                      "borderWidth" :"2px"
                    }}
                    className="justify-center w-36 h-12 border-solid rounded-2xl bg-white tracking-wider  px-3 py-2 text-base text-[#32497F] sm:mt-0 sm:w-auto"
                    onClick={() => {
                      setOpen(false)}}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="justify-center w-36 h-12 rounded-2xl border-solid bg-white tracking-wider px-3 py-2 text-base text-[#FF0000] border-2 sm:mt-0 sm:w-auto border-[#FF0000]"
                    onClick={()=>{
                      if(comment === ""){
                          setcommentError(true);
                          return;
                        }else{
                          (statusVal) ? setStatusVal(false) : setStatusVal(true)
                          setOpen(false)
                        }
                      
                    }}
                    ref={cancelButtonRef}
                  >
                    Yes
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}