import React from 'react'
import NavBar from '../NavBar'
import DataTable from "react-data-table-component";
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import {getFirestore, doc,getDoc,collection, getDocs, onSnapshot} from "firebase/firestore"
import {getAuth, onAuthStateChanged} from "firebase/auth"
import { app } from "../../firebase";
import ServiceNavbar from "./ServiceNavbar"
const firestore = getFirestore(app)
const clearfilterCss = "w-40 h-10 bg-transparent cursor-pointer text-lg text-[#32497F] border-solid border-2 border-[#4F72C5] rounded-2xl";
const filterCss = "w-40 h-10 bg-[#4F72C5] cursor-pointer text-lg text-white border-none rounded-2xl"

const columns = [
  {
    name : 'Sr NO',
    selector : row => row.SrNo,
    width :"200px",
    left : true
  },
  {
    name : 'College Name',
    selector : row=>row.CollegeName,
    sortable :true,
    left : true,
    conditionalCellStyles: [
			{
				when: row => row.CollegeName,
				style: {
					color: '#5F8EFC',
					'&:hover': {
						cursor: 'pointer',
					},
				},
			},
    ]
  },
  {
    name : 'Status',
    selector : row=>row.Status,
    center : true
  }
]

const customStyles = {
  headRow :{
    style: {
			// backgroundColor: theme.background.default,
      fontSize : '16px',
      fontWeight : 'bold',
      fontFamily : 'Segoe UI',
			minHeight: '52px',
			borderBottomWidth: '1px',
			borderBottomColor: '#8A8A8A',
			borderBottomStyle: 'solid',
		},

  },
  rows : {
    style : {
      fontSize: '16px',
			fontWeight: 400,
      fontFamily : 'Segoe UI',
			// color: theme.text.primary,
			// backgroundColor: theme.background.default,
			minHeight: '48px',
			'&:not(:last-of-type)': {
				borderBottomStyle: 'solid',
				borderBottomWidth: '1px',
				borderBottomColor: '#8A8A8A'
			},
    }
  },
  pagination :{
    style: {
			fontSize: '16px',
			minHeight: '56px',
      fontFamily : 'Segoe UI',
			borderTopStyle: 'solid',
			borderTopWidth: '1px',
			borderTopColor: '#8A8A8A',
      color : 'black'
		},
  }
}

const conditionalRowStyles = [
			{
				when: row => row.Status === "Inactive",
				style: {
					backgroundColor: '#F1F1F1'
				}
			}]

function TopColleges() {

    let srNo = 0;
    const navigate = useNavigate();
    const [originalData, setoriginalData] = useState([])
    const [dataKey, setDataKey] = useState(originalData)

    

    const handleSearch = (e) =>{
      setDataKey(originalData.filter((dt)=>dt.CollegeName.toLowerCase().includes(e.target.value.toLowerCase())))
    }

    const handleChange = (e) =>{
      navigate("/Services/Colleges/"+e._id)
    }

    const handleGetColleges = () =>{
        const ref = collection(firestore, "College");

    // Subscribe to real-time changes
    const unsubscribe = onSnapshot(ref, (snapshot) => {
        const demoData = [];
        snapshot.forEach((dc) => {
            console.log("snap ",dc.data())
            demoData.push({
                SrNo: ++srNo,
                _id: dc.id,
                CollegeName: dc.data().collegeName,
                Status : (dc.data().active)?"Active":"Inactive"
            });
        });
        setoriginalData(demoData)
        setDataKey(demoData)
    });

    // Return unsubscribe function to clean up the listener when component unmounts
    return () => unsubscribe();
    }

    useEffect (()=>{
        handleGetColleges();
    },[])
  return (
    <div style={{"margin":"auto"}} className="pr-5 pt-1 pl-5">
        <NavBar />
        <div className='flex'>
          <ServiceNavbar />
        <button onClick={()=>(navigate("/Services/Colleges/AddNewCollge"))} className='w-56 h-12 bg-[#4F72C5] cursor-pointer text-base text-white mt-4 border-none rounded-2xl '>Add New College</button>
        </div>
        <div className="pr-4 pl-4">
          <div className="flex justify-between mt-5 mb-5">
            <div className='flex gap-x-10 mt-5 mb-5'>
            <input onInput={handleSearch} type="Text"  placeholder="Search By College Name" className="pl-5 w-56 h-10 border-solid border-2 border-[#9E9B9B] text-black rounded-xl"/>
            <p onClick={()=>navigate("/updateImages/TopColleges/uploadCarouselImages",{state:{
              storagePath : "topCollegesCarousel",
              navigationPath : "Services/Colleges",
              moduleName : "Top Colleges"
            }})} className='text-black underline decoration-black'>Update Homepage Content and Images</p>
            </div>
          </div>
          <div>
            <DataTable 
              columns={columns}
              data={dataKey}
              onRowClicked={handleChange}
              customStyles={customStyles}
              conditionalRowStyles={conditionalRowStyles}
              pagination
            />
          </div>
        </div>
      </div>
  )
}

export default TopColleges