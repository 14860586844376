import {React} from 'react'
import NavBar from '../NavBar'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import {getFirestore, doc,getDoc,collection, getDocs, onSnapshot, setDoc, addDoc, query, where} from "firebase/firestore"
import { app } from "../../firebase";
import ServiceNavbar from "./ServiceNavbar"
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat';
var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrAfter)
dayjs.extend(customParseFormat);
const firestore = getFirestore(app)
let slotArr = [];
function NewSlot() {
    const [slotArray, setslotArray] = useState([]);
    const [slotDate, setSlotDate] = useState(null)
    const [slotStart, setSlotStart] = useState(null)
    const [slotEnd, setSlotEnd] = useState(null)
    const [slotPrice, setSlotPrice] = useState("")
    const [error, setError] = useState(false)
    const navigate = useNavigate();
    const [slotError,setslotError] = useState(false);
    const [valueError,setvalueError] = useState(false);
    const [slotTimeError, setslotTimeError] = useState(false);
 
    function doesKeyExist(key, array) {
    // Iterate through the array
    for (let obj of array) {
        // Check if the object has the specified key
        if (obj.hasOwnProperty(key)) {
            return true; // Key exists in at least one object
        }
    }
    return false; // Key does not exist in any object
}

    const doExistsinDatabase = async(currSlotRange) =>{
        const colRef = collection(firestore,`slots/${dayjs(slotDate).format('DD-MM-YYYY')}/Slots`)
        const q = await getDocs(colRef)
        if(q.empty){
            console.log("iam in empty")
            return false;
        }else {
        console.log("iam in");
        return q.docs.some((dc) => {
            if (doRangesOverlap(currSlotRange, dc.data().slotTime)) {
                console.log('i am in map');
                return true;
            }
            return false;
        });
    }
    }

    const OnSlotUpload = async() =>{
         if (!slotDate || !slotStart || !slotEnd || !slotPrice.trim()) {
            setslotTimeError(false)
            setvalueError(true)
            alert('Please fill in all fields.');
            return; // Stop further execution
        }

        if (dayjs(slotStart).isSameOrAfter(dayjs(slotEnd))) {
        alert('Please validate slot timings.');
        setvalueError(false)
        setslotError(false)
        setError(false)
        setslotTimeError(true)
        return;
    }


        const newSlotRange = `${slotStart.format('hh:mm:ss A')}-${slotEnd.format('hh:mm:ss A')}`;
        console.log(newSlotRange)
        const overlappingSlot = slotArray.find(slot => Object.keys(slot)[0] === slotDate.format('DD/MM/YYYY') && slot[Object.keys(slot)[0]].some(existingSlot => doRangesOverlap(newSlotRange, existingSlot.slotTime)));
        console.log(overlappingSlot)

        if(await doExistsinDatabase(newSlotRange)){
            console.log("first")
            alert("This slot already exist.Please update");
            setError(false)
            setvalueError(false)
            setslotTimeError(false)
            setslotError(true)
            return;
        }

        if (overlappingSlot) {
            alert("This slot already exist.Please update");
            setError(false)
            setvalueError(false)
            setslotTimeError(false)
            setslotError(true)
            return;
        }
        let slotDt = dayjs(slotDate).format('DD/MM/YYYY')
        let slotSt = dayjs(slotStart).format('hh:mm:ss A')
        let slotEn = dayjs(slotEnd).format('hh:mm:ss A')
        
        let slotTime = `${slotSt}-${slotEn}`;
        try {
            if(doesKeyExist(slotDt,slotArr)){
            let index = slotArr.findIndex(obj => obj.hasOwnProperty(slotDt))
            //console.log(slotTime)
            let exists = slotArr[index][slotDt].some(obj => Object.values(obj).includes(slotTime));
            if(exists){
                setvalueError(false)
                setslotError(false)
                setslotTimeError(false)
                setError(true)
            }else{
                slotArr[index][slotDt].push(
                    {   
                        emailId : "",
                        isBooked : false,
                        eventId : null,
                        slotTime : slotTime,
                        slotPrice : slotPrice
                    }
                )
                setError(false)
                setvalueError(false)
                setslotError(false)
                setslotTimeError(false)
                setslotArray(slotArr)
                setSlotPrice("")
                setSlotDate(null)
                setSlotEnd(null)
                setSlotStart(null)
            }
            }else{
            //console.log(slotTime.split('-'))
            slotArr.push(
                {[`${slotDt}`]:[{
                emailId : "",
                isBooked : false,
                eventId : null,
                slotTime : slotTime,
                slotPrice : slotPrice
            }]}
            )
            setError(false)
            setvalueError(false)
            setslotError(false)
            setslotTimeError(false)
            setslotArray(slotArr)
            setSlotPrice("")
            setSlotDate(null)
            setSlotEnd(null)
            setSlotStart(null)
        }
        } catch (error) {
            console.log(error)
        }
        }

    const doRangesOverlap = (range1, range2) => {
        
        const [start1, end1] = range1.split('-').map(time => dayjs(`01/01/2024 ${time}`, 'DD/MM/YYYY hh:mm:ss A'));
        const [start2, end2] = range2.split('-').map(time => dayjs(`01/01/2024 ${time}`, 'DD/MM/YYYY hh:mm:ss A'));
        console.log(start1," ",start2)
        if (start1.isSame(start2) && end1.isSame(end2)) {
        return true; // Slots are equal, so they cannot overlap
    }
        return start1.isBetween(start2, end2) || end1.isBetween(start2, end2) || start2.isBetween(start1, end1) || end2.isBetween(start1, end1);
    };

    const OnSlotDelete = (slotDate,dateInd, slotInd) =>{
        console.log(slotDate," ",dateInd," ",slotInd)
        let tmpArr = slotArr[dateInd][slotDate]
        if(tmpArr.length === 1){
            slotArr.splice(dateInd,1);
        }else{
            tmpArr.splice(slotInd,1); 
        }
        setslotArray([...slotArr])
    }

    const handleUpload = () =>{
        if(slotArray.length===0){
            alert("Please add data first");
            return;
        }
        if(error || slotError || slotTimeError || valueError){
            alert("Please clear the validations to save time slots.");
            return;
        }
        slotArr.forEach(async obj => {
    // Get the keys of the current object
    console.log(obj)
    const keys = Object.keys(obj);
    let id = keys[0].split("/");
    let tmpId = `${id[0]}-${id[1]}-${id[2]}`
    obj[keys[0]]
    // Print the keys
    console.log("Keys of object:", keys[0]);
    const colRef = collection(firestore,`slots/${tmpId}/Slots`)
    await getDocs(colRef).then(async(q)=>{
        if(q.empty){
        await setDoc(doc(firestore, "slots", tmpId), {slotUpload:dayjs(tmpId,'DD/MM/YYYY').format('YYYY/MM/DD')}).then(()=>{
        const docRef = collection(firestore,`/slots/${tmpId}/Slots`)
        obj[keys[0]].map((slot)=>{
            
            addDoc(docRef,slot).then(()=>{
                console.log("doc added")
            }).catch((error)=>{
                console.log(error)
            })
        })
    }).catch((error)=>{
        console.log(error)
    })
    }else{
        obj[keys[0]].map((slot)=>{
            
            addDoc(colRef,slot).then(()=>{
                console.log("doc added")
            }).catch((error)=>{
                console.log(error)
            })
        })
    }
    })

}
    
)
alert("Slots uploaded successfully");
navigate("/Services/PersolnalizedCoaching")
    }
    useEffect(()=>{
        slotArr = [];
    },[])
  return (
    <div style={{"margin":"auto"}} className="pr-5 pt-1 pl-5">
        <NavBar />
        <div className='flex'>
          <ServiceNavbar />
        {/* <button onClick={()=>(navigate("/Services/ExpertGuide/NewGuide"))} className='w-56 h-12 bg-[#4F72C5] cursor-pointer text-base text-white mt-4 border-none rounded-2xl '>New Guide</button> */}
        </div>
        <div className="flex justify-between pl-10 pr-10 mb-5 mt-5">
            <h2>
                Personalized Career Coaching by Experts &gt; Open New Slot
            </h2>
            <div className='flex gap-x-6'>
                <button onClick={()=>navigate("/Services/PersolnalizedCoaching")} className="w-40 h-12 bg-transparent border-2 mt-2 text-base text-[#32497F] tracking-wider rounded-2xl border-solid border-[#32497F]">Cancel</button>
                <button onClick={handleUpload} className="w-40 text-white h-12 bg-[#4F72C5] tracking-wider text-base border-none mt-2 rounded-2xl">Save</button>
            </div>
        </div>
        <div>
            <div className='w-5/6 ml-64 flex flex-col gap-y-4'>
                {
                    (slotArray.length!==0)&&slotArray.map((ig,dateInd)=>{
                            return(
                                ig[Object.keys(ig)[0]].map((slot,slotInd)=>{
                                return(
                                <div className='flex flex-col gap-y-2'>
                                    <label>Select Date & Time</label>
                                    <div  className='relative flex gap-x-4'>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DateTimePicker','TimePicker','TimePicker']}>
                                                <div className='flex gap-x-2'>
                                                    <DatePicker format='DD/MM/YYYY' slotProps={{ textField: {size:'small'} }} sx={{width:"200px",borderRadius:"10px",border:"solid 2px #c4c2c2"}} readOnly value={dayjs(Object.keys(ig)[0],'DD/MM/YYYY')} />
                                                <TimePicker
                                                    slotProps={{ textField: {size:'small'} }}
                                                    sx={{width:"200px",borderRadius:"10px",border:"solid 2px #c4c2c2"}}
                                                    readOnly
                                                    value={dayjs(`${Object.keys(ig)[0]} ${slot.slotTime.split('-')[0]}`,'DD/MM/YYYY hh:mm A')}
                                                    onError={(error)=>(console.log(error))}
                                                    />

                                                <TimePicker
                                                    slotProps={{ textField: {size:'small'} }}
                                                    sx={{width:"200px",borderRadius:"10px",border:"solid 2px #c4c2c2"}}
                                                    readOnly
                                                    value={dayjs(`${Object.keys(ig)[0]} ${slot.slotTime.split('-')[1]}`,'DD/MM/YYYY hh:mm A')}
                                                    />
                                                </div>
                                            </DemoContainer>
                                        </LocalizationProvider>
                                        <TextField aria-readonly sx={{width:"100px",height:"40px",border:"solid 2px #c4c2c2",borderRadius:"10px",marginTop:"7px"}} id="outlined-basic" value={slot.slotPrice} size='small' variant="outlined" /><span className='mt-6'>Rs</span>
                                        <p className='mt-5 ml-5 cursor-pointer underline' onClick={()=>OnSlotDelete(Object.keys(ig)[0],dateInd,slotInd)}>Delete Slot</p>
                                    </div>
                                </div>
                            )
                            })
                            )
                        })
                }
                <div className='flex flex-col gap-y-2'>
                    <label>Select Date & Time</label>
                    <div  className='relative flex gap-x-4'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DateTimePicker','TimePicker','TimePicker']}>
                                <div className='flex gap-x-2'>
                                <DatePicker
                                    format='DD/MM/YYYY'
                                    disablePast
                                    slotProps={{ textField: {size:'small'} }} 
                                    sx={(error)?{width:"200px",height:"40px",borderRadius:"10px",border:"solid 2px #c4c2c2",borderColor:"#F33A6A"}:{width:"200px",height:"40px",borderRadius:"10px",border:"solid 2px #c4c2c2"}} 
                                    value={slotDate} 
                                    onChange={(newValue)=> {
                                        setSlotDate(newValue)}}
                                    />
                                <TimePicker
                                    slotProps={{ textField: {size:'small'} }}
                                    sx={(error || slotError || slotTimeError)?{width:"200px",height:"40px",borderRadius:"10px",border:"solid 2px #c4c2c2",borderColor:"#F33A6A"}:{width:"200px",height:"40px",borderRadius:"10px",border:"solid 2px #c4c2c2"}} 
                                    reduceAnimations
                                    value={slotStart}
                                    onChange={(newValue) => {
                                        setSlotStart(newValue)}}
                                    
                                    />
                                <TimePicker
                                    slotProps={{ textField: {size:'small'} }}
                                    sx={(error || slotError || slotTimeError)?{width:"200px",height:"40px",borderRadius:"10px",border:"solid 2px #c4c2c2",borderColor:"#F33A6A"}:{width:"200px",height:"40px",borderRadius:"10px",border:"solid 2px #c4c2c2"}} 
                                    value={slotEnd}
                                    onChange={(newValue) => setSlotEnd(newValue)}
                                    />
                                </div>
                            </DemoContainer>
                        </LocalizationProvider>
                        <TextField sx={(error)?{width:"100px",height:"40px",border:"solid 2px #c4c2c2",borderRadius:"10px",marginTop:"7px",borderColor:'#F33A6A'}:{width:"100px",height:"40px",border:"solid 2px #c4c2c2",borderRadius:"10px",marginTop:"7px"}} id="outlined-basic" onChange={(e)=>(setSlotPrice(e.target.value))} value={slotPrice} size='small' variant="outlined" /><span className='mt-5'>Rs</span>
                        <p className='mt-5 cursor-pointer underline ml-6' onClick={OnSlotUpload}>Add More Slot</p>
                    </div>
                    {(error)&&<label className='text-rose-500'>This slot already exist.Please update</label>}
                    {(slotError)&&<label className='text-rose-500'>This slot already exist.Please update</label>}
                    {(valueError)&&<label className='text-rose-500'>Please fill in all fields.</label>}
                    {(slotTimeError)&&<label className='text-rose-500'>Please validate slot timings.</label>}
                </div>
            </div>
        </div>
    </div>
  )
}

export default NewSlot