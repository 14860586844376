import React, { useEffect, useState } from 'react'
import NavBar from './NavBar'
import { useLocation, useNavigate } from 'react-router-dom'
import { app } from "../firebase";
import {getFirestore, doc,getDoc,collection, getDocs, addDoc,updateDoc, onSnapshot, deleteDoc, Timestamp} from "firebase/firestore"
import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import crossIocon from "../images/icons8-multiply-24.png"
import DialogBox from "./dialogPop"
import dayjs from 'dayjs';
import {getAuth, onAuthStateChanged} from "firebase/auth"
import DialogPop from './dialogPop';
const auth = getAuth(app)

const firestore = getFirestore(app)
const dataD = {
    _id : 1,
    SrNo : 1,
    Name : "Suyash Patil",
    EmailId : "Suyash.patil@xyz.com",
    MobileNo : 7845845555,
    Qualification : "PG",
    Status : "Active",
    Plan : 399,
    SubStart : "31/11/2023",
    SubEnd : "12/11/2023",
    Interest : 3,
    Plans :[
        {
            plan : {
                planRs : "199 Rs/90 Days",
                startDate : "1/8/2024",
                endDate : "1/9/2024",
            },
            interest : [
                "B.E","B.Tech","BBA/BBA CA/BBA IB","MBBS"
            ]
        },
        {
            plan : {
                planRs : "199 Rs/90 Days",
                startDate : "1/8/2024",
                endDate : "1/9/2024",
            },
            interest : [
                "MBBS"
            ]
        }
    ]
}




export default function UserData() {
    const navigate = useNavigate();
    const [objData , setobjData] = useState(dataD);
    const [open, setOpen] = useState(false)
    const [comment, setComment] = useState("")
    const [commentError,setcommentError] = useState(false)
    const cancelButtonRef = useRef(null)
    const loc = useLocation()
    const userId = loc.pathname.split("/")[2]
    const [adminName,setadminName] = useState("");
    const [statusVal, setStatusVal] = useState(null);

    const handleClickHook = async() =>{
  
    try {
    const inColle = collection(firestore, "Users/" + userId + "/Buy Plans");

    // Listen for real-time updates
    const unsubscribe = onSnapshot(inColle, async (snapshot) => {
      const Plans = [];

      // Process updated data
      snapshot.docs.forEach((doc) => {
        Plans.push({
          plan: doc.data().Plan,
          interest: doc.data().selectedInterests
        });
      });

      // Fetch user data
      const ref = doc(firestore, "Users", userId);
      const ref1 = await getDoc(ref);
      const dataT = {
        Name: ref1.data().fullName,
        EmailId: ref1.data().email,
        MobileNo: ref1.data().phoneNumber,
        Qualification: ref1.data().qualification,
        Status: ref1.data().active ? setStatusVal(true) : setStatusVal(false),
        Plans: Plans
      };

      // Update state with the new data
      setobjData(dataT);
    });

    // Unsubscribe from the snapshot listener when component unmounts
    return () => unsubscribe();
  } catch (error) {
    console.error("Error fetching data:", error);
    alert("Error Fetching Data from Firebase.Please try to refresh");
  }
};

    const handleStatus = () =>{
        setOpen(true)
    }

    const handleComment = () =>{
      if(comment === ""){
        setcommentError(true);
        return;
      }
      const addCom = {
        date : Timestamp.now(),
        adminName : adminName,
        commentData : comment
      }
      const commentsColRef = collection(firestore,"Users/"+userId+"/Comments")
      addDoc(commentsColRef,addCom)
      .then((docRef) => {
        console.log('Comment added with ID: ', docRef.id);
        const userDocRef = doc(firestore, `Users/${userId}`);
        updateDoc(userDocRef, { active : (statusVal)?true:false })
        .then(() => {
        console.log('User document updated successfully.');
        })
        .catch((error) => {
        alert("Error updating data.Please try to refresh");
        console.error('Error updating user document:', error);
      });

      }).then(()=>{
        navigate("/Dashboard")
      })
      .catch((error) => {
        console.error('Error adding comment: ', error);
        alert("Error Fetching Data from Firebase.Please try to refresh");
      });
    }

useEffect (()=>{
    onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/auth.user
          const uid = user.uid;
          console.log(user)
          setadminName(user.email)
          handleClickHook()
          // ...
        } else {
          // User is signed out
          // ...
          console.log("no user")
          navigate("/")
        }
      });
},[])

  return (
    <div style={{"margin":"auto","fontFamily":"Segoe UI","fontWeight":"500"}} className="h-full pr-5 pt-1 pl-5">
        <NavBar />
        <div className="flex justify-between pl-10 pr-10 mb-5 mt-5">
            <h2 style={{"fontSize":"24px","fontFamily":"Segoe UI","lineHeight":"32px"}}>
                User Management &gt; {objData.Name}
            </h2>
            <div className='flex gap-x-6'>
              <button style={{"borderColor":"#32497F"}} onClick={()=>navigate("/Dashboard")} className="w-40 tracking-wider h-12 mt-2 bg-transparent cursor-pointer border-solid border-2 text-base text-[#32497F] rounded-2xl" >Cancel</button>
              <button onClick={handleComment} className="w-40 text-white h-12 bg-[#4F72C5] tracking-wider text-base border-none mt-2 rounded-2xl">Save</button>
            </div>
        </div>
        <div className="flex pl-20 h-80">
            <div className="flex flex-col w-96 pt-4 h-96 gap-y-5 border-r-2 border-dashed border-[#707070]">
                <div className='flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px","fontFamily":"Segoe UI","fontWeight":"400"}}>Name</label>
                    <div style={{"fontSize":"20px","fontFamily":"Segoe UI","fontWeight":"400","lineHeight":"27px"}}>{objData.Name}</div>
                </div>
                <div className='flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px","fontFamily":"Segoe UI","fontWeight":"400"}}>Email Id</label>
                    <div style={{"fontSize":"20px","fontFamily":"Segoe UI","fontWeight":"400","lineHeight":"27px"}}>{objData.EmailId}</div>
                </div>
                <div className='flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px","fontFamily":"Segoe UI","fontWeight":"400"}}>Mobile Number</label>
                    <div style={{"fontSize":"20px","fontFamily":"Segoe UI","fontWeight":"400","lineHeight":"27px"}}>{objData.MobileNo}</div>
                </div>
                <div className='flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px","fontFamily":"Segoe UI","fontWeight":"400"}}>Qualification</label>
                    <div style={{"fontSize":"20px","fontFamily":"Segoe UI","fontWeight":"400","lineHeight":"27px"}}>{objData.Qualification}</div>
                </div>
                <div className='flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px","fontFamily":"Segoe UI","fontWeight":"400"}}>Status</label>
                    <label class="inline-flex items-center cursor-pointer">
                    <input type="checkbox" onChange={()=>setOpen(true)} value={statusVal} class="sr-only peer" checked={(statusVal)?true:false}/>
                    <span style={{"fontSize":"16px","fontFamily":"Segoe UI","fontWeight":"400"}} class="text-sm font-medium text-gray-900 dark:text-gray-300 mr-3">Inactive</span>
                    <div class="relative w-11 h-6 bg-[#b6b8b7] rounded-full peer peer-focus:ring-4 peer-focus:ring-white dark:peer-focus:ring-white dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#818181]"></div>
                    <span style={{"fontSize":"16px","fontFamily":"Segoe UI","fontWeight":"400"}} class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 ml-3">Active</span>
                    </label>
                </div>
            </div>
            <div className="flex flex-col pt-4 pl-20 gap-y-7">
                <div style={{"fontSize":"20px","fontFamily":"Segoe UI","fontWeight":"400"}}>Plan Selected :</div>
                {
                    objData.Plans.map((dt)=>{
                        return(
                            <div style={{"fontFamily":"Segoe UI"}} className="flex flex-col gap-y-3 pb-6 border-b-2 border-dashed border-[#707070]">
                                <div className="flex gap-x-4">
                                    <div style={{"fontSize":"20px","fontFamily":"Segoe UI","fontWeight":"400"}}>{dt.plan.planRs}</div>
                                    <div style={{"fontSize":"16px","fontWeight":"400"}} className='flex pt-0.5'>
                                        <label>Subscription Start :</label>
                                        <div>{dt.plan.startDate.split('T')[0]}<span style={{"fontWeight":"400"}} className='ml-8'>|</span></div>
                                    </div>
                                    <div style={{"fontSize":"16px","fontFamily":"Segoe UI","fontWeight":"400"}} className='flex pt-0.5'>
                                        <label>Subscription End :</label>
                                        <div>{dt.plan.endDate.split('T')[0]}</div>
                                    </div>
                                </div>
                                <div className="flex flex-row gap-x-4">
                                    {dt.interest.map((intrst)=>{
                                        return(
                                            <div style={{"fontSize":"16px","fontFamily":"Segoe UI","fontWeight":"400"}}>
                                                <span className="mr-3">&#10004;</span>{intrst}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
        <DialogBox 
          dialogTitle = "Change User Status"
          data = {objData.Name}
          open = {open}
          setOpen = {setOpen}
          comment = {comment}
          setComment = {setComment}
          commentError = {commentError}
          setcommentError={setcommentError}
          statusVal = {statusVal}
          setStatusVal = {setStatusVal}
        />
    </div>
  )
}
