import React from 'react'
import NavBar from '../NavBar'
import ReactQuill from 'react-quill';
import 'quill/dist/quill.snow.css'
import {useState,useEffect} from "react"
import {Select, Option} from "../customSelect"
import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import crossIocon from "../../images/icons8-multiply-24.png"
import DataTable from "react-data-table-component";
import { useNavigate, useLocation } from 'react-router-dom'
import uploadIcon from "../../images/upload.png"
import {collectionGroup,getFirestore, doc,getDoc,collection,onSnapshot,addDoc, getDocs,query,where, updateDoc, Timestamp} from "firebase/firestore"
import {getStorage, ref, uploadBytesResumable, getDownloadURL, listAll, deleteObject} from "firebase/storage"
import { app } from "../../firebase";
import dayjs from 'dayjs';
import {getAuth, onAuthStateChanged} from "firebase/auth"
const auth = getAuth(app)
const firestore = getFirestore(app)
const storage = getStorage(app)

const modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] }
      ],
      [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
    ]
  };

const columns = [
  {
    name : 'Name',
    selector : row=>row.Name,
    sortable :true,
    left : true
  },
  {
    name : 'Email Id',
    selector : row=>row.Email,
    sortable :true,
    left : true
  }
]

const customStyles = {
  headRow :{
    style: {
			// backgroundColor: theme.background.default,
      fontSize : '16px',
      fontWeight : 'bold',
      fontFamily : 'Segoe UI',
			minHeight: '52px',
			borderBottomWidth: '1px',
			borderBottomColor: '#8A8A8A',
			borderBottomStyle: 'solid',
		},

  },
  rows : {
    style : {
      fontSize: '16px',
			fontWeight: 400,
      fontFamily : 'Segoe UI',
			// color: theme.text.primary,
			// backgroundColor: theme.background.default,
			minHeight: '48px',
			'&:not(:last-of-type)': {
				borderBottomStyle: 'solid',
				borderBottomWidth: '1px',
				borderBottomColor: '#8A8A8A'
			},
    }
  },
  pagination :{
    style: {
			fontSize: '16px',
			minHeight: '56px',
			borderTopStyle: 'solid',
			borderTopWidth: '1px',
			borderTopColor: '#8A8A8A',
      color : 'black'
		},
  }
}

const formats = [
    "header", "height", "bold", "italic",
    "underline", "strike", "blockquote",
    "list", "color", "bullet", "indent",
    "link", "image", "align", "size",
  ];

function EditGuide() {

    const [intData,setIntData] = useState([])
    const loc = useLocation();
    const [open, setOpen] = useState(false)
    const noteId = loc.pathname.split("/")[3]
    const [noteData, setNoteData] = useState({})
    const [planActive, setPlanActive] = useState(noteData.active)
    const [errors, setErrors] = useState({});
    const [userArray,setuserArray] = useState([])
    const navigate = useNavigate()
    const cancelButtonRef = useRef(null)
    const [comment, setComment] = useState(null)
    const [commentError, setcommentError] = useState(false)
    const [adminName, setadminName] = useState(null)

    const getNotesData = async() =>{
        const docRef = doc(firestore,`Notes/${noteId}`)
        const userArr = [];
        const museums = query(collectionGroup(firestore, 'Buy Notes'), where('Note.noteId', '==', `${noteId}`));
                                const querySnapshot = await getDocs(museums);
                                const userDoc = await getDocs(collection(firestore,"Users"));
                                if(!querySnapshot.empty){
                                    var noteCount = querySnapshot.forEach(async(doc) => {
                                    userDoc.docs.map((data)=>{
                                        if(data.id === doc.ref.parent.parent.id){
                                            userArr.push({
                                                    Name : data.data().fullName,
                                                    Email : data.data().email
                                                });
                                        }
                                    })
                                });
                                }
            // Fetch data from the document
            console.log(userArr)
            setuserArray(userArr)
            getDoc(docRef)
            .then((dc)=>{
                setNoteData(dc.data());
                setPlanActive(dc.data().active)
            })  
            .catch((error)=>{
                console.log(error)
            })
    }

    const handleComment = async() =>{
      if(comment === ""){
        setcommentError(true);
        return;
      }
    
      const addCom = {
        date : Timestamp.now(),
        adminName : adminName,
        commentData : comment
      }
      console.log(planActive)
      const commentsColRef = collection(firestore,"Notes/"+noteId+"/Comments")
      addDoc(commentsColRef,addCom)
      .then((docRef) => {
        console.log('Comment added with ID: ', docRef.id);
      })
      .catch((error) => {
        console.error('Error adding comment: ', error);
      });
    }

  const getIntData = async() =>{
        const ref = collection(firestore, "interests");

    // Subscribe to real-time changes
    const unsubscribe = onSnapshot(ref, (snapshot) => {
        const demoData = [];
        snapshot.forEach((dc) => {
            if(dc.data().active){
                demoData.push({
                _id: dc.id,
                Name: dc.data().interestName,
                SelByUsers: dc.data().counter,
                Status: dc.data().active ? "Active" : "Inactive"
            });
            }
        });
        setIntData(demoData); // Update state with new data
    });

    // Return unsubscribe function to clean up the listener when component unmounts
    return () => unsubscribe();
    }

const handleUpload = async() =>{
        const validationErrors = validateInputs(noteData);
        if (Object.keys(validationErrors).length === 0){
            setErrors({})
            if(comment !== null){
              await handleComment()
            }
            console.log(noteData)
                const updatedInputs = {
                        ...noteData,
                        active: (planActive)?true:false
                    };
                    console.log(updatedInputs)
                    const ref = doc(firestore, `Notes/${noteId}`)
                    updateDoc(ref,updatedInputs)
                    .then((res)=>{
                        console.log("Data uploaded succesfully :")
                    })
                    .then(
                        navigate("/Services/ExpertGuide")
                    )
                    .catch((err)=>{
                        console.log(err)
                    })
        }else {
            alert("Please fill in all fields");
            setErrors(validationErrors);
        }
        
    }

   const validateInputs = (values) => {
        const errors = {};

        if (!values.noteCategory.trim()) {
            errors.noteCategory = "Please select qualification.";
        }
        if (!values.noteName.trim()) {
            errors.noteName = "Please select interest.";
        }
        if (!values.noteRs) {
            errors.noteRs = "Price is required.";
        }
        if (!values.validity) {
            errors.validity = "Validity is required.";
        }
        if (!values.caption) {
            errors.caption = "Description is required.";
        }
        if (values.imgName === "Select Image") {
            errors.imgName = "Document is required.";
        }

        return errors;
    }

  const handleChange = (event,name=null,value=null) =>{
        let nameTmp;
        let valueTmp;
        if(name !== null && value !== null){
            nameTmp = name;
            valueTmp = value
        }else{
            nameTmp = event.target.name;
            valueTmp = event.target.value
        }
        if(nameTmp === 'active'){
            valueTmp = Boolean(valueTmp)
        }
        else if (nameTmp === 'imgName'){
            valueTmp = event.target.files[0].name
        }
        else if (nameTmp === 'noteRs'){
            valueTmp = Number(valueTmp)
        }
        else if(nameTmp === 'validity'){
            valueTmp = Number(valueTmp)
        }

        setNoteData(values => (console.log(values),{...values, [nameTmp]: valueTmp}))
}

  const handleEditorText = (content) =>{
    setNoteData(values => ({...values,caption:content}))
  }

  useEffect(()=>{
    onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/auth.user
          const uid = user.uid;
          console.log(user)
          setadminName(user.email)
          getNotesData()
          getIntData()
          // ...
        } else {
          // User is signed out
          // ...
          console.log("no user")
          navigate("/")
        }
      });
   
},[])

  return (
    <div style={{"margin":"auto"}} className="pr-5 pt-1 pl-5">
        <NavBar />
        <div className="flex justify-between pl-10 pr-10 mb-5 mt-5">
            <h2 className="text-2xl font-semibold">
                Expert Learning Guides &gt; Edit Guide &gt; {noteData.noteName}
            </h2>
            <div className='flex gap-x-6'>
                <button onClick={()=>navigate("/Services/ExpertGuide")} className="w-40 h-12 bg-transparent text-[#32497F] border-2 mt-2 border-solid text-base rounded-2xl border-[#32497F]">Cancel</button>
                <button onClick={handleUpload} className="w-40 text-white h-12 bg-[#4F72C5] text-base border-none mt-2 rounded-2xl">Save</button>
            </div>
        </div>
        <div className="ml-56 w-5/6 h-full flex flex-col pl-20 h-80">
            <div className="flex w-3/5 pt-4 pb-8 h-full gap-x-8 border-b-2 border-dashed border-[#707070]">
                <div className='relative flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}} for="interestCategory">Select Qualification</label>

                    <Select onChange={(e)=>{handleChange(e,"noteCategory",value)}} value={noteData.noteCategory} name='noteCategory' id="interestCategory" className='pl-4 w-64 h-11 rounded-xl border-2 border-[#9E9B9B] border-solid'>
                    <Option value="UG">Under Graduate</Option>
                    <Option value="PG">Post Graduate</Option>
                    </Select>
                    {errors.noteCategory && <div className='absolute top-16'><p className="text-red-500">{errors.noteCategory}</p></div>}
                </div>
                <div className='relative flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}} for="interestCategory">Select Interest</label>

                    <Select onChange={(e,value)=>{handleChange(e,"noteName",value)}} value={noteData.noteName} id="interestCategory" name='noteName' className='pl-4 w-64 h-11 rounded-xl border-2 border-[#9E9B9B] border-solid'>
                    {intData.map((dt)=>{
                        return(
                            <Option key={dt._id} value={dt.Name}>{dt.Name}</Option>
                        )
                    })}
                    </Select>
                    {errors.noteName && <div className='absolute top-16'><p className="text-red-500">{errors.noteName}</p></div>}
                </div>
                <div className='relative flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}}>Price</label>
                    <div className='flex'>
                        <input onChange={handleChange} value={noteData.noteRs} className="pl-4 w-20 h-10 rounded-xl border-2 mr-3 border-[#9E9B9B] border-solid pl-2" name='noteRs' type='number'/><span className='mt-3'>Rs</span>
                    </div>
                    {errors.noteRs && <div className='absolute top-16'><p className="text-red-500">{errors.noteRs}</p></div>}
                </div>
                <div className='flex flex-col gap-y-3 ml-8'>
                    <label style={{"fontSize":"16px"}} className='mb-3'>Status</label>
                    <label class="inline-flex items-center cursor-pointer">
                    <input type="checkbox" class="sr-only peer" name='active' onChange={()=>setOpen(true)} checked={(planActive)?true:false}/>
                    <span class="text-sm font-normal text-gray-900 dark:text-gray-300 mr-3">Inactive</span>
                    <div class="relative w-11 h-6 bg-[#b6b8b7] rounded-full peer peer-focus:ring-4 peer-focus:ring-white dark:peer-focus:ring-white dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#818181]"></div>
                    <span class="ms-3 text-sm font-normal text-gray-900 dark:text-gray-300 ml-3">Active</span>
                    </label>
                </div>
            </div>
            <div className='pt-4 w-5/6 flex gap-x-5 border-b-2 border-dashed border-[#707070] pb-8 mb-6'>
                <div className='flex relative flex-col gap-y-3'>
                    <label>Upload Document</label>
                    <div  className='relative flex gap-x-8'> 
                        <label style={{"border":"solid #9E9B9B","borderWidth":"2px","borderColor":"#9E9B9B"}} readOnly for="imgOne" className='relative pt-1 w-96 pl-4 rounded-xl text-base h-10 z-2'>{noteData.noteName}</label>
                        <span className='absolute right-6 top-2'><img className='w-6 h-6 z-10' src={uploadIcon}/></span>
                        <input onChange={(e)=>{
                            console.log(e.target.files[0])
                            setData(e.target.files[0])
                            setName(e.target.files[0].name)
                            }} id='imgOne' style={{"display":"none"}} readOnly type='text'/>

                    </div>
                    {errors.imgName && <div className='absolute top-16'><p className="text-red-500">{errors.imgName}</p></div>}
                </div>
                <div className='relative flex flex-col gap-y-3'>
                    <label style={{"fontSize":"16px"}}>Validity</label>
                    <div>
                        <input onChange={handleChange} value={noteData.validity} className="pl-4 w-20 h-10 rounded-xl border-2 mr-3 border-[#9E9B9B] border-solid pl-2" name='validity' type='number'/><span>Days</span>
                    </div>
                    {errors.validity && <div className='absolute top-16'><p className="text-red-500">{errors.validity}</p></div>}
                </div>
            </div>
                <div className='relative flex flex-col gap-y-3 border-b-2 border-dashed border-[#707070] pb-8 mb-6'>
                    <label style={{"fontSize":"16px"}}>Description</label>
                    <div className='rounded-xl border-2 border-[#9E9B9B] border-solid ' style={{"width":"650px", "height":"190px", "overflow":"hidden"}}>
                        <ReactQuill
                        theme="snow"
                        modules={modules}
                        value={noteData.caption}
                        formats={formats}
                        onChange={handleEditorText}
                        placeholder="write your content ...."
                        style={{ height: "152px", width:"650px"}}
                        >
                        </ReactQuill>
                    </div>
                    {errors.caption && <div className='absolute top-52 mt-1'><p className="text-red-500">{errors.caption}</p></div>}
                </div>
                <div>
                    <label style={{"fontSize":"16px"}}>Users</label>
                    <div className='rounded-xl w-1/2 border-2 border-[#9E9B9B] mt-4 border-solid mt-2 mb-3'>
                    
                    <DataTable
                        columns={columns}
                        data={userArray}
                        customStyles={customStyles}
                    />
                </div>
                </div>
        </div>
        <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-23" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className = "bg-gray"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
            <Dialog.Panel style={{"width":"655px","height":"354px","fontFamily":"Segoe UI"}} className="absolute top-64 transform border border-[#707070] overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all  sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white pl-10 px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div> */}
                    <div className="mt-3 text-left sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-2xl font-semibold leading-6 text-gray-900">
                        Change Guide Status
                      </Dialog.Title>
                      <img onClick={() => setOpen(false)} src={crossIocon} alt='cross' className='absolute right-12 top-10'/>
                      <div className="mt-2">
                        <p className="text-base text-gray-500">
                          Are you sure, you want make {noteData.noteName} {(planActive)?<span>Inactive</span>:<span>Active</span>}?
                        </p>
                      </div>
                      <div className='relative flex flex-col pr-9'>
                        {/* add padding top-left */}
                        <label className='text-base mb-2'>Please provide comment below</label>
                        <textarea onChange={(e)=>{
                        setcommentError(false)
                        setComment(e.target.value)}} id="w3review" name="w3review" rows="4" cols="50" className='w-78 h-24 rounded-lg border pt-3 pl-3'></textarea>
                        {(commentError)&&<div className='absolute top-28 mt-3'><p className="text-red-500">Please provide comment.</p></div>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 flex justify-center gap-x-5">
                  <button
                    type="button"
                    style={{
                      "borderColor" : "#32497F",
                      "borderWidth" :"2px"
                    }}
                    className="justify-center w-36 h-12 border-solid rounded-2xl bg-white px-3 py-2 text-lg text-[#32497F] sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="justify-center w-36 h-12 rounded-2xl border-solid bg-white px-3 py-2 text-lg text-[#FF0000] border-2 sm:mt-0 sm:w-auto border-[#FF0000]"
                    onClick={()=>{
                      if(comment === null){
                        setcommentError(true);
                        return;
                      }
                      (planActive)?setPlanActive(false):setPlanActive(true)
                      setOpen(false)
                    }}
                    ref={cancelButtonRef}
                  >
                    Yes
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>

    </div>
  )
}

export default EditGuide