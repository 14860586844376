import React from 'react'
import NavBar from '../NavBar'
import DataTable from "react-data-table-component";
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import {getFirestore, doc,getDoc,collection,setDoc, updateDoc, getDocs, onSnapshot, addDoc} from "firebase/firestore"
import {getAuth, onAuthStateChanged} from "firebase/auth"
import { app } from "../../firebase";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat'
import ServiceNavbar from "./ServiceNavbar"
import cred from "../../client_secret_100998732635-hckn4s77n6ibm5h3et6qbfpqmqh1uqhg.apps.googleusercontent.com.json"
var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat)
const firestore = getFirestore(app)
const clearfilterCss = "w-40 h-11 bg-transparent cursor-pointer text-base tracking-wider text-[#32497F] border-solid border-2 border-[#32497F] rounded-lg";
const filterCss = "w-40 h-11 bg-[#4F72C5] cursor-pointer text-base tracking-wider text-white border-solid border-2 border-[#4F72C5] rounded-lg"


const columns = [
  {
    name : 'Sr NO',
    selector : row => row.SrNo,
    width :"180px",
    left : true
  },
  {
    name : 'Slots',
    selector : row=>row.Name,
    sortable : true,
    left : true,
    width : "400px",
    conditionalCellStyles: [
			{
				when: row => row.Name,
				style: {
					color: '#5F8EFC',
					'&:hover': {
						cursor: 'pointer',
					},
				},
			},
    ]
  },
  {
    name : 'Status',
    selector : row=>row.Status,
    sortable :true,
    left : true
  },
  {
    name : 'Price',
    selector : row=>row.slotPrice,
    sortable :true,
    left : true
  },
  {
    name : 'Booked By',
    selector : row=>row.bookedBy,
    left : true
  }
]

const customStyles = {
  headRow :{
    style: {
			// backgroundColor: theme.background.default,
      fontSize : '16px',
      fontWeight : 'bold',
      fontFamily : 'Segoe UI',
			minHeight: '52px',
			borderBottomWidth: '1px',
			borderBottomColor: '#8A8A8A',
			borderBottomStyle: 'solid',
		},

  },
  rows : {
    style : {
      fontSize: '16px',
			fontWeight: 400,
      fontFamily : 'Segoe UI',
			// color: theme.text.primary,
			// backgroundColor: theme.background.default,
			minHeight: '48px',
			'&:not(:last-of-type)': {
				borderBottomStyle: 'solid',
				borderBottomWidth: '1px',
				borderBottomColor: '#8A8A8A'
			},
    }
  },
  pagination :{
    style: {
			fontSize: '16px',
			minHeight: '56px',
      fontFamily : 'Segoe UI',
			borderTopStyle: 'solid',
			borderTopWidth: '1px',
			borderTopColor: '#8A8A8A',
      color : 'black'
		},
  }
}

const conditionalRowStyles = [
			{
				when: row => row.Status === "Booked",
				style: {
					backgroundColor: '#F1F1F1'
				}
			}]


function PersonalizedCoaching() {

    let srNo = 0;
    const navigate = useNavigate();
    const [originalData, setoriginalData] = useState([])
    const [dataKey, setDataKey] = useState(originalData)
    const [subStart, setsubStart] = useState("")
    const [subEnd, setsubEnd] = useState("")
    const [dateError, setdateError] = useState(false)
    const [isFilActive, setFilterActive] = useState(false)
    const [accessToken, setAccessToken] = useState(null);

    

    const handleSearch = () =>{

    }

        const handleClickDateEnd= (e) =>{
  //const value = dayjs(e.target.value).format('DD/MM/YYYY')
  setsubEnd(e.target.value)
  //console.log(value)
}

const handleClickDateStart =(e) =>{
  //const value = dayjs(e.target.value).format('DD/MM/YYYY')
  setsubStart(e.target.value)
  //console.log(value)
}

    const handelFilter  = () =>{
      console.log("i am in")
  if (isFilActive) {
      setsubStart("")
      setsubEnd("")
      setFilterActive(false)
      setDataKey(originalData)
    }
    else if(subStart !== "" && subEnd !== ""){
      console.log("First");

    // Ensure subStart and subEnd are dayjs objects
    let subSt = dayjs(subStart, 'YYYY-MM-DD');
    let subEn = dayjs(subEnd, 'YYYY-MM-DD');
    if (subSt.isAfter(subEn) || subSt.isSame(subEn)) {
        setdateError(true);
        return;
      } else {
        setdateError(false);
      }
    // Filter dataKey using dayjs isBetween with consistent formats
    setDataKey(dataKey.filter((dt) => 
      {
        console.log(dt.Name.split('|')[0])
        const [datePart, timeRange] = dt.Name.split('|');
        const startTime = datePart + ' ' + timeRange.split('-')[0]; // Extract the start time part
        return dayjs(startTime, 'DD-MM-YYYY hh:mm:ss A').isBetween(subSt, subEn, null, '[]');
    }
    ));

    setFilterActive(true);
    }
    else if(subStart === ""){
      alert("Please select the start date first")
    }
  }

    const toISOStringCon  = (date) =>{
const dateString = date;
const formattedDateString = dayjs.tz(dateString, "DD-MM-YYYY hh:mm:ss A", "Asia/Kolkata");
const isoString = formattedDateString.utc().toISOString();
return isoString;

    }

    const createMeeting = async (accessToken,email,start,end) => {
  try {
    const eventPayload = {
  "summary": "Your Event Summary",
  "description": "Your Event Description",
  "start": {
    "dateTime":  `${start}`,
    "timeZone": "Asia/Kolkata"
  },
  "end": {
    "dateTime": `${end}`,
    "timeZone": "Asia/Kolkata"
  },
  "conferenceData": {
    "createRequest": {
      "conferenceSolutionKey": {"type": "hangoutsMeet"},
      "requestId": `jfkjfdklkasihgf7${start}ygdafjdsafdjkdhj${end}jkkjaojfas;kjdjk`,
    }
  },
  "attendees": [
    {
      "email": `${email}`
    }
  ]
}

    const response = await fetch('https://www.googleapis.com/calendar/v3/calendars/eazycareerpune@gmail.com/events?conferenceDataVersion=1', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(eventPayload),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`Failed to create meeting: ${errorData.error.message}`);
    }

    const responseData = await response.json();
    console.log('Meeting created successfully:', responseData);
    return responseData;
  } catch (error) {
    console.error('Error creating meeting:', error.message);
    throw error;
  }
};

    const handleChange = (e) =>{
      console.log(e)
      navigate("/Services/PersolnalizedCoaching/"+e._id)
    }


    const handleGetSlots = async (accTok) => {
  const slotRef = collection(firestore, "slots");

  const slotSnapshot = onSnapshot(slotRef, (snapshot) => {
    const demoData = [];

    snapshot.docs.forEach((slot) => {
      const slotRef1 = collection(firestore, `slots/${slot.id}/Slots`);
      let slotDate = slot.id.split('-');

      onSnapshot(slotRef1, (snapshot1) => {
        // Initialize temporary array to collect slot data within this snapshot
        const tempData = [];

        snapshot1.docs.forEach((slData) => {
          console.log(slData.id, " ", slData.data());
          let slotT = slData.data().slotTime.split('-');
          let start = toISOStringCon(`${slot.id} ${slotT[0]}`);
          let end = toISOStringCon(`${slot.id} ${slotT[1]}`);

          if (slData.data().isBooked && slData.data().eventId === null) {
            createMeeting(accTok, slData.data().emailId, start, end)
              .then((meetingData) => {
                tempData.push({
                  SrNo: demoData.length + tempData.length + 1,
                  _id: slot.id,
                  Name: `${slotDate[0]}/${slotDate[1]}/${slotDate[2]} | ${slData.data().slotTime}`,
                  Status: "Booked",
                  slotPrice: slData.data().slotPrice,
                  bookedBy: slData.data().emailId,
                });
                return meetingData;
              })
              .then((meetingData) => {
                const slotDocRef = doc(firestore, `slots/${slot.id}/Slots/${slData.id}`);
                return updateDoc(slotDocRef, { eventId: meetingData });
              })
              .then(() => {
                console.log('Slot document updated successfully.');
              })
              .catch((error) => {
                console.error('Error creating meeting or updating document:', error);
              });
          } else {
            tempData.push({
              SrNo: demoData.length + tempData.length + 1,
              _id: `${slot.id}/${slData.id}`,
              Name: `${slotDate[0]}/${slotDate[1]}/${slotDate[2]} | ${slData.data().slotTime}`,
              Status: slData.data().isBooked ? "Booked" : "Open",
              slotPrice: slData.data().slotPrice,
              bookedBy: slData.data().isBooked ? slData.data().emailId : "--",
            });
          }
        });

        // Merge temporary data into main demoData array
        demoData.push(...tempData);

        // Update state after processing all documents in the snapshot
        setoriginalData([...demoData]);
        setDataKey([...demoData]);
      });
    });
  });

  return () => slotSnapshot(); // Cleanup function for onSnapshot
};
  

    useEffect(()=>{
      const params = new URLSearchParams(window.location.search);
    const authorizationCode = params.get('code');

    if (authorizationCode) {
      // If there's an authorization code in the URL, exchange it for an access token
      exchangeCodeForToken(authorizationCode);
    } else {
      // If there's no authorization code, initiate the OAuth flow
      initiateOAuthFlow();
    }
    },[])


    const initiateOAuthFlow = () => {
    const clientId = cred.web.client_id;
    const redirectUri = cred.web.redirect_uris[1];
    const scopes = 'https://www.googleapis.com/auth/calendar'; // Add the scopes your application requires
    const authEndpoint = 'https://accounts.google.com/o/oauth2/auth';
    const authUrl = `${authEndpoint}?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scopes)}&response_type=code`;

    window.location.href = authUrl;
  };

  const exchangeCodeForToken = async (authorizationCode) => {
    const clientId = cred.web.client_id;
    const clientSecret = cred.web.client_secret;
    const redirectUri = cred.web.redirect_uris[1];
    const tokenEndpoint = 'https://oauth2.googleapis.com/token';

    fetch(tokenEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        code: authorizationCode,
        client_id: clientId,
        client_secret: clientSecret,
        redirect_uri: redirectUri,
        grant_type: 'authorization_code',
      }),
    })
      .then(response => response.json())
      .then(data => {
        // Access token received
        const accessToken = data.access_token;
        console.log(accessToken)
        setAccessToken(accessToken);
        return accessToken
      }).then((acctoken)=>{
        handleGetSlots(acctoken)
      })
      .catch(error => {
        console.error('Error exchanging code for token:', error);
      });
  };

  return (
    <div style={{"margin":"auto"}} className="pr-5 pt-1 pl-5">
        <NavBar />
        <div className='flex'>
          <ServiceNavbar />
        <button onClick={()=>(navigate("/Services/PersolnalizedCoaching/NewSlot"))} className='w-56 h-12 bg-[#4F72C5] cursor-pointer tracking-wider text-base text-white mt-4 border-none rounded-2xl '>Open New Slot</button>
        </div>
        <div className="pr-4 pl-4">
          {/* <div className="flex justify-between mt-10 mb-10">
            <h2 className="text-2xl font-semibold">
                User Management ({dataKey.length})
            </h2>
              <button onClick={handleClick} className="w-56 h-12 bg-[#4F72C5] cursor-pointer text-lg text-white mt-2 border-none rounded-2xl">Create User</button>
          </div> */}
          <div className="flex justify-between mt-5 mb-5">
            <div className='flex gap-x-10 mt-5 mb-5'>
            <input onInput={handleSearch} type="Text"  placeholder="Search ..." className="pl-5 w-56 h-10 border-solid border-2 border-[#9E9B9B] text-black rounded-xl"/>
            <p onClick={()=>navigate("/updateImages/personalizedCoaching/updateCarouselImages",{state:{
              storagePath : "personalizedCoachingCarousel",
              navigationPath : "Services/PersolnalizedCoaching",
              moduleName : "Personalized Coaching by Expert"
            }})} className='text-black underline decoration-black'>Update Homepage Content and Images</p>
            </div>
            <div className="flex relative gap-x-3 pr-2 mt-4">
             {/* reduce date input width // add filter button */}
              <input onChange={handleClickDateStart} type="Date" value={subStart} className="border-solid border-2 border-[#9E9B9B] rounded-lg w-36 pl-3 pr-1 h-10" />
              <span className="mt-3">to</span>
              <input onChange={handleClickDateEnd} type="Date" value={subEnd} placeholder="dd-mm-yy"  className="border-solid border-2 border-[#9E9B9B] rounded-lg pl-3 pr-1 w-36 h-10" />
              <button style={{"fontFamily":"Segoe UI"}} onClick={handelFilter} className={(isFilActive)?clearfilterCss:filterCss}>{(isFilActive)?<p className="mt-1.5">Clear</p>:<p className="mt-1.5">Filter</p>}</button>
              {(dateError)&&<p className="text-rose-600 absolute top-8 left-1">Please validate the dates.</p>}
            </div>
          </div>
          <div>
            <DataTable 
              columns={columns}
              data={dataKey}
              onRowClicked={handleChange}
              customStyles={customStyles}
              conditionalRowStyles={conditionalRowStyles}
              defaultSortAsc={false}
              pagination
            />
          </div>
        </div>
      </div>
  )
}

export default PersonalizedCoaching